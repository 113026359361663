import React from 'react'
import View from 'views/History/Index'
import HistoryAction from '../../actions/HistoryAction'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import BaseContainer, { selector } from 'containers/BaseContainer'
import moment from 'moment'
import common from 'common'
class Index extends BaseContainer {
    constructor(props) {
        super(props)
        this.refTable = null
        this.onFetchData = this.onFetchData.bind(this)
        this.onRefTable = this.onRefTable.bind(this)
        this.onDeleteData = this.onDeleteData.bind(this)
        let historyDate = JSON.parse(localStorage.getItem("filter.historiesDate")) || {};
        this.query = {
            startDate: historyDate.startDate || moment().startOf('month').format('YYYY-MM-DD'),
            endDate: historyDate.endDate || moment().format('YYYY-MM-DD')
        }
    }

    //filter date 
    onSubmitDate = (dateRange) => {
        this.onFetchData(dateRange)
    }

    componentDidMount(){
        localStorage.setItem('userCalendar', JSON.parse(localStorage.user)._id)
        localStorage.setItem('dateCalendar', new Date())    
    }

    componentWillUnmount() {
        localStorage.setItem("filter.historiesDate", JSON.stringify({
            startDate: this.query.startDate,
            endDate: this.query.endDate
        }));
    }

    onFetchData(state) {
        if(Array.isArray(state.filters) && state.filters.length > 0) {
            state.filters.map(item => {
                if(item.columnName === 'Construction.code') {
                  return item.value = common.codeFormater(item.value)
                }
            })
        }
        //thêm date vào query để gửi lên server
        this.query = {
            ...this.query,
            ...state
        }
        this.props.dispatch(HistoryAction.fetchAll(this.query)).then(data => {
            if (data.error) {
                this.notify(`Response: [${data.error.status}] ${data.error.message}`, 'error')
            }
        })
    }

    // onFetchData(state) {
    //     this.props.dispatch(HistoryAction.fetchAll(state))
    // }

    onRefTable(ref) {
        this.refTable = ref
    }

    onDeleteData(selectedIds) {
        let permissions = JSON.parse(localStorage.user).permissions || []
        let checkTimeZoneFE = localStorage.getItem("frontTimeZone");

        this.props.dispatch(HistoryAction.delete({ ids: selectedIds, permissions: permissions, checkTimeZoneFE: checkTimeZoneFE }))
        .then(result => {
            if (!result.error) {
                this.notify(I18n.t('Message.deleteDataSuccess'))
                if (this.refTable) {
                    this.refTable.onSelectionChange([])
                    this.refTable.onFetchData()
                }
            } else {
                let err = result.error
                switch (err.status) {
                    case 417: {
                        if (err.message === "Block_Update") {
                            this.notify(I18n.t("Backend.History.Block_Update"), "error")
                        }
                        break
                    }
                    default: this.notify(`Response: [${err.status}] ${err.message}`, 'error')
                }
            }
        })
    }

    render() {
        return (
            <View
                onFetchData={this.onFetchData}
                onRefTable={this.onRefTable}
                onDeleteData={this.onDeleteData}
                data={this.props.data}
                onSubmitDate={this.onSubmitDate}
            />
        )
    }
}


const mapStateToProps = state => {
    return {
        data: selector(state, "history.list", {}),
    }
}

export default withRouter(connect(mapStateToProps)(Index))
import BaseAction from './BaseAction'
/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
class HistoryAction extends BaseAction {
  get actions() {
    return {
      edit: {
        method: 'put',
        url: '/api/v1/histories/:_id',
        type: 'History.edit'
      },
      fetchAll: {
        method: 'get',
        url: '/api/v1/histories',
        type: 'History.fetchAll'
      },
      fetch: {
        method: 'get',
        url: '/api/v1/histories/:_id',
        type: 'History.fetch'
      },
      destroy: {
        method: 'delete',
        url: '/api/v1/histories/:_id',
        type: 'History.destroy'
      },
      delete: {
        method: 'delete',
        url: '/api/v1/histories',
        type: 'History.delete'
      },
      updateWorkingLog: {
        method: 'put',
        url: '/api/v1/histories/updateWorkingLog',
        type: 'History.updateWorkingLog'
      },
      getConstructionForCheckin: {
        method: 'get',
        url: '/api/v1/histories/getConstructionForCheckin',
        type: 'History.getConstructionForCheckin'
      },
      addHistoryInCalendar: {
        method: 'put',
        url: '/api/v1/histories/addHistoryInCalendar',
        type: 'History.addHistoryInCalendar'
      },
      deleteHistoryInCalendar: {
        method: 'put',
        url: '/api/v1/histories/deleteHistoryInCalendar',
        type: 'History.deleteHistoryInCalendar'
      },
      updateWorkingLength: {
        method: 'put',
        url: '/api/v1/histories/updateWorkingLength',
        type: 'History.updateWorkingLength'
      }
    }
  }
}
/*
 * bắt buộc gọi hàm export()
 */
export default HistoryAction.export()
import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'
import { 
    IconButton, 
    Icon, 
    Tooltip, 
    Button, 
    Card, 
    CardContent, 
    CardActions, 
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,

} from '@material-ui/core'
import BaseView from 'views/BaseView'
import PaperFade from 'components/Main/PaperFade'
import { I18n } from 'react-redux-i18n'
import _ from 'lodash'
import common from 'common'
import ExportExcel from 'components/ExportExcel/ExportExcel'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import ReactToPrint from 'react-to-print'

const styles = theme => ({
    canter: {
        textAlign: 'center'
    },
    gridTable: {
        height: "calc(100vh)"
    }
})

class Index extends BaseView {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
        }
        this.onShow = this.onShow.bind(this)
        this.onHide = this.onHide.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onCancel = this.onCancel.bind(this)
    }

    onShow(){
        this.setState({ open: true })
    }

    onHide(){
        this.setState({ open: false })
    }

    onCancel(){
        this.onHide()
    }

    onSubmit(){
        this.props.onSubmit()
        this.onHide()
    }

    renderDialog(){
        return (
            <Dialog
                open={this.state.open}
                onClose={this.onCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{I18n.t('Message.deleteData')}</DialogTitle>
                    <DialogContent>
                        {I18n.t('Message.deleteDialogContent')}
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.onCancel} color="primary">
                        {I18n.t("Button.cancel")}
                    </Button>
                    <Button onClick={this.onSubmit} color="primary" autoFocus>
                        {I18n.t("Button.ok")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    render() {
        const { classes } = this.props
        return (
            <PaperFade showLoading={true}>
               <Card>
                <CardContent>
                    {
                        this.renderDialog()
                    }
                    <center>
                        <Typography className={classes.center} variant='h4'>
                            {I18n.t('Exception.deleteData')}
                        </Typography>
                        <Typography className={classes.center} variant='h6'>
                            {I18n.t('Exception.deleteDataWarning')}
                        </Typography>
                        <br></br>
                        <Button color='primary' variant='contained' onClick={() => this.onShow()}>
                            {I18n.t('Button.deleteData')}
                        </Button>
                    </center>
                </CardContent>
               </Card>
            </PaperFade>
        )
    }
}

Index.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(withRouter(Index))
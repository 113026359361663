import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { I18n } from "react-redux-i18n";

export default class Modal extends React.Component {
  constructor(props) {
      super(props);
      
      this.state = {
      //   show:false,
          hien:false,
        open: false,
      }
  }

  onClose = e => {
    this.props.onClose && this.props.onClose(e);
  };
  
  onSubmit = e => {
    this.props.onSubmit && this.props.onSubmit(e);
  };

  render() {
    if(!this.props.show){
        return null;
    }

    return (      
        <Dialog
            open={this.props.show}
            onClose={this.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title"></DialogTitle>
            <DialogContent>
            申請の承認されていないものがありますがよろしいでしょうか？
            </DialogContent>
            <DialogActions>
            <Button onClick={ this.onClose} color="primary">
            {I18n.t('Button.cancel')}
            </Button>
            <Button onClick={this.onSubmit} color="primary" autoFocus>
            {I18n.t('Button.request')}
            </Button>
            </DialogActions>
        </Dialog>
    )
  }
}
import React from 'react'
import { connect } from 'react-redux'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import MenuToggleAction from '../../actions/MenuToggleAaction'
import BaseContainer, { selector } from 'containers/BaseContainer'

class DrawerButton extends BaseContainer {
    toggleDrawer = () => {
        this.props.dispatch(MenuToggleAction.toggleMenu({
            status: !this.props.data.menuToggle.status
        }))
    }

    render() {
        const { classes, data } = this.props
        if (data.menuToggle.status) {
            return null
        }

        return (
            <Hidden smDown implementation="css">
                <IconButton
                    className={classes.iconButton}
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={this.toggleDrawer}
                >
                    <MenuIcon />
                </IconButton>
            </Hidden>
        )
    }
}

const mapStateToProps = state => {
    return {
        data: selector(state, "menuToggle", {})
    }
}

export default connect(mapStateToProps)(DrawerButton)

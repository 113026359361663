import React from 'react';
import View from 'views/Setting/Time'
import SettingAction from 'actions/SettingAction';
import BaseContainer, { selector } from 'containers/BaseContainer';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n';
import moment from 'moment'
import _ from 'lodash'
/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
class Time extends BaseContainer {
    constructor(props){
        super(props)
        this.state = {
        }
        this.onSubmit = this.onSubmit.bind(this)
    }

    componentDidMount(){
        localStorage.setItem('userCalendar', JSON.parse(localStorage.user)._id)
        localStorage.setItem('dateCalendar', new Date()) 
        this.props.dispatch(SettingAction.getTimeSetting())
        this.props.dispatch(SettingAction.getHolidaySetting())
    }

    onSubmit(data){
        //set date of overTime night end
        let values = _.get(data, 'value', {})
        let { time = [] } = values
        delete values.time
        values.workingDay = []
        let holiday = values.holidayInProvision.concat(values.holidayOutProvision)
        for(let i = 0; i< 6; i++){
            if(!holiday.includes(i)){
                values.workingDay.push(i)
            }
        }
        time = time.map(item => {
            if(moment(item.overTimeNightStart) > moment(item.overTimeNightEnd)){
                item.overTimeNightEnd = moment(item.overTimeNightEnd).add(1, 'day').toISOString()
            }
            return {
                ...item,
                ...values
            }
        })
        let addSlideTime = data.value
        addSlideTime.key = "slideTime"
        time.push(addSlideTime)

        this.props.dispatch(SettingAction.updateTimeSetting({ time: time})).then((res) => {
            this.notify(I18n.t('Message.editDataSuccess'))
        })
    }
    
    onUpdateHoliday = (holiday) =>{
        holiday.map((day, i) => {
            holiday[i].date = moment(day.start).format("YYYY-MM-DD")
        })
        let errorDay = this.checkDuplicateHoliday(holiday)
        if(errorDay){
            return this.notify(`${I18n.t("Message.holidayDuplication")}: [${errorDay}]`,'error')
        }
        this.props.dispatch(SettingAction.updateHolidaySetting({ data: holiday, _id: 1 })).then(() => {
            this.notify(I18n.t('Message.editDataSuccess'))
        })
    }

    checkDuplicateHoliday(events){
        let count = {}
        for(let event of events){
            let end = event.end
            let start = event.start
            for(let day = start; day < end; day = moment(day).add(1,"days").toDate()){
                let key = moment(day).format("YYYYMMDD")
                if(!count[key]) count[key] = 0;
                    count[key]++
                if(count[key] > 1) 
                    return key
            }
        }
        return null
    }

    render() {
        return (
            <View
                data = {this.props.data}
                onSubmit = {this.onSubmit}
                onUpdateHoliday = {this.onUpdateHoliday}
            />
        )
    }
}


const mapStateToProps = state => {
    return {
        data: selector(state, "setting", {})
    }
}

export default withRouter(connect(mapStateToProps)(Time))
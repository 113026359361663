import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Form, DateTimeField, Validation } from 'components/Forms'
import { BaseView } from 'views/BaseView'
import { I18n } from 'react-redux-i18n'
import { Grid, Button } from '@material-ui/core'
import Fab from '@material-ui/core/Fab';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import moment from 'moment'

const styles = theme => ({
    form: {
        [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 2}px`,
        },
        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 4}px`,
        }
    },
    DateTime: {
        marginBottom: 0
    }
});

class FilterForm extends BaseView {
    constructor(props) {
        super(props)
        this.validate = {
            startDate: [Validation.required(I18n.t("Form.required"))],
            endDate: [Validation.required(I18n.t("Form.required"))],
        };

        this.state = {
            startDate: moment().startOf('month'),
            endDate: moment()
        }
    }

    onSubmitDate = (data) => {
        this.setState({
            startDate: data.startDate,
            endDate: data.endDate
        })
        let dateRange = {
            startDate: data.startDate.format('YYYY-MM-DD'),
            endDate: data.endDate.format('YYYY-MM-DD')
        }
        this.props.onSubmitDate(dateRange)
    }

    componentDidMount() {
        if (typeof this.props.onRef == "function") {
            this.props.onRef(this) 
        }   
    }

    render() {
        const { classes } = this.props
        return (
            // <PaperFade className={classes.paper}>
            <Form className={classes.form} onSubmit={this.onSubmitDate}>
                <Grid container
                    direction="row"
                    justify="center"
                    alignItems="flex-end" spacing={8}>
                    <Grid item xs={5} md = {4} lg = {3}>
                        <DateTimeField
                            fullWidth
                            label={I18n.t("Common.from")}
                            name="startDate"
                            format="YYYY.MM.DD"
                            clearable={false}
                            autoOk={true}
                            showTime={false}
                            showDate={true}
                            value={this.state.startDate}
                            className={classes.DateTime}
                            // onChange={this.onChangeStartDate}
                            validate={this.validate.startDate}
                        />
                    </Grid>
                    <Grid item xs={5} md = {4} lg = {3}>
                        <DateTimeField
                            fullWidth
                            label={I18n.t("Common.to")}
                            clearable={false}
                            name="endDate"
                            format="YYYY.MM.DD"
                            autoOk={true}
                            showTime={false}
                            showDate={true}
                            value={this.state.endDate}
                            className={classes.DateTime}
                            // onChange={this.onChangeEnddate}
                            validate={this.validate.endDate}
                        />
                    </Grid>
                    <Grid item xs={2} md = {4} lg = {6}>
                        <Hidden smDown>
                            <Button type="submit" variant="contained" color="primary" >
                                {I18n.t("Button.search")}
                            </Button>
                        </Hidden>
                        <Hidden mdUp>
                            <Fab color="primary"
                                type='submit'
                                // onClick={this.onSubmit}
                                size="small" aria-label="add"
                                className={classes.fab}>
                                <Icon>search</Icon>
                            </Fab>
                        </Hidden>
                    </Grid>

                </Grid>
            </Form>
            // </PaperFade>
        )
    }
}

FilterForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FilterForm)
import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Login from './layouts/Login'
import Authorize from './layouts/Authorize'
import Main from './layouts/Main'
import withRoot from './withRoot';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
class Routes extends React.Component {
  checkJWTToken() {
    try {
      // let token = localStorage.getItem('token')
      // var base64Url = token.split('.')[1];
      // var base64 = base64Url.replace('-', '+').replace('_', '/');
      // let decodedToken = JSON.parse(window.atob(base64));
      // var dateNow = new Date();
      let user = localStorage.getItem('user')
      localStorage.setItem('frontTimeZone', new Date().getTimezoneOffset() / 60)
      // if (decodedToken.exp && decodedToken.exp < dateNow.getTime())
        //return false
      if (user) return true 
      return false
    } catch (e) {
      //return false;
    }
    return false
  }
  render() {
    let user = JSON.parse(localStorage.getItem('user'))

    let ASSET_MANAGEMENT_URL = process.env.REACT_APP_ASSET_MANAGEMENT_PRODUCTION_URL
    if (process.env.NODE_ENV === `development` || ["localhost", "time-management.javis.vn", "time-wlm-management.javis.vn"].includes(window.location.hostname)) {
      ASSET_MANAGEMENT_URL = process.env.REACT_APP_ASSET_MANAGEMENT_STAGING_URL
    }

    let ASSET_ACCESS_TOKEN = process.env.REACT_APP_ASSET_ACCCESS_TOKEN
    let user_code = !["", undefined, null].includes(user) && user.code !== undefined ? user.code : ""

    let redirectToAsset = `time_token=${ASSET_ACCESS_TOKEN}&time_user_code=${user_code}`
    return (
      <ErrorBoundary>
          <BrowserRouter>
            <Switch>
              <Route path="/login" name="Login Page" component={Login} />
              <Route path="/authorize/:accessToken" name="Authorize" component={Authorize} />
              <Route path='/calendar_booking' component={() => { 
                localStorage.setItem('redirectAsset', true);
                window.location.href = `${ASSET_MANAGEMENT_URL}/operator/printing/planToReturn?${redirectToAsset}`; 
                return null;
              }}/>
              <Route path='/list_product' component={() => { 
                localStorage.setItem('redirectAsset', true);
                window.location.href = `${ASSET_MANAGEMENT_URL}/operator/printing/products?${redirectToAsset}`; 
                return null;
              }}/>
              <Route path='/revenue' component={() => { 
                localStorage.setItem('redirectAsset', true);
                window.location.href = `${ASSET_MANAGEMENT_URL}/admin/printing/revenue?${redirectToAsset}`; 
                return null;
              }}/>
              <Route path='/printing' component={() => { 
                localStorage.setItem('redirectAsset', true);
                window.location.href = `${ASSET_MANAGEMENT_URL}/admin/printing?${redirectToAsset}`; 
                return null;
              }}/>
               <Route path='/damageNotDefine' component={() => { 
                localStorage.setItem('redirectAsset', true);
                window.location.href = `${ASSET_MANAGEMENT_URL}/admin/damage-notDefine?${redirectToAsset}`; 
                return null;
              }}/>
              
              <Route path="/" render={(props) => (
                this.checkJWTToken()
                  ? (<Main {...props} />)
                  : (<Redirect to={{
                    pathname: '/login',
                    state: {
                      from: props.location
                    }
                  }} />))} />
            </Switch>
          </BrowserRouter>
      </ErrorBoundary>
    )
  }
}
export default withRoot(Routes)
import BaseReducer from './BaseReducer';
/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
class MonthlyReportManagement extends BaseReducer {
  get actionsAllow() {
    return {
      ...super.actionsAllow,
      'MonthlyReportManagement.fetchAll': {
        path: 'list'
      },
      'MonthlyReportManagement.fetchByIds': {
        path: 'fetchByIds'
      },
      'MonthlyReportManagement.approveReport': {
        path: 'approveReport'
      }
    }
  }

  get initialState() {
    return {
      ...super.initialState,
      error: {
        message: null
      }
    }
  }
}
/*
 * bắt buộc gọi hàm export()
 */
export default MonthlyReportManagement.export()
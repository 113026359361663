import React from 'react'
import PropTypes from 'prop-types'
import BaseContainer from 'containers/BaseContainer'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles'
import { I18n } from 'react-redux-i18n'

const styles = theme => ({
   link: {
    fontSize: "17px"
   }
})

class LineAPI extends BaseContainer {

    constructor(props) {
        super(props)
        this.redirect_uri = window.location.origin + "/line"
        this.client_id = process.env.REACT_APP_LINE_CHANNEL_ID;  // lấy client d từ channel id trong developer
        let response_type = "code";  //get code from line api
        // let state = Math.floor(Math.random() * Math.pow(10, 10));
        let state = JSON.parse(localStorage.user)._id
        let scope = "notify";
        // let nonce = JSON.parse(localStorage.user)._id

        this.authorization_request = `https://notify-bot.line.me/oauth/authorize?response_type=${response_type}&client_id=${this.client_id}&redirect_uri=${this.redirect_uri}&state=${state}&scope=${scope}`
    }

    
    render() {
        const { classes} = this.props
        return (
            <Link
                variant="body2"
                className = {classes.link}
                href={this.authorization_request}
                color="inherit"
                target = "_blank"
            >
                {I18n.t("Button.line_account")}
          </Link>
        )
    }
}

const mapStateToProps = state => {
    return {
    }
}
LineAPI.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withRouter(connect(mapStateToProps)(withStyles(styles)(LineAPI)))
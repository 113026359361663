import BaseReducer from './BaseReducer';
/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
class MonthlyReport extends BaseReducer {
  get actionsAllow() {
    return {
      ...super.actionsAllow,
      "MonthlyReport.create": {
        path: "data"
      },
      "MonthlyReport.edit": {
        path: "data"
      },
      "MonthlyReport.delete": {
        path: "data"
      },
      "MonthlyReport.destroy": {
        path: "data"
      },
      "MonthlyReport.fetchAll": {
        path: "list"
      },
      "MonthlyReport.fetch": {
        path: "item"
      },
      "MonthlyReport.getApprovePaidHalf": {
        path: "data"
      }
    }
  }

  get initialState() {
    return {
      ...super.initialState,
      error: {
        message: null
      }
    }
  }
}
/*
 * bắt buộc gọi hàm export()
 */
export default MonthlyReport.export()
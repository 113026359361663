import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { I18n } from 'react-redux-i18n'
import BaseView from 'views/BaseView'
import { Button } from '@material-ui/core'
import PaperFade from 'components/Main/PaperFade'
import Calendar from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Card, CardHeader } from '@material-ui/core'
import moment from 'moment'
import DialogEvent from './DialogEvent'

const styles = theme => ({
    paper: {
        padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 4}px`,
    },
    button: {
        marginTop: `${theme.spacing.unit * 3}px`
    },
    cardHeader: {
        padding: ` ${theme.spacing.unit * 3}px ${theme.spacing.unit * 4}px`,
    },
    calendar: {
        height: 600,
        '& .rbc-month-view': {
          border: 'none',
          '& .rbc-event': {
            paddingLeft: '2px',
            borderRadius: 3,
            backgroundColor: theme.palette.secondary.main,
          },
          '& .rbc-now': {
            color: theme.palette.secondary.main,
          },
          '& .rbc-selected-cell': {
            backgroundColor: '#fff',
          },
          '& .rbc-today.rbc-selected-cell': {
            backgroundColor: '#eaf6ff',
          },
          '& .rbc-off-range-bg.rbc-selected-cell': {
            background: '#e6e6e6',
          },
          '& .rbc-month-row': {
            cursor: 'pointer',
          },
        },
    },
});

const DragAndDropCalendar = withDragAndDrop(Calendar)
const globalizeLocalizer = Calendar.momentLocalizer(moment)

class CardHoliday extends BaseView {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
            events: []
        }
        this.eventId = 0;
        this.onEventDrop = this.onEventDrop.bind(this);
        this.onEventResize = this.onEventResize.bind(this);
    }

    componentDidUpdate(prevProps) {
        //khởi tạo events
        if (this.eventId === 0 && this.props.data.length) {
            let i = 0;
            this.setState({
                events: [
                    ...this.props.data?.map(event => {
                        event.id = i++;
                        event.start = new Date(event.start);
                        event.date = moment(event.start).format("YYYY-MM-DD")
                        event.end = new Date(event.end)
                        return event
                    })
                ]
            })
            this.eventId = this.props.data.length
        }
    }
    handleSelect = (event) => {
        if (event.id == null) {
            event.title = ""
            event.id = null
            event.end = moment(event.start).add("1", "days").toDate()
        }
        this.DialogEventRef.show(event)
        return false
    }
    updateEvent = (event) => {
        event = {
            id: event.id,
            start: event.start,
            end: event.end,
            title: event.title,
            type: event.type
        }
        if (!event.title)
            return
        if (event.id == null) {
            event.id = this.eventId++;
            this.setState({
                events: [
                    ...this.state.events,
                    event
                ]
            })
        } else {
            const { events } = this.state
            for (let eventIndex in events) {
                if (events[eventIndex].id === event.id) {
                    events[eventIndex] = {
                        ...event
                    }
                }
            }
            this.setState({ events: [...this.state.events] })
        }
    }
    deleteEvent = (event) => {
        const newEvents = this.state.events.filter(existEvent => existEvent.id !== event.id)
        this.setState({ events: [...newEvents] })
    }
    onEventResize({ event, start, end }) {
        const { events } = this.state
        const nextEvents = events.map(existingEvent => {
            return existingEvent.id === event.id
                ? { ...existingEvent, start, end }
                : existingEvent
        })
        this.setState({ events: nextEvents })
    }
    onEventDrop({ event, start, end }) {
        const { events } = this.state;
        const idx = events.indexOf(event);
        const updatedEvent = { ...event, start, end };
        const nextEvents = [...events];
        nextEvents.splice(idx, 1, updatedEvent);
        this.setState({ events: nextEvents })
    }
    eventPropGetter = (event, start, end, isSelected) => {
        let style = {
            fontSize: '15px'
        }
        if (event.type === "1") {
            style = { ...style, backgroundColor: '#ff6600'}
            return { style, type: 'button', x: 1 }
        }
        if (event.type === "0") {
            style = { ...style, backgroundColor: '#33B2FF'}
            return { style, type: 'button', x: 1 }
        }
    }
    submitHoliday = () => {
        if (this.props.onSubmit) {
            this.props.onSubmit(this.state.events)
        }
    }

    render() {
        const { classes } = this.props
        let formats = {
            monthHeaderFormat: 'YYYY/MM/DD'
        }
        let messages = {
            previous: I18n.t("Calendar.back"),
            next:  I18n.t("Calendar.next"),
            today:  I18n.t("Calendar.today")
        }
        return (
            <Card>
                <CardHeader
                    className={classes.cardHeader}
                    title={I18n.t("Label.settingHoliday")}
                />
                <PaperFade className={classes.paper}>
                    <Calendar
                        formats={formats}
                        culture={I18n.t("Calendar.localizer")}
                        style={{ height: '80vh' }}
                        className={classes.calendar}
                        messages = {messages}
                        localizer={globalizeLocalizer}
                        events={this.state.events}
                        selectable={true}
                        onSelectSlot={this.handleSelect}
                        onSelectEvent={this.handleSelect}
                        resizable
                        onEventResize={this.onEventResize}
                        onEventDrop={this.onEventDrop}
                        defaultDate={new Date()}
                        eventPropGetter={this.eventPropGetter}
                        tooltipAccessor={null}
                        views={['month']}
                    />
                    <DialogEvent
                        ref={(ref) => this.DialogEventRef = ref}
                        onSubmit={this.updateEvent}
                        onDelete={this.deleteEvent}
                    />
                        <Button size="small" variant='contained' className={classes.button} color="primary" style={{ marginTop: '70px' }} onClick={this.submitHoliday}>
                        {I18n.t("Button.submit")}
                        </Button>

                </PaperFade>
            </Card>
        )
    }
}

export default withStyles(styles)(CardHoliday);
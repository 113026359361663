import React from 'react';
import View from 'views/Hour/Detail'
import HourAction from '../../actions/HourAction';
import BaseContainer, { selector } from 'containers/BaseContainer';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n';
import moment from 'moment'
/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
class Create extends BaseContainer {
    constructor(props) {
        super(props)
        this.row = ''
    }

    
    componentDidMount(){
        localStorage.setItem('userCalendar', JSON.parse(localStorage.user)._id)
        localStorage.setItem('dateCalendar', new Date())  

        const startDate = moment().startOf('month').format('YYYY-MM-DD');
        const endDate = moment().format('YYYY-MM-DD')
        this.props.dispatch(HourAction.fetchAll({ startDate, endDate, unique: true, _isRound: true })).then(data => {
            if (data.error) {
                this.notify(`Response: [${data.error.status}] ${data.error.message}`, 'error')
            }
        })
    }
    onSubmit = (data) => {
        const startDate = moment(data.startDate).format('YYYY-MM-DD');
        const endDate = moment(data.endDate).format('YYYY-MM-DD')
        this.props.dispatch(HourAction.fetchAll({ startDate, endDate, unique: true, _isRound: true })).then(data => {
            if (data.error) {
                this.notify(`Response: [${data.error.status}] ${data.error.message}`, 'error')
            }
        })
    }
    render() {
        const  data  = this.props.data

        // xét xem có phải là data server trả về không
        let row
        let showTable = data.unique
        if(showTable){
            [row] = data.data
        }
        return (
            <View
                onSubmit={this.onSubmit}
                row={row}
                showTable={showTable}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        //sử dụng selector để lấy state từ redux
        data: selector(state, "hour.list", '')
    }
}

export default withRouter(connect(mapStateToProps)(Create))
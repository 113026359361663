import React from "react";
import { I18n } from "react-redux-i18n";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import withStyles from "@material-ui/core/styles/withStyles";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import { connect } from "react-redux";
import RequestAction from "../../../actions/RequestAction";
import CalendarAction from "../../../actions/CalendarAction";
import UtilityAction from "../../../actions/UtilityAction";
import {
	Button,
	Dialog,
	AppBar,
	IconButton,
	Card,
	CardContent,
	Grid,
} from "@material-ui/core";
import moment from "moment";
import _ from "lodash";

const styles = (theme) => ({
	setDialogRequest: {
		maxHeight: "400px",
		minHeight: "400px",
		overflowY: "scroll",
		overflowX: "hidden",
		[theme.breakpoints.down("xs")]: {
			maxHeight: "100%",
			minHeight: "auto",
			height: "100vh",
			overflowY: "scroll",
			overflowX: "hidden",
		},
	},
	paper: {
		padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 4}px`,
	},
	button: {
		marginTop: `${theme.spacing.unit * 2}px`,
	},
	root: {
		flexGrow: 1,
	},
	fixHiddenDialog: {
		overflow: "hidden !important",
	},
});

class DialogEvent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			data: {},
			startDate: null,
		};
		this.onCancel = this.onCancel.bind(this);
	}

	show = (data = null) => {
		this.setState({
			open: true,
			data: { ...this.state.data, ...data },
			startDate: data.start,
		});
	};

	hide() {
		this.setState({ open: false });
	}

	onCancel() {
		const { onCancel } = this.props;
		this.hide();
		if (typeof onCancel === "function") {
			onCancel(this.state.data);
		}
		window.localStorage.removeItem("event");
	}

	setBackCalendar() {
		localStorage.setItem("back", "calendars");
		localStorage.setItem("createRequest", "createRequest");
	}

	onDeleteRequest(selectedIds) {
		let checkTimeZoneFE = localStorage.getItem("frontTimeZone");
		this.props
			.dispatch(
				RequestAction.delete({
					ids: selectedIds,
					checkTimeZoneFE: checkTimeZoneFE,
				})
			)
			.then((res) => {
				let userCalendar = localStorage.getItem("userCalendar");
				let dateCalendar = localStorage.getItem("dateCalendar");
				let yearMonth = moment(new Date()).format("YYYY-MM");
				if (dateCalendar) {
					yearMonth = moment(dateCalendar).format("YYYY-MM");
				}
				this.props.dispatch(
					CalendarAction.listWorkerRequestsCalendar({
						userId: userCalendar,
						yearMonth: yearMonth,
					})
				);
				this.props.dispatch(
					CalendarAction.listUserDayOffCalendar({
						userId: userCalendar,
						yearMonth: yearMonth,
					})
				);
				this.hide();
				this.props.dispatch(
					UtilityAction.notify({
						message: I18n.t("Message.deleteDataSuccess"),
						type: "success",
					})
				);
			});
	}

	render() {
		let _dataRequest = _.get(this.state.data, "dataRequest", []) || [];
		let { classes } = this.props;
		let userCalendar = localStorage.getItem("userCalendar");
		let checkUserLogin = false;
		if (JSON.parse(localStorage.user)._id === userCalendar) {
			checkUserLogin = true;
		}
		let permissions = JSON.parse(localStorage.user).permissions || [];
		let mana_authority = false;
		let mana_authority_update = false;
		for (let i of permissions) {
			switch (i) {
				case "mana_authority":
					mana_authority = true;
					break;
				case "mana_authority_update":
					mana_authority_update = true;
					break;
				default:
					break;
			}
		}

		let width = window.innerWidth;
		let { data } = this.state;
		let typeRequest = _.get(data, "typeRequest", "");

		switch (typeRequest) {
			case 1:
				typeRequest = I18n.t("Input.request.typeEarlyLate");
				break;
			case 2:
				typeRequest = I18n.t("Input.request.typeOT");
				break;
			case 3:
				typeRequest = I18n.t("Input.request.typeSalaryCalendar");
				break;
			case 4:
				typeRequest = I18n.t("Input.request.typeWorkOnDayOff");
				break;
			case 5:
				typeRequest = I18n.t("Input.request.typeCompensatedCalendar");
				break;
			case 6:
				typeRequest = I18n.t("Input.request.typeReplace");
				break;
			case 8:
				typeRequest = I18n.t("Input.request.absent");
				break;
			case 9:
				typeRequest = I18n.t("Input.request.dayoff");
				break;
			case 10:
				typeRequest = I18n.t("Input.request.typeLate");
				break;
			default:
				break;
		}
		return (
			<Card className={classes.fixHiddenDialog}>
				<Dialog
					fullScreen={width <= 500 ? true : false}
					fullWidth={true}
					open={this.state.open}
					maxWidth="sm"
					aria-labelledby="draggable-dialog-title"
				>
					<AppBar position="static">
						<span style={{ textAlign: "right" }}>
							<Grid container spacing={0}>
								<Grid item xs={10}>
									<p style={{ textAlign: "left", marginLeft: "15px" }}>
										{I18n.t("Exception.listRequest")}
									</p>
								</Grid>
								<Grid item xs={2}>
									<IconButton
										onClick={this.onCancel}
										color="inherit"
										aria-label="menu"
									>
										<CloseIcon />
									</IconButton>
								</Grid>
							</Grid>
						</span>
					</AppBar>
					<div className={classes.setDialogRequest}>
						{_dataRequest.map((item, index) => {
							let _id = _.get(item, "_id", "");
							let typeRequest = item.type;
							let status = _.get(item, "status", "");

							if (item.userId === JSON.parse(localStorage.user)._id) {
								mana_authority = true;
							}
							switch (typeRequest) {
								case 1:
									typeRequest = I18n.t("Input.request.typeEarlyLate");
									break;
								case 2:
									typeRequest = I18n.t("Input.request.typeOT");
									break;
								case 3:
									typeRequest = I18n.t("Input.request.typeSalaryCalendar");
									break;
								case 4:
									typeRequest = I18n.t("Input.request.typeWorkOnDayOff");
									break;
								case 5:
									typeRequest = I18n.t("Input.request.typeCompensatedCalendar");
									break;
								case 6:
									typeRequest = I18n.t("Input.request.typeReplace");
									break;
								case 8:
									typeRequest = I18n.t("Input.request.absent");
									break;
								case 9:
									typeRequest = I18n.t("Input.request.dayoff");
									break;
								case 10:
									typeRequest = I18n.t("Input.request.typeLate");
									break;
								default:
									break;
							}
							return (
								<div key={index}>
									<CardContent>
										<Grid container spacing={0}>
											<Grid item xs={8}>
												<p>
													{I18n.t("Input.date")}:{" "}
													{moment(item.date).format("YYYY/MM/DD")}
												</p>
												<p>
													{mana_authority || mana_authority_update ? (
														<Link
															to={{ pathname: `/calendars/requests/${_id}` }}
															style={{ textDecoration: "none" }}
														>
															{I18n.t("Input.typeRequest")}: {typeRequest}
														</Link>
													) : (
														<span>
															{I18n.t("Input.typeRequest")}: {typeRequest}
														</span>
													)}
												</p>
											</Grid>
											<Grid item xs={4}>
												{status ? (
													<Button
														className={classes.button}
														color={"primary"}
														variant="contained"
														size="small"
													>
														{I18n.t("Button.approved")}
													</Button>
												) : checkUserLogin || mana_authority_update ? ( // kiem tra nếu là xem lịch của chính mình mới dc xin phép lại
													<>
														<Link
															to={{
																pathname: `/calendars/requests/${_id}/edit`,
															}}
															style={{
																textDecoration: "none",
																marginRight: "0.5rem",
															}}
														>
															<Button
																className={classes.button}
																color={"primary"}
																variant="contained"
																size="small"
															>
																{I18n.t("Button.unApproved")}
															</Button>
														</Link>
													</>
												) : (
													<Button
														disabled={true}
														className={classes.button}
														color={"primary"}
														variant="contained"
														size="small"
													>
														{I18n.t("Button.unApproved")}
													</Button>
												)}
												<Button
													disabled={!checkUserLogin && !mana_authority_update}
													className={classes.button}
													color={"secondary"}
													variant="contained"
													size="small"
													onClick={() => this.ConfirmDialog.show([item._id])}
													style={{ marginLeft: "5px", alignItems: "center" }}
												>
													{I18n.t("Button.delete")}
												</Button>
												<ConfirmDialog
													ref={(ref) => (this.ConfirmDialog = ref)}
													title={I18n.t("Message.deleteDialogTitle")}
													content={I18n.t("Message.deleteDialogContent")}
													onSubmit={this.onDeleteRequest.bind(this)}
												/>
											</Grid>
										</Grid>
									</CardContent>
								</div>
							);
						})}
					</div>
					<div style={{ textAlign: "right" }}>
						{checkUserLogin || mana_authority_update ? ( // kiem tra nếu là xem lịch của chính mình mới dc xin phép lại
							<Grid container spacing={8}>
								<Grid item xs={7}></Grid>
								<Grid
									item
									xs={4}
									style={{ textAlign: "right", paddingBottom: "5%" }}
								>
									<Link
										style={{ textDecoration: "none" }}
										to={{ pathname: "/calendars/requests/create" }}
									>
										<Button
											style={{ width: "70%" }}
											variant="outlined"
											size="small"
											color="primary"
											onClick={() => this.setBackCalendar()}
										>
											{I18n.t("Button.addRequest")}
										</Button>
									</Link>
								</Grid>
							</Grid>
						) : (
							<Grid container spacing={8}>
								<Grid item xs={7}></Grid>
								<Grid
									item
									xs={4}
									style={{ textAlign: "right", paddingBottom: "5%" }}
								>
									<Button
										disabled={true}
										variant="outlined"
										size="small"
										color="primary"
										onClick={() => this.setBackCalendar()}
									>
										{I18n.t("Button.addRequest")}
									</Button>
								</Grid>
							</Grid>
						)}
					</div>
				</Dialog>
			</Card>
		);
	}
}

export default connect()(withStyles(styles)(DialogEvent));

import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import ReactToPrint from "react-to-print";
import { IconButton, Icon, Tooltip, Button, Chip, Grid } from '@material-ui/core'
import BaseView from 'views/BaseView'
import PaperFade from 'components/Main/PaperFade'
import PDFComponent from './PrintTable'
import { I18n } from 'react-redux-i18n'
import Form from 'components/Forms/Form'
import { DateTimeField, RadioGroupField, TextField, Validation} from 'components/Forms'
import { Radio } from 'components/Forms/RadioGroupField'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import Modal from 'components/Dialogs/Modal'
import AddBtn from '@material-ui/icons/ControlPoint'
import SelectField, { Option } from 'components/Forms/SelectField'
import _ from 'lodash'
import { connect } from 'react-redux'
import CalendarAction from '../../actions/CalendarAction'

const GridTable = React.lazy(() => import('components/Table/GridTable'))
const styles = theme => ({
    gridTable: {
        height: "calc(100vh - 100px)"
    },
    onValidate: {
        color: 'red',
    },
    chip: {
        textAlign: 'center',
        justifyContent: 'center',
        marginBottom: '2px',
    },
    textCenter: {
        textAlign: 'center'
    },
    floatRight: {
        float: 'right'
    }
})

let filters = []

class Index extends BaseView {
    constructor(props) {
        super(props)
        this.state = {
            dayOffDate: null,
            dayOffType: null,
            onValidate: true,
            startDate: '',
            endDate: '',
            typeDayOff: '',
            radioValue: '',
            reload: false, 
            dateRequest: null,
            existRemainTotal: [],
        }
        this.table = {
            columns: [
                // {
                //     name: 'index',
                //     title: I18n.t("Table.header.user.index"),
                //     type: "text",
                //     filterable: false,
                //     sortable: false
                // },
                {
                    name: 'code',
                    title: I18n.t('Table.header.user.code'),
                },
                {
                    name: 'name',
                    title: I18n.t('Table.header.user.name'),
                },
                {
                    name: 'paidVacationDays',
                    title: I18n.t('Table.header.user.paidVacationDays'),
                    filterable: false,
                    style: {
                        color: "#33cc33"
                    },
                },
                {
                    name: 'remainTotal',
                    title: I18n.t('Table.header.user.remain'),
                    filterable: false,
                    style: {
                        color: "#33cc33"
                    },
                },
                {
                    name: 'remainByWorkingOnDayOff',
                    title: I18n.t('Table.header.user.remainByWorkingOnTimeOff'),
                    filterable: false,
                    formatterComponent: (data) => {
                        return _.get(data.row, 'remainByWorkingOnDayOff', '')
                    },
                    filterable: false,
                    style: {
                        color: "#f77f0f"
                    },
                },
                {
                    name: 'DayOff3',
                    title: I18n.t('Table.header.user.dayOffInFuture'),
                    style: {
                        textAlign: 'center'
                    },
                    formatterComponent: (data) => {
                        return this.customDayOffInFuture(data)
                    },
                    filterable: false,
                    sortable: false
                },
            ],
            defaultSort: [],
            tableColumnExtensions: [
                { columnName: 'name', wordWrapEnabled: true },
                { columnName: '_id', align: 'center' },
                { columnName: 'code', wordWrapEnabled: true },
                { columnName: 'paidVacationDays', wordWrapEnabled: true, align: "left" },
                { columnName: 'remainTotal', wordWrapEnabled: true, align: "left" },
                { columnName: 'remainByWorkingOnDayOff', wordWrapEnabled: true, align: "left" },
                { columnName: 'DayOff3', wordWrapEnabled: true },
            ],
            //nếu tổng nhỏ hơn 990 thì tính theo %, ngược lại tính theo px
            columnWidths: [
                // {
                //     name: 'index',
                //     width: 90
                // },
                {
                    name: 'code',
                    width: 150
                },
                {
                    name: 'name',
                    width: 150
                },
                {
                    name: 'paidVacationDays',
                    width: 160
                },
                {
                    name: 'remainTotal',
                    width: 130
                },
                {
                    name: 'remainByWorkingOnDayOff',
                    width: 130
                },
                {
                    name: 'DayOff3',
                    width: 250
                }
            ]
        }
        this.validate = {
            date: [
                Validation.required(I18n.t("Form.required")),
            ]
        }
        this.ConfirmDialog = null
        this.userEditing = {}
        this.onChangeArea = this.onChangeArea.bind(this)
        this.onChangeDate = this.onChangeDate.bind(this)
        this.renderToolbarActions = this.renderToolbarActions.bind(this)
        this.onChangeRadio = this.onChangeRadio.bind(this)
        this.formatValueRadio = this.formatValueRadio.bind(this)
    }

    validateNewDayOff(dataRow) {
        return (value) => {
            if (new Date() > new Date(value)) {
                return I18n.t('Form.pastDate')
            }
            let duplicated = dataRow.dayOffs.filter(day => moment(day.date).format("YYYY-MM-DD") === moment(value).format("YYYY-MM-DD")).length
            if (duplicated) {
                return I18n.t('Form.duplicateDate')
            }
        }
    }

    renderToolbarActions() {
        const  data  = this.props.data;
        // const  user  = this.props.data
        const {
          ignoreExcelColumns,
          columnWidths,
          columns,
          ignorePrintColumns
        } = this.table;
        // const headerText = this.getHeaderText();
        // const userInfo = [[user.name, `社員コード：${user.code}`]]
        return [
            <ReactToPrint
                trigger={() => <Tooltip title={I18n.t('toolTip.print')} key="print">
                    <IconButton>
                    <Icon>local_printshop</Icon>
                    </IconButton>
                </Tooltip>
                }
                content={() => this.printArea}
            />,
            <div style={{display: 'none'}}>
                <div ref={(ref) => this.printArea = ref}>
                <PDFComponent
                    data={this.props.data}
                    printMode={true}
                    exportType='print'
                    autoDownload={false}
                    classes={{}} 
                    estimatedRowHeight={70}
                    pageHiding={true}
                    id="DayOffIndex22"
                    // className={classes.gridTable}
                    onFetchData={this.props.onFetchData}
                    onRefTable={this.props.onRefTable}
                    columns={this.table.columns}
                    rows={data}
                    totalCount={data.total}
                    pageSize={data.pageSize}
                    defaultSort={this.table.defaultSort}
                    showCheckboxColumn={false}
                    height="auto"
                    tableActions={this.renderToolbarActions}
                    tableColumnExtensions={this.table.tableColumnExtensions}
                    defaultColumnWidths={this.table.columnWidths}
                    />
                </div>
            </div>,
        ]
    }

    onSubmit(values, dataRow) {
        let type = _.get(values, 'type', '')
        let userId = _.get(dataRow, '_id', '')
        let subtractHour = _.get(values, 'subtractHour', '')
        let permissions = JSON.parse(localStorage.user).permissions || []
        let dayWorkOnDayOff = _.get(values, 'dayWorkOnDayOff', '')
        let checkTimeZoneFE = localStorage.getItem("frontTimeZone");

        let converData = {
            date: moment(values.date).format("YYYY-MM-DD"),
            type: type,
            userId: userId,
            subtractHour: subtractHour,
            permissions: permissions,
            dayWorkOnDayOff: moment(dayWorkOnDayOff).format("YYYY-MM-DD"),
            pageDayOff: true,
            checkTimeZoneFE: checkTimeZoneFE
        }

        // if(type != '5'){
        //     delete converData.subtractHour
        // }
        if(type){
            this.props.onSubmit(converData)
        }
        if(this.state.radioValue){
            this.modalAddDay.hide()
        }
        this.setState({ radioValue: '', reload: !this.state.reload })
    }

    formatValueRadio(){
        this.setState({ radioValue: '', reload: !this.state.reload })
        this.modalAddDay.hide()
    }

    // 1
    customDayOffInFuture(data) {
        let { classes } = this.props
        let dataRow = _.get(data, "row", {})
        let listDayOffs = _.get(data.row, "dayOffs", [])
        listDayOffs = _.orderBy(listDayOffs, ['date'], ['asc']) //sort data theo thứ tự ngày/tháng/năm tăng dần
        
        return (
            <React.Fragment>
                <div className={classes.textCenter}>
                    <Tooltip title={I18n.t("toolTip.new")} key="iconAddDay">
                        <AddBtn color="primary" onClick={() => this.addDate(dataRow)} />
                    </Tooltip>
                </div>
                {
                    listDayOffs.map((item, index) => (
                        <Chip key={index} className={classes.chip}
                            // type = 3 co luong, 4 khong luong, 5 nghi bu
                            style={{ background: item.type === '3' ? '#33cc33' : item.type === '5' ? '#f77f0f' : '' }}
                            variant="default"
                            color='primary'
                            size='small'
                            label={moment(item.date).format("MM/DD")}
                            onDelete={() => this.ConfirmDialog.show(item._id)}
                        />
                    ))
                }

            </React.Fragment>
        )
    }

    onDeleteDay = () => {
        return <ConfirmDialog
            ref={(ref) => this.ConfirmDialog = ref}
            title={I18n.t('Message.deleteDateDialogTitle')}
            content={I18n.t('Message.deleteDialogContent.')}
            onSubmit={this.props.onDelete}
        />
    }

    onChangeRadio(value) {
        this.setState({ radioValue: value })
    }

    addDate = (dataRow) => {
        this.modalAddDay.show(dataRow)
    }

    renderAddDate = (dataRow) => {   
        return (
            <Modal
                ref={(ref) => this.modalAddDay = ref}
                title={I18n.t('DayOff.addDayOff')}
                content={(dataRow) => this.rowDayOff(dataRow)}
            />
        )
    }

    onChangeDateRequest = (dataRow, value) => {
        this.setState({dateRequest: moment(new Date(value)).format('YYYY/MM/DD')})
        this.props.dispatch(CalendarAction.listRemainTotalCompensatedThisMonth({ userId: dataRow._id, yearMonth: moment(new Date(value)).format('YYYY-MM'), date: moment(new Date(value)).format('YYYY-MM-DD') })).then(response => {
            if(!response.error) {
                this.setState({existRemainTotal: response.data})
            }
        })
    }

    onChangeDateCompensated = (userId, value) => {
        let yearMonth = moment(this.state.dateRequest).format('YYYY-MM')
        this.props.dispatch(CalendarAction.getUserDayOffThisDayCalendar({ userId: userId._id, yearMonth: yearMonth, date: moment(new Date(value)).format('YYYY-MM-DD') })).then(response => {
            if(!response.error) {
                this.setState({timeWorkOffApprove: response.data.remainTotal})
            }
        })
    }

    disableWeekends = (date) => {     
        let month = (new Date(this.state.dateRequest)).getMonth()
        let year = (new Date(this.state.dateRequest)).getFullYear()
        let thisMonth = new Date(year, month, 1)
  
        if(moment(new Date(date)).format('YYYY-MM') <= moment(thisMonth).format('YYYY-MM')
           || moment(new Date(date)).format('YYYY-MM') >= moment(thisMonth).format('YYYY-MM')){
            let check = true
            if(this.state.existRemainTotal.length > 0) {
                this.state.existRemainTotal.map(item => {
                   if(moment(new Date(date)).format('YYYY-MM-DD') === moment(new Date(item)).format('YYYY-MM-DD')){
                      check = false
                      return
                   }
                })
                return ((new Date(date)).getDay() !== 0 && (new Date(date)).getDay() !== 6) || (check)
            }
        }
    }

    // 4
    rowDayOff = (dataRow = {}) => {
        let { classes } = this.props
        let remainTotal = _.get(dataRow, "remainTotal", 0) // ố ngày nghỉ có lương
        let remainByWorkingOnDayOff = _.get(dataRow, "remainByWorkingOnDayOff", 0) // Số giờ nghỉ có lương
        let subtractHour = _.get(dataRow, 'subtractHour', '')
        let hourDefault = ''
        // if(remainByWorkingOnDayOff >= 8){
        //     hourDefault = 8
        // } else if( remainByWorkingOnDayOff >= 4){
        //     hourDefault = 4
        // } 
        if (this.state.timeWorkOffApprove >= 8) { // Kiểm tra set default cho giờ nghỉ bù
            hourDefault = 8
        } else if (this.state.timeWorkOffApprove >= 4) {
            hourDefault = 4
        }

        let hourDefaultDay = ''
        if(remainTotal >= 1) {
            hourDefaultDay = 8
        } else if(remainTotal >= 0.5) {
            hourDefaultDay = 4
        }
        let { radioValue } = this.state
        remainByWorkingOnDayOff = remainByWorkingOnDayOff === null ? 0 : remainByWorkingOnDayOff
        remainTotal = remainTotal === null ? 0 : remainTotal
        return (
            <Form onSubmit={(values) => this.onSubmit(values, dataRow)}>
                <Grid container spacing={32}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={I18n.t('Table.header.user.code')}
                            name="code"
                            value={dataRow.code}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <TextField
                            fullWidth
                            label={I18n.t('Table.header.user.name')}
                            name="name"
                            value={dataRow.name}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateTimeField
                            fullWidth
                            name="date"
                            clearable={false}
                            validate={this.validate.date}
                            autoOk={true}
                            showTime={false}
                            showDate={true}
                            label={I18n.t('DayOff.chooseDayOff')}
                            onChange={(value) => this.onChangeDateRequest(dataRow, value)}
                            value={this.state.dateRequest}
                        />
                    </Grid>
                    {
                        <Grid item xs={6}>
                            <SelectField
                                fullWidth
                                name="subtractHour"
                                label={I18n.t('DayOff.chooseHourOff')}
                                value={subtractHour || hourDefault || hourDefaultDay}
                            >
                                { (parseInt(this.state.radioValue) === 5 && this.state.timeWorkOffApprove >= 4) || (parseInt(this.state.radioValue) === 3 && remainTotal >= 0.5) ? <Option value={4}>{I18n.t('Common.4h')}</Option> : ''}
                                { (parseInt(this.state.radioValue) === 5 && this.state.timeWorkOffApprove >= 8) || (parseInt(this.state.radioValue) === 3 && remainTotal >= 1) ? <Option value={8}>{I18n.t('Common.8h')}</Option> : ''}
                            </SelectField>
                        </Grid>
                    }
                    {
                        parseInt(this.state.radioValue) === 5
                        ?
                            <Grid item xs={12} lg={12}>
                                <DateTimeField
                                    format='YYYY/MM/DD'
                                    fullWidth
                                    selected={this.state.selected}
                                    shouldDisableDate={this.disableWeekends}
                                    disabled={ this.state.existRemainTotal.length <= 0 || this.state.dateRequest == null }
                                    minDate={"-1d"}
                                    maxDate={"-1d"}
                                    onChange={(value) => this.onChangeDateCompensated(dataRow, value)}
                                    showTime={false}
                                    name="dayWorkOnDayOff"
                                    margin="normal"
                                    label={I18n.t("Input.request.onUsedCompensated")}
                                />
                            </Grid>
                        : ""
                    }
                    <Grid item xs={12}>
                        <RadioGroupField
                            // value={radioValue} // set gia trị mac đinh cho radio
                            fullWidth
                            name="type"
                            onChange={value => this.onChangeRadio(value)}
                            label={I18n.t('DayOff.type.label')}
                        >
                            <Radio
                                disabled={remainTotal < 0.5 ? true : false}
                                label={I18n.t('DayOff.type.hasSalary') + ` (` + I18n.t('DayOff.remain') + ` ${remainTotal} ` + I18n.t('DayOff.day') + `)`}
                                value="3"
                            />
                            <Radio
                                disabled={remainByWorkingOnDayOff < 4 ? true : false}
                                label={I18n.t('DayOff.type.compensatoryLeave') + ` (` + I18n.t('DayOff.remain') + ` ${remainByWorkingOnDayOff} ` + I18n.t('DayOff.hour') + `)`}
                                value="5"
                            />
                        </RadioGroupField>
                    </Grid>
                </Grid>
                <div>
                    <Button onClick={() => this.formatValueRadio()} size="small" color="primary">
                        {I18n.t("Button.cancel")}
                    </Button>
                    <Button 
                        disabled={ (radioValue ? false : true) || (parseInt(this.state.radioValue) === 5 && hourDefault == '') }
                        className={classes.floatRight} 
                        size="small"
                        color="primary" 
                        type="submit"
                     >
                        {I18n.t("Button.create")}
                    </Button>
                </div>
            </Form>
        )
    }

    onChangeArea(value) {
        let element = {
            columnName: "Area.name",
            value: value.label,
            operation: "contains",
            dataType: "text",
        }
        if (filters.length == 0) {
            filters.push(element)
        } else if (filters.length == 1) {
            if (filters[0].dataType == 'text') {
                filters[0] = element
            }
            if (filters[0].dataType == 'date') {
                filters.push(element)
            }
        } else if (filters.length == 2) {
            if (filters[0].dataType == 'text') {
                filters[0] = element
            }
            if (filters[1].dataType == 'text') {
                filters[1] = element
            }
        }
        this.props.filterData(filters)
    }

    onChangeDate(value, name) {
        if (name == 'startDate') {
            this.setState({ startDate: value })
            let element = {
                columnName: "date",
                operation: "daterange",
                dataType: "date",
                value: {
                    startDate: moment(value).startOf('day'),
                    endDate: moment(this.state.endDate).endOf('day'),
                    key: "dateRange",
                },
            }
            if (filters.length == 0) {
                filters.push(element)
            } else if (filters.length == 1) {
                if (filters[0].dataType == 'date') {
                    filters[0] = element
                }
                if (filters[0].dataType == 'text') {
                    filters.push(element)
                }
            } else if (filters.length == 2) {
                if (filters[0].dataType == 'date') {
                    filters[0] = element
                }
                if (filters[1].dataType == 'date') {
                    filters[1] = element
                }
            }
            if (this.state.endDate) {
                this.props.filterData(filters)
            }
        }
        if (name == 'endDate') {
            this.setState({ endDate: value })
            let element = {
                columnName: "date",
                operation: "daterange",
                dataType: "date",
                value: {
                    startDate: moment(this.state.startDate).startOf('day'),
                    endDate: moment(value).endOf('day'),
                    key: "dateRange",
                },
            }
            if (filters.length == 0) {
                filters.push(element)
            } else if (filters.length == 1) {
                if (filters[0].dataType == 'date') {
                    filters[0] = element
                }
                if (filters[0].dataType == 'text') {
                    filters.push(element)
                }
            } else if (filters.length == 2) {
                if (filters[0].dataType == 'date') {
                    filters[0] = element
                }
                if (filters[1].dataType == 'date') {
                    filters[1] = element
                }
            }
            if (this.state.startDate) {
                this.props.filterData(filters)
            }
        }
    }

    render() {
        const { data, classes } = this.props
        return (
            <PaperFade showLoading={true}  id ='542'>
                <GridTable
                    estimatedRowHeight={70}
                    pageHiding={true}
                    id="DayOffIndex22"
                    className={classes.gridTable}
                    onFetchData={this.props.onFetchData}
                    onRefTable={this.props.onRefTable}
                    columns={this.table.columns}
                    rows={data}
                    totalCount={data.total}
                    pageSize={data.pageSize}
                    defaultSort={this.table.defaultSort}
                    showCheckboxColumn={false}
                    height="auto"
                    tableActions={this.renderToolbarActions}
                    tableColumnExtensions={this.table.tableColumnExtensions}
                    defaultColumnWidths={this.table.columnWidths}
                />
                {this.renderAddDate()}
                {this.onDeleteDay()}
            </PaperFade>  
        )
    }
}

Index.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(withRouter(connect()(Index)));

import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import CssBaseline from '@material-ui/core/CssBaseline'
import { withStyles } from '@material-ui/core/styles'
import routes from 'config/routes.jsx'
import { Switch, Route, withRouter } from 'react-router-dom'
import Loading from 'containers/Loading/Loading'
import LoadingComponent from 'components/Progress/Loading'
import Notistack from 'components/Snackbars/Notistack'
import http from 'helpers/http'
import permission from 'helpers/permission'
import AccessDenied from 'components/Pages/AccessDenied'
import NavDrawer from './components/NavDrawer'
import HeaderAppBar from './components/AppBar'

const styles = theme => ({
    root: {
        display: 'flex',
    },
    grow: {
        flexGrow: 1,
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: theme.drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        marginLeft: theme.drawerWidth,
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${theme.drawerWidth}px)`,
        },
    },
    navIconButton: {
        top: '10px',
        left: '10px'
    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    menuLogout: {
        marginLeft: -12,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: theme.drawerWidth,
    },
    paperAnchorLeft: {
        borderRight: "none",
        boxShadow: "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    content: {
        flexGrow: 1,
        padding: `0px`,
        width: `calc(100% - ${theme.drawerWidth}px)`,
        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px 0px`,
        },
    },
    Typography: {
        paddingRight: `${theme.spacing.unit * 3}px`,
        marginLeft: `${theme.spacing.unit * 2}px`
    },
    iconButton: {
        marginRight: '10px'
    }
})

class Main extends React.Component {
    constructor(props) {
        super(props)
        http.initOnce(props)

        this.state = {
            mobileOpen: false,
            open: true,
        }
    }

    onLogout = () => {
        let user = JSON.parse(localStorage.getItem('user'))
        let redirectAsset = JSON.parse(localStorage.getItem('redirectAsset'))
        if(!["", undefined].includes(redirectAsset) && redirectAsset == true) {
            let ASSET_MANAGEMENT_URL = process.env.REACT_APP_ASSET_MANAGEMENT_PRODUCTION_URL

            if (process.env.NODE_ENV === `development` || ["localhost", "time-management.javis.vn"].includes(window.location.hostname)) {
                ASSET_MANAGEMENT_URL = process.env.REACT_APP_ASSET_MANAGEMENT_STAGING_URL
            }
            let hostRedirect = window.location.protocol + "//" + window.location.host
            let ASSET_ACCESS_TOKEN = process.env.REACT_APP_ASSET_ACCCESS_TOKEN
            let user_code = !["", undefined, null].includes(user) && user.code !== undefined ? user.code : ""
            
            let redirectToAsset = `time_token=${ASSET_ACCESS_TOKEN}&time_user_code=${user_code}&time_action=logout&time_redirect_url=${hostRedirect}`
            localStorage.removeItem("redirectAsset");
            localStorage.clear();
            
            window.location.href = `${ASSET_MANAGEMENT_URL}?${redirectToAsset}`; 
        } else {
            localStorage.clear();
            this.props.history.push('/login')
        }
    }

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    }

    renderRoutes(routes) {
        let result = []
        let index = 0
        const permissions = localStorage.user ? JSON.parse(localStorage.user).permissions : [];
        //phân quyền
        for (let route of routes) {
            if (Array.isArray(route.permissions) && Array.isArray(permissions)) {
                let intersect = permissions.filter(permit => route.permissions.includes(permit))
                if (intersect.length) {
                    result.push(<Route
                        key={index++}
                        path={route.path}
                        exact={route.exact || route.children ? true : false}
                        name={route.name}
                        component={() => this.renderComponent(route)}
                    />)
                }
            } else {
                result.push(<Route
                    key={index++}
                    path={route.path}
                    exact={route.exact || route.children ? true : false}
                    name={route.name}
                    component={() => this.renderComponent(route)}
                />)
            }
        }
        return result
    }
    
    renderLoading() {
        return (
            <LoadingComponent show={true} />
        )
    }

    toggleDrawer = () => {
        this.setState(state => ({ open: !state.open }));
    }

    renderComponent(route) {
        const { classes } = this.props;
        const title = typeof route.title === "function" ? route.title() : ""
        const Component = permission.hasPermission(route.role) ? route.component : AccessDenied
        const user = JSON.parse(localStorage.getItem('user'))
        document.title = title

        if (route.raw) {
            return <Suspense fallback={this.renderLoading()}>
                    <Component route={route} />
                </Suspense>
        }

        return <div className={classes.root}>
            <CssBaseline />
            <HeaderAppBar
                classes={classes}
                user={user}
                route={route}
                title={title}
                onLogout={this.onLogout}
            />
            <NavDrawer classes={classes} route={route} />
            <main className={classes.content}>
                <div className={classes.toolbar} />

                <Suspense fallback={this.renderLoading()}>
                    <Component route={route} />
                </Suspense>
            </main>
        </div>
    }
    render() {
        return (
            <React.Fragment>
                <Switch>
                    {this.renderRoutes(routes)}
                </Switch>
                <Loading />
                <Notistack />
            </React.Fragment>
        )
    }
}

Main.propTypes = {
    classes: PropTypes.object.isRequired,
    container: PropTypes.object,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(Main));

import React from 'react';
import View from 'views/Labor/Index'
import LaborAction from '../../actions/LaborAction';
import BaseContainer, { selector } from 'containers/BaseContainer';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n';
import moment from 'moment'
import common from 'common'

/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
class Index extends BaseContainer {
  constructor(props) {
    super(props)
    this.state = {
      _60Time: true,
      dateFilter: [],
    }
    this.refTable = null
    this.onFetchData = this.onFetchData.bind(this)
    this.onRefTable = this.onRefTable.bind(this)
    this.is60Time = this.is60Time.bind(this)
    const laborsMonth = JSON.parse(localStorage.getItem("filter.laborsMonth")) || '';
    const month = (laborsMonth && laborsMonth !=='1970.01') ? moment(laborsMonth, 'YYYY.MM') : moment()
    this.query = {}
    this.startDate = month.startOf('month').format('YYYY-MM-DD')
    this.endDate = month.endOf('month').format('YYYY-MM-DD')
  }

  componentDidMount(){
    localStorage.setItem('userCalendar', JSON.parse(localStorage.user)._id)
    localStorage.setItem('dateCalendar', new Date())  
  }

  componentWillUnmount() {
    if(this.startDate !== '1970-01-01'){
      localStorage.setItem("filter.laborsMonth", moment(this.startDate).format('YYYY.MM'));
    }
  }

  componentDidUpdate(prevProps) {
    //example for check props change
    /* if(prevProps.x !== this.props.x){
      //props x changed
    } */
  }

  is60Time(bool) {
    this.setState({
      _60Time: bool
    })
  }

  onFetchData(state) {
    state.filters.map(item => {
      if(item.columnName === 'code') {
        return item.value = common.codeFormater(item.value)
      }
    })

    this.query = state
    this.props.dispatch(LaborAction.fetchAll({
      ...state,
      startDate: this.startDate,
      endDate: this.endDate
    })).then(data => {
      if (data.error) {
        this.notify(`Response: [${data.error.status}] ${data.error.message}`, 'error')
      }
    })
  }

  onSubmit = ({ startDate, endDate }) => {
    this.startDate = startDate
    this.endDate = endDate
    this.props.dispatch(LaborAction.fetchAll({
      ...this.query,
      startDate,
      endDate
    }))
  }

  onRefTable(ref) {
    this.refTable = ref
  }

  render() {
    return (<View
      onFetchData={this.onFetchData}
      onRefTable={this.onRefTable}
      data={this.props.data}
      is60Time={this.is60Time}
      _60Time={this.state._60Time}
      onChangeDate={this.onChangeDate}
      onSubmit={this.onSubmit}
      month={moment(this.startDate).format('YYYY-MM')}
    />);
  }
}


const mapStateToProps = state => {
  return {
    data: selector(state, "labor.list", {}),
  }
}

export default withRouter(connect(mapStateToProps)(Index))
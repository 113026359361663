import React from "react";
import PropTypes from "prop-types";
import BaseView from "views/BaseView";
import _ from "lodash";
import moment from "moment";
import DocService from "../../helpers/DocService";
import "./style.css";
moment.locale("ja");

class Index extends BaseView {
  constructor(props) {
    super(props);
    this.bodyRef = React.createRef();
    this.createPdf = this.createPdf.bind(this);
    if (props.onFetchData) this.props.onFetchData();
  }

  createPdf() {
    const user = this.props.data.user;
    DocService.createPdf(this.bodyRef.current, `${user.name}-${user.code}`);
  }

  componentDidMount() {
    if (this.props.autoDownload) {
      this.createPdf();
    }
  }

  render() {
    let { data = {}, onFetchData, printMode, exportType } = this.props;
    let kLass = exportType || "print";
    let rows = data.data || [];
    let construction = _.get(data, "userConstructions", {});
    let constructionList = construction.list || [];
    let queryDate = _.get(data, "queryDate", null);
    let user = _.get(data, "user", {});
    if (rows.length <= 0) return null;

    return (
      <div className={onFetchData ? "" : printMode ? "" : "pdf-container"}>
        <div
          className={`absolute top-0 left-0 right-0 bottom-0 flex flex-col p-[30px] ${kLass}`}
          ref={this.bodyRef}
        >
          <div className="w-full flex justify-between gap-x-[16px]">
            <div className="w-1/3">
              <div
                className="foo report-title"
                style={{ fontSize: "24px", fontWeight: "bold" }}
              >
                <span>作業月報・運行日誌一覧表</span>
              </div>
              <div className="foo report-date" style={{ marginBottom: "1rem" }}>
                <span>
                  期間:{" "}
                  {queryDate
                    ? moment(new Date(queryDate)).format("YYYY年MM月")
                    : ""}
                </span>
              </div>
              <div className="foo report-worker-name">
                <span>
                  <span className="seal">氏名</span>
                  {user.name}
                </span>
                <span>
                  <span className="seal">社員コード</span>
                  {user.code}
                </span>
                <span>
                  <span className="seal">ユーザーID</span>
                  {user.username}
                </span>
              </div>
              <div className="foo report-car-number">
                <span>車番: {user.car_number}</span>
              </div>
            </div>
            <div className="w-1/3">
              <table
                className={`${kLass} table2`}
                aria-label="table"
                style={{ width: "100%" }}
              >
                <tbody>
                  <tr>
                    <td>承認者2</td>
                    <td>承認者1</td>
                    <td>本人</td>
                  </tr>
                  <tr>
                    <td style={{ width: "33.333333%", textAlign: "center" }}>
                      <div className="report-stamp">
                        {data.request2Approved && (
                          <img src="/images/stamp.png" />
                        )}
                      </div>
                    </td>
                    <td style={{ width: "33.333333%", textAlign: "center" }}>
                      <div className="report-stamp">
                        {data.request1Approved && (
                          <img src="/images/stamp.png" />
                        )}
                      </div>
                    </td>
                    <td style={{ width: "33.333333%", textAlign: "center" }}>
                      <div className="report-stamp"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-full flex justify-between gap-x-[16px] mt-[20px]">
            <div className="w-2/3">
              <table className={`${kLass} table2`} aria-label="table">
                <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>工事</td>
                    <td>工事時間</td>
                    <td>勤務時間</td>
                    <td>労働時間</td>
                    <td>種別</td>
                    <td>通勤・業務</td>
                    <td>私用</td>
                    <td>給油量</td>
                    <td>残業</td>
                    <td>深夜</td>
                    <td>申請書</td>
                    <td>メモ</td>
                  </tr>
                  {rows.map((row, index) => {
                    let constructionFontSize =
                      row.constructions && row.constructions.length > 1
                        ? 11 - row.constructions.length
                        : 13;
                    let typeWorkRequestFontSize =
                      row.typeRequestWorkingDay &&
                      row.typeRequestWorkingDay.length > 1
                        ? 11 - row.typeRequestWorkingDay.length
                        : 13;
                    let typeWorkAprroveFontSize =
                      row.typeOfWorkingDay && row.typeOfWorkingDay.length > 1
                        ? 11 - row.typeOfWorkingDay.length
                        : 13;

                    return (
                      <tr key={index}>
                        <td>{row.dateText}</td>
                        <td>
                          {row.totalRow
                            ? "合計"
                            : moment(row.date).format("dd")}
                        </td>
                        <td
                          style={{
                            textAlign: "left",
                            fontSize: `${constructionFontSize}px`,
                            lineHeight: `${constructionFontSize + 3}px`,
                            marginTop: "-3px",
                            marginBottom: "-3px",
                          }}
                        >
                          {row.constructions &&
                            row.constructions.map((con) =>
                              // <div key={con.code}>{`${_.get(con, 'code', '')} ${_.get(con, 'name', '')}`}</div>
                              {
                                let code = _.get(con, "code", "");
                                return (
                                  <div key={con.code}>{`${
                                    code && code !== ""
                                      ? code.replace(/-/g, "")
                                      : code
                                  } ${_.get(con, "name", "")}`}</div>
                                );
                              }
                            )}
                        </td>
                        <td
                          style={{
                            fontSize: `${constructionFontSize}px`,
                            lineHeight: `${constructionFontSize + 3}px`,
                            marginTop: "-3px",
                            marginBottom: "-3px",
                          }}
                        >
                          {row.totalRow
                            ? row.totalWorkingConstructions
                            : row.constructions &&
                              row.constructions.map((con) => (
                                <div key={con.code}>{`${con.workTime}`}</div>
                              ))}
                        </td>
                        {/* <td>{row._startTime && row._endTime ? `${moment(new Date(row._startTime)).format('HH:mm')} - ${moment(new Date(row._endTime)).format('HH:mm')}` : ''}</td> */}
                        <td>{row.workHourRange}</td>
                        <td>
                          {row.totalRow ? row.totalWorkHour : row.work_hour}
                        </td>
                        <td
                          style={{
                            fontSize: `${typeWorkAprroveFontSize}px`,
                            lineHeight: `${typeWorkAprroveFontSize + 3}px`,
                            marginTop: "-3px",
                            marginBottom: "-3px",
                          }}
                        >
                          {row.typeOfWorkingDay &&
                            row.typeOfWorkingDay.map((item, index) => (
                              <div key={index}>{`${item}`}</div>
                            ))}
                        </td>
                        <td>
                          {row.driving_length_on_working &&
                          ![undefined, null].includes(
                            row.driving_length_on_working
                          )
                            ? row.driving_length_on_working.toFixed(2)
                            : row.driving_length_on_working}
                        </td>
                        <td>
                          {row.driving_length_on_private &&
                          ![undefined, null].includes(
                            row.driving_length_on_private
                          )
                            ? row.driving_length_on_private.toFixed(2)
                            : row.driving_length_on_private}
                        </td>
                        <td>
                          {row.fuel_supply_amount &&
                          ![undefined, null].includes(row.fuel_supply_amount)
                            ? row.fuel_supply_amount.toFixed(2)
                            : row.fuel_supply_amount}
                        </td>
                        <td>
                          {row.totalRow
                            ? row.total_ot
                            : row.total_ot
                            ? row.total_ot
                            : row[row.dateType] && row[row.dateType].normalOT}
                        </td>
                        <td>
                          {row.totalRow
                            ? 
                              row.total_work_night_hour
                            :
                            row.total_work_night_hour
                            ? row.total_work_night_hour
                            : row[row.dateType] &&
                              row[row.dateType].workNightHour}
                        </td>
                        <td
                          style={{
                            fontSize: `${typeWorkRequestFontSize}px`,
                            lineHeight: `${typeWorkRequestFontSize + 3}px`,
                            marginTop: "-3px",
                            marginBottom: "-3px",
                          }}
                        >
                          {row.typeRequestWorkingDay &&
                            row.typeRequestWorkingDay.map((item, index) => (
                              <div key={index}>{`${item}`}</div>
                            ))}
                        </td>
                        <td>{row.memo}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="w-1/3 flex flex-col gap-y-[16px]">
              <table className={`${kLass} table2`} aria-label="table">
                <tbody>
                  <tr>
                    <td>
                      <div className="foo" style={{ textAlign: "left" }}>
                        出勤日数
                      </div>
                    </td>
                    <td>{data.total_working_day}</td>
                  </tr>
                  <tr>
                    <td className="foo" style={{ textAlign: "left" }}>
                      休日出勤日数
                    </td>
                    <td>
                      {data.requests &&
                        Object.keys(data.requests[0].workOnDayOffReq).length}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>先月最終メーター</td>
                    <td>
                      {data.drivingLog && data.drivingLog.prevMonth.length > 0
                        ? Intl.NumberFormat("ja-JP", {
                            notation: "standard",
                          }).format(data.drivingLog.prevMonth.length)
                        : 0}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>今月最終メーター</td>
                    <td>
                      {data.drivingLog &&
                      data.drivingLog.currentMonth.length > 0
                        ? Intl.NumberFormat("ja-JP", {
                            notation: "standard",
                          }).format(data.drivingLog.currentMonth.length)
                        : 0}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>期間走行距離</td>
                    <td>{data.period_mileage}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>通勤＋業務距離</td>
                    <td>{data.driving_length_on_working}</td>
                  </tr>
                </tbody>
              </table>

              <table className={`${kLass} table2`} aria-label="table">
                <tbody>
                  <tr>
                    <td style={{ textAlign: "left" }}>工事</td>
                    <td>時間</td>
                  </tr>
                  {[...Array(rows.length - 8)].map((x, i) => {
                    let con = constructionList[i] || { code: "", name: "" };

                    return (
                      <tr key={con.constructionId}>
                        <td
                          style={{
                            textAlign: "left",
                            maxHeight: "39px",
                            display: "flex",
                            overflow: "hidden",
                            alignItems: "center",
                          }}
                        >
                          {`${con.code.replace(/-/g, "")} ${con.name}`}
                        </td>
                        <td>{con.workTime}</td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td style={{ textAlign: "left" }}>合計</td>
                    <td>{construction.total}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Index;

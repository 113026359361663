import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import RadioGroupField, { Radio } from 'components/Forms/RadioGroupField';
import { TextField, Validation, Form } from 'components/Forms'
import { I18n } from 'react-redux-i18n';
class DialogEvent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            data: {
                title: "",
                type: "1"
            }
        };
        this.onCancel = this.onCancel.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.validate = {
            title: [
                Validation.required(I18n.t("Form.required")),
                Validation.maxLength(255, I18n.t("Form.maxLength"))
            ]
        }
    }
    show(data = null) {
        this.setState({
            open: true,
            data: {
                ...this.state.data,
                ...data
            }
        })
    }
    hide() {
        this.setState({
            open: false
        })
    }
    onCancel() {
        const { onCancel } = this.props
        this.hide()
        if (typeof onCancel === "function") {
            onCancel(this.state.data)
        }
    }
    onSubmit = () => {
        const { onSubmit } = this.props
 
        this.hide()
        if (typeof onSubmit === "function") {
            onSubmit({
                ...this.state.data
            })
        }
    }
    onDelete = () => {
        const { onDelete } = this.props
        this.hide()
        if (typeof onDelete === "function") {
            onDelete({
                ...this.state.data
            })
        }
    }
    handleValueChange = (value, name) => {
        this.setState({
            data: { ...this.state.data, [name]: value }
        })
    }

    render() {
        return (
            <Dialog
                open={this.state.open}
                onClose={this.onCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{I18n.t('Label.updateHoliday')}</DialogTitle>
                <Form onSubmit={this.onSubmit}>
                    <DialogContent>
                        <TextField
                            fullWidth
                            name="title"
                            defaultValue={this.state.data.title}
                            onChange={value => this.handleValueChange(value, "title")}
                            label={I18n.t('Input.holidayName')}
                            validate={this.validate.title}
                        />
                        <RadioGroupField
                            fullWidth
                            name="type"
                            label={I18n.t('Input.holidayType')}
                            value={this.state.data.type || "0"}
                            onChange={value => this.handleValueChange(value, "type")}
                        >
                            <Radio
                                label={I18n.t('Input.holidayInProvision')}
                                value="0"
                            />
                            <Radio
                                label={I18n.t('Input.holidayOutProvision')}
                                value="1"
                                checked={true}
                            />
                        </RadioGroupField>
                    </DialogContent>
                    <DialogActions>
                        {this.state.data.id != null ?
                            <Button onClick={this.onDelete} style={{ marginRight: "auto", color: "#f50057" }}>
                                {I18n.t('Button.delete')}
                            </Button>
                            : ''}
                        <Button onClick={this.onCancel} color="primary">
                            {I18n.t('Button.cancel')}
                        </Button>
                        <Button
                            color="primary"
                            type='submit'
                            autoFocus
                        >
                            {I18n.t('Button.ok')}
                        </Button>
                    </DialogActions>
                </Form>

            </Dialog>
        );
    }
}

export default DialogEvent;
import React from "react";
import { I18n } from "react-redux-i18n";
import withStyles from "@material-ui/core/styles/withStyles";
import {
	Dialog,
	Card,
	DialogContent,
	Typography,
} from "@material-ui/core";
import _ from "lodash";

const styles = (theme) => ({
	setDialogRequest: {
		maxHeight: "400px",
		minHeight: "400px",
		overflowY: "scroll",
		overflowX: "hidden",
		[theme.breakpoints.down("xs")]: {
			maxHeight: "100%",
			minHeight: "auto",
			overflowY: "scroll",
			overflowX: "hidden",
			height: "100vh",
		},
	},
	paper: {
		padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 4}px`,
	},
	button: {
		marginTop: `${theme.spacing.unit * 2}px`,
	},
	root: {
		flexGrow: 1,
	},
});

class DialogEvent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			data: {},
			startDate: null,
		};
		this.onCancel = this.onCancel.bind(this);
	}

	show = (data = null) => {
		this.setState({
			open: true,
			data: { ...this.state.data, ...data },
			startDate: data.start,
		});
	};

	hide() {
		this.setState({ open: false });
	}

	onCancel() {
		const { onCancel } = this.props;
		this.hide();
		if (typeof onCancel === "function") {
			onCancel(this.state.data);
		}
		localStorage.removeItem("event");
	}

	setBackCalendar() {
		localStorage.setItem("back", "calendars");
	}

	render() {
		let { data } = this.state;
		let titleHoliday = _.get(data, "titleHoliday", "");
		let typeDayOff = parseInt(_.get(data, "typeDayOff", ""));

		return (
			<Card>
				<Dialog
					fullWidth={true}
					onClose={this.onCancel}
					open={this.state.open}
					maxWidth="sm"
					aria-labelledby="draggable-dialog-title"
				>
					<DialogContent>
						<Typography variant="h6">
							{typeDayOff === 1 ? (
								<p>
									{I18n.t("Input.holidayOutProvision")}: {titleHoliday}
								</p>
							) : (
								""
							)}
							{typeDayOff === 0 ? (
								<p>
									{I18n.t("Input.holidayInProvision")}: {titleHoliday}
								</p>
							) : (
								""
							)}
						</Typography>
					</DialogContent>
				</Dialog>
			</Card>
		);
	}
}

export default withStyles(styles)(DialogEvent);

import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Form, TextField, Validation } from 'components/Forms'
import { BaseView } from 'views/BaseView'
import { I18n } from 'react-redux-i18n'
import { Grid, Button } from '@material-ui/core'
import MonthPicker from 'components/Forms/MonthPicker'
import moment from 'moment'


const styles = theme => ({
    form: {
        padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 3}px`,
    },
    select: {
        marginBottom: "0px"
    },
    searchBtn: {
        margin: "auto"
    }
});

class FilterForm extends BaseView {
    constructor(props) {
        super(props)
        this.validate = {
            code: [Validation.required(I18n.t("Form.required"))],
            name: [Validation.required(I18n.t("Form.required"))],
        }
        const laborsMonth = JSON.parse(localStorage.getItem("filter.laborsMonth")) || '';
        const month = (laborsMonth && laborsMonth !== '1970.01') ? moment(laborsMonth, 'YYYY.MM') : moment()
        this.defaultValue = month.format('YYYY.MM')
        this.startDate = month.startOf('month').format('YYYY-MM-DD')
        this.endDate = month.endOf('month').format('YYYY-MM-DD')
    }

    storePrintData() {
        let { construction = {} } = this.props
        let month = this.getMonthFromURL()
        month = month === 'Invalid date'? moment(this.startDate).format('YYYY.MM') : month;
        construction = {
            ...construction,
            month: month
        }
        localStorage.setItem("print_labors", JSON.stringify(construction));
    }
    componentWillUnmount(){
        localStorage.removeItem("print_labors");
    }

    onChange = (month = {}) => {
        this.startDate = moment(month.startDate).format('YYYY-MM-DD');
        this.endDate = moment(month.endDate).format('YYYY-MM-DD');
        this.storePrintData()
        this.props.onSubmit({
            startDate: this.startDate,
            endDate: this.endDate
        })
    }
    getMonthFromURL () {
        const urlParams = new URLSearchParams(window.location.search);
        let month = urlParams.get('month');
        month = moment(month, 'YYYY-MM').format('YYYY.MM')
        return month
    }

    componentDidMount() {
        if (typeof this.props.onRef == "function") {
            this.props.onRef(this)
        }
    }

    render() {
        const { classes, construction = {}, detail } = this.props
        this.storePrintData()
        const month = this.getMonthFromURL()
        return (
            <Form className={classes.form}>
                <Grid container spacing={32}
                    direction="row"
                    alignItems="flex-end"
                >
                    <Grid item xs={12} md={5} lg={4}>
                        {
                            detail ?
                                <TextField
                                    fullWidth
                                    label={I18n.t("Input.month")}
                                    name="month"
                                    value={month}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    className={classes.select}
                                />
                                :
                                <MonthPicker
                                    fullWidth
                                    label={I18n.t("Input.pickMonth")}
                                    name="selectField"
                                    datestart='2019-01-01'
                                    value={this.defaultValue}
                                    dateend={moment().format('YYYY-MM-DD')}
                                    onChange={this.onChange}
                                    className={classes.select}
                                >
                                </MonthPicker>
                        }
                    </Grid>
                    {detail ?
                        <Grid item xs={12} md={7} lg={8}>
                            <Grid container spacing={8} alignItems="flex-end">
                                <Grid item xs={12} sm={4} md={3} lg={3}>
                                    <TextField
                                        fullWidth
                                        label={I18n.t("Construction.code")}
                                        name="construction_code"
                                        value={construction.code}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        className={classes.select}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={8} md={9} lg={9}>
                                    <TextField
                                        fullWidth
                                        label={I18n.t("Construction.name")}
                                        name="construction_name"
                                        value={construction.name}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        className={classes.select}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        : ""
                    }

                </Grid>
            </Form>
        )
    }
}

FilterForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FilterForm)
import React from 'react'
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Form } from 'components/Forms'
import MonthPicker from 'components/Forms/MonthPicker'
import AutoCompleteField, { Option as OptionAuto } from 'components/Forms/AutoCompleteField'
import Hidden from '@material-ui/core/Hidden';
import { BaseView } from 'views/BaseView';
import { I18n } from 'react-redux-i18n';
import { Grid, Button } from '@material-ui/core';
import moment from 'moment'
import _ from 'lodash'
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import common from 'common'

const styles = theme => ({
    form: {
        [theme.breakpoints.down('xs')]: {
            padding: `${theme.spacing.unit * 0}px ${theme.spacing.unit * 2}px`,
        },
        [theme.breakpoints.up('sm')]: {
            padding: `${theme.spacing.unit * 0}px ${theme.spacing.unit * 4}px`,
        }

    },
    fixButton: {
        margin: "auto",
        marginBottom: `${theme.spacing.unit * 1}px`
    },
    fixGrid: {
        paddingTop: "0px !important"
    },
    fab: {
        marginBottom: '8px'
    },
    fixBtnGrid: {
        [theme.breakpoints.up('lg')]: {
            paddingLeft: `${theme.spacing.unit * 3}px`,
        }
    }
})

// const required = function (value) {
//     if (!value || value.length === 0) {
//         return I18n.t("Form.required")
//     }
// } 

class Filter extends BaseView {
    constructor(props) {
        super(props)
        let login_user = JSON.parse(localStorage.user) || {}
        let userId = common.params().user_id || login_user._id
        this.state = {
            name: "",
            code: "",
            login_user: login_user,
            _id: userId,
            dateRange: {
                startDate: moment().startOf('month').format('YYYY-MM-DD'),
                endDate: moment().endOf('month').format('YYYY-MM-DD')
            },
            _60format: true,
            _round: false
        }
    }
    //hàm gọi khi thay đổi tháng
    onChange = (data) => {
        let _startDate = _.get(data, 'startDate', '')
        let _endDate = _.get(data, 'endDate', '')
        let startDate = moment(_startDate).format('YYYY-MM-DD')
        let endDate = moment(_endDate).format('YYYY-MM-DD')
        this.setState({ dateRange: { startDate, endDate } })
    }

    //gọi khi người dùng click vào tên
    onChangeName = (option) => {
        if (!option) return false
        const { users } = this.props || []
        let { name = '', code = '', _id = '' } = this.getUserById(users, option.value)
        this.setState({ name, code, _id })
    }

    getUserById(users, userId) {
        let user = users.find(function (item) {
            return String(userId) === String(item._id)
        }) || {}   
        return user
    }
    //hàm gọi khi nhấn nút tìm kiếm
    onSubmit = () => {
        let { _id, dateRange } = this.state
        let { startDate, endDate } = dateRange || {}
        this.props.onSubmit({ _id, startDate, endDate })
    }

    render() {
        const { classes, _isRound, users } = this.props
        const { login_user, _id } = this.state
        let { code } = this.getUserById(users, _id)

        return (
            // <PaperFade className={classes.paper}>
            <Form className={classes.form}>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-end"
                    spacing={16}>
                    <Grid item xs={12} md = {8} lg={7}>
                        <Grid container spacing={16} alignItems="flex-end">
                            <Grid item xs={12} md={8}>
                                <AutoCompleteField
                                    key="1"
                                    fullWidth
                                    select
                                    label={I18n.t("Input.name")}
                                    name="userId"
                                    isMulti={false}
                                    margin="normal"
                                    onChange={this.onChangeName}
                                    defaultValue={this.state._id}
                                >
                                    {
                                        users.map(item => (
                                            <OptionAuto key={item._id} code={item.code} value={item._id} showCheckbox={false}>
                                                {item.name}
                                            </OptionAuto>
                                        ))
                                    }
                                </AutoCompleteField>
                            </Grid>
                            <Grid item xs={12} md={4}>
                            <AutoCompleteField
                                    key="1"
                                    fullWidth
                                    select
                                    label={I18n.t("Input.code")}
                                    name='code'
                                    isMulti={false}
                                    margin="normal"
                                    onChange={this.onChangeName}
                                    defaultValue={this.state._id}
                                >
                                    {
                                        users.map(item => (
                                            <OptionAuto key={item._id} code={item.code} value={item._id} showCheckbox={false}>
                                                {item.code}
                                            </OptionAuto>
                                        ))
                                    }
                                </AutoCompleteField>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md = {4} lg={3}>
                        <Grid container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-end"
                            spacing={16}>
                            <Grid item xs={9}>
                                {/* date start chính là ngày bắt đầu và date end chính là ngày kết thúc mà bạn muốn tính tháng 
                            nếu không truyề vào mặc định tháng cuối cùng là tháng hiện tại, ngày bắt đầu là ngày 2019-01-01 */}
                                <MonthPicker
                                    fullWidth
                                    label={I18n.t("Input.pickMonth")}
                                    name="selectField"
                                    datestart='2019-01-01'
                                    dateend={moment().format('YYYY-MM-DD')}
                                    onChange={this.onChange}
                                    value={common.params().year_month ? common.params().year_month.split('-').join('.') : null}
                                >
                                </MonthPicker>
                            </Grid>
                            <Grid item xs={3}>
                                <Hidden smDown>
                                    <Button
                                        onClick={this.onSubmit}
                                        variant="contained"
                                        color="primary"
                                        className={classes.fixButton}
                                    >
                                        {I18n.t("Button.search")}
                                    </Button>
                                </Hidden>
                                <Hidden mdUp>
                                    <Fab color="primary"
                                        onClick={this.onSubmit}
                                        size="small" aria-label="add"
                                        className={classes.fab}>
                                        <Icon>search</Icon>
                                    </Fab>
                                </Hidden>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
        )
    }
}

Filter.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Filter);
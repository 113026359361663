import React, { lazy } from 'react';
import { Redirect } from 'react-router'
import _ from 'lodash'
import { I18n, Translate } from 'react-redux-i18n'

const Example = lazy(() => import('components/Forms/Example'))
//import UserIndex from 'containers/User/Index'
const UserIndex = lazy(() => import('containers/User/Index'))
const UserCreate = lazy(() => import('containers/User/Create'))
const UserEdit = lazy(() => import('containers/User/Edit'))
const AreaIndex = lazy(() => import('containers/Area/Index'))
const AreaCreate = lazy(() => import('containers/Area/Create'))
const AreaEdit = lazy(() => import('containers/Area/Edit'))
const RoleIndex = lazy(() => import('containers/Role/Index'))
const RoleCreate = lazy(() => import('containers/Role/Create'))
const RoleEdit = lazy(() => import('containers/Role/Edit'))
const PositionIndex = lazy(() => import('containers/Position/Index'))
const PositionCreate = lazy(() => import('containers/Position/Create'))
const PositionEdit = lazy(() => import('containers/Position/Edit'))
const SettingTime = lazy(() => import('containers/Setting/Time'))
const SettingRoot = lazy(() => import('containers/Setting/Root'))
const DayOffIndex = lazy(() => import('containers/DayOff/Index'))
const DeleteIndex = lazy(() => import('containers/Delete/Index'))
const ReportIndex = lazy(() => import('containers/Report/Index'))
const ReportCreate = lazy(() => import('containers/Report/Create'))
const ReportDetail = lazy(() => import('containers/Report/Detail'))
const ConstructionIndex = lazy(() => import('containers/Construction/Index'))
const ConstructionHourIndex = lazy(() => import('containers/ConstructionHour/Index'))
const ConstructionCreate = lazy(() => import('containers/Construction/Create'))
const ConstructionImport = lazy(() => import('containers/Construction/Import'))
const ConstructionEdit = lazy(() => import('containers/Construction/Edit'))
const LaborIndex = lazy(() => import('containers/Labor/Index'))
const LaborDetail = lazy(() => import('containers/Labor/Detail'))
const HistoryIndex = lazy(() => import('containers/History/Index'))
const HistoryDetail = lazy(() => import('containers/History/Detail'))
const HistoryEdit = lazy(() => import('containers/History/Edit'))
const Worker = lazy(() => import('containers/Worker/Worker'))
const RequestIndex = lazy(() => import('containers/Request/Index'))
const ListRequest = lazy(() => import('containers/Calendar/ListRequest'))
const RequestCreate = lazy(() => import('containers/Request/Create'))
const RequestDetail = lazy(() => import('containers/Request/Detail'))
const RequestEdit = lazy(() => import('containers/Request/Edit'))
const HourIndex = lazy(() => import('containers/Hour/Index'))
const HourDetail = lazy(() => import('containers/Hour/Detail'))
const CalendarIndex = lazy(() => import('containers/Calendar/Calendar'))
const Time = lazy(() => import('containers/Time'))
const TimekeepingIndex = lazy(() => import('containers/Timekeeping/Index'))
const TimekeepingEdit = lazy(() => import('containers/Timekeeping/Edit'))
const TimekeepingUpdate = lazy(() => import('containers/Timekeeping/Update'))
const ContentsIndex = lazy(() => import('containers/Contents/Index'))
const ContentsCreate = lazy(() => import('containers/Contents/Create'))
const ContentsEdit = lazy(() => import('containers/Contents/Edit'))
const NotFound = lazy(() => import('../layouts/NotFound'))
const Line = lazy(() => import('containers/LineAPI/Line'))
const MonthlyReport = lazy(() => import('containers/MonthlyReport/Index'))
const MonthlyReportExport = lazy(() => import('containers/MonthlyReportExport/Index'))
const MonthlyReportManagement = lazy(() => import('containers/MonthlyReportManagement/Index'))

/**
 * in_out => /workers
 * mana_construction, sort_user => /constructions
 * mana_user => /users
 * history => /histories
 * mana_timekeeping => /timekeepings
 * mana_holiday => /dayoffs
 * mana_money => /labors
 * allow_level_1, allow_level_2 => /histories
 * profit => ...
 * 
 */
function redirectLink() {
  let permissions = localStorage.user ? JSON.parse(localStorage.user).permissions : []
  if (!Array.isArray(permissions)) permissions = []
  let link = ''
  if (permissions.includes('in_out')) {
    link = '/workers'
  } else {
    for (let x of permissions) {
      switch (x) {
        case 'mana_user':
          link = '/users'
          break
        case 'mana_construction':
        case 'sort_user':
          link = '/constructions'
          break
        case 'history':
          link = '/histories'
          break
        case 'mana_timekeeping':
          link = '/timekeepings'
          break
        case 'mana_money':
          link = '/labors'
          break
        case 'mana_holiday':
          link = '/dayoffs'
          break
        case 'allow_level_1':
        case 'allow_level_2':
          link = '/requests'
          break
        case 'setting_time':
          link = 'setting';
          break;
        case 'myhour':
          link = 'myhour'
          break;
      }
      if (link) {
        break
      }
    }
  }
  return link
}

const routes = [
  {
    path: "/",
    component: () => {
      let link = redirectLink()
      return <Redirect to={link ? link : './not-found'} />
    },
    exact: true,
    name: 'dashboard'
  },
  {
    path: "/not-found",
    component: () => <NotFound />,
    exact: true,
    name: 'dashboard'
  },
  {
    path: "/line",
    component: () => <Line />,
    exact: true,
    name: 'dashboard'
  },
  {
    path: "/example",
    name: 'example',
    title: () => I18n.t("Breadcrumb.userIndex"),
    component: () => <Example />,
    exact: true,
    sidebarName: 'example'
  },
  {
    path: "/time",
    name: 'time',
    title: () => I18n.t("Breadcrumb.time"),
    component: () => <Time />,
    exact: true,
    sidebarName: 'Time'
  },
  {
    path: "/settingRoot",
    name: 'time',
    title: () => I18n.t("Breadcrumb.iiiii"),
    component: () => <SettingRoot />,
    exact: true,
  },
  //users
  {
    path: "/users",
    name: 'user',
    permissions: ['mana_user'],
    title: () => I18n.t("Breadcrumb.userIndex"),
    component: () => <UserIndex />,
    exact: true,
    sidebarName: 'user'
  },
  {
    path: "/users/create",
    name: 'users.create',
    permissions: ['mana_user'],
    title: () => I18n.t("Breadcrumb.userCreate"),
    component: () => <UserCreate />,
    exact: true,
    sidebarName: 'user'
  },
  {
    path: "/users/:id",
    name: 'user.edit',
    permissions: ['mana_user'],
    title: () => I18n.t("Breadcrumb.userEdit"),
    component: () => <UserEdit />,
    exact: true,
    sidebarName: 'user'
  },

  //Area
  {
    path: "/areas",
    name: 'area',
    permissions: ['mana_user'],
    title: () => I18n.t("Breadcrumb.areaIndex"),
    component: () => <AreaIndex />,
    exact: true,
    sidebarName: 'area'
  },
  {
    path: "/areas/create",
    name: 'area.create',
    permissions: ['mana_user'],
    title: () => I18n.t("Breadcrumb.areaCreate"),
    component: () => <AreaCreate />,
    exact: true,
    sidebarName: 'area'
  },
  {
    path: "/areas/:id",
    name: 'area.edit',
    permissions: ['mana_user'],
    title: () => I18n.t("Breadcrumb.areaEdit"),
    component: () => <AreaEdit />,
    exact: true,
    sidebarName: 'area'
  },
  //Role
  {
    path: "/roles",
    name: 'role',
    permissions: ['mana_user'],
    title: () => I18n.t("Breadcrumb.roleIndex"),
    component: () => <RoleIndex />,
    exact: true,
    sidebarName: 'role'
  },
  {
    path: "/roles/create",
    name: 'role.create',
    permissions: ['mana_user'],
    title: () => I18n.t("Breadcrumb.roleCreate"),
    component: () => <RoleCreate />,
    exact: true,
    sidebarName: 'role'
  },
  {
    path: "/roles/:id",
    name: 'role.edit',
    permissions: ['mana_user'],
    title: () => I18n.t("Breadcrumb.roleEdit"),
    component: () => <RoleEdit />,
    exact: true,
    sidebarName: 'role'
  },

  //Position
  {
    path: "/positions",
    name: 'position',
    permissions: ['mana_user'],
    title: () => I18n.t("Breadcrumb.positionIndex"),
    component: () => <PositionIndex />,
    exact: true,
    sidebarName: 'position'
  },
  {
    path: "/positions/create",
    name: 'position.create',
    permissions: ['mana_user'],
    title: () => I18n.t("Breadcrumb.positionCreate"),
    component: () => <PositionCreate />,
    exact: true,
    sidebarName: 'position'
  },
  {
    path: "/positions/:id",
    name: 'position.edit',
    permissions: ['mana_user'],
    title: () => I18n.t("Breadcrumb.positionEdit"),
    component: () => <PositionEdit />,
    exact: true,
    sidebarName: 'position'
  },
  //Setting
  {
    path: "/setting",
    name: 'setting',
    permissions: ['setting_time'],
    title: () => I18n.t("Breadcrumb.settingTime"),
    component: () => <SettingTime />,
    exact: true,
    sidebarName: 'setting'
  },
  //DayOff
  {
    path: "/dayoffs",
    name: 'dayoff',
    permissions: ['mana_holiday'],
    title: () => I18n.t("Breadcrumb.dayoffIndex"),
    component: () => <DayOffIndex />,
    exact: true,
    sidebarName: 'dayoff'
  },
  // Delete 
  {
    path: "/deletes",
    name: 'delete',
    permissions: ['mana_user'],
    title: () => I18n.t("Breadcrumb.deleteIndex"),
    component: () => <DeleteIndex />,
    exact: true,
    sidebarName: 'delete'
  },
  //Report
  {
    path: "/reports",
    name: 'report',
    permissions: ['in_out'],
    title: () => I18n.t("Breadcrumb.reportIndex"),
    component: () => <ReportIndex />,
    exact: true,
    sidebarName: 'report'
  },
  /////////////////////////////////////
  {
    path: "/reports/create",
    name: 'report.create',
    permissions: ['in_out'],
    title: () => I18n.t("Breadcrumb.reportCreate"),
    component: () => <ReportCreate />,
    exact: true,
    sidebarName: 'report'
  },
  {
    path: "/calendars/reports/create",
    name: 'report.create',
    permissions: ['in_out'],
    title: () => I18n.t("Breadcrumb.reportCreate"),
    component: () => <ReportCreate />,
    exact: true,
    sidebarName: 'calendar'
  },
  {
    path: "/workers/reports/create",
    name: 'report.create',
    permissions: ['in_out'],
    title: () => I18n.t("Breadcrumb.reportCreate"),
    component: () => <ReportCreate />,
    exact: true,
    sidebarName: 'worker'
  },
  /////////////////////////////
  {
    path: "/reports/:id",
    name: 'report.detail',
    permissions: ['in_out'],
    title: () => I18n.t("Breadcrumb.reportDetail"),
    component: () => <ReportDetail />,
    exact: true,
    sidebarName: 'report'
  },
  {
    path: "/calendars/reports/:id",
    name: 'report.detail',
    permissions: ['in_out'],
    title: () => I18n.t("Breadcrumb.reportDetail"),
    component: () => <ReportDetail />,
    exact: true,
    sidebarName: 'calendar'
  },
  {
    path: "/workers/reports/:id",
    name: 'report.detail',
    permissions: ['in_out'],
    title: () => I18n.t("Breadcrumb.reportDetail"),
    component: () => <ReportDetail />,
    exact: true,
    sidebarName: 'worker'
  },

  // Construction
  {
    path: "/constructions",
    name: 'construction',
    permissions: ['sort_user', 'mana_construction', 'profit'],
    title: () => I18n.t("Breadcrumb.constructionIndex"),
    component: () => <ConstructionIndex />,
    exact: true,
    sidebarName: 'construction'
  },
  {
    path: "/constructions-hour",
    name: 'construction_hour',
    permissions: ['construction_hour'],
    title: () => I18n.t("Breadcrumb.constructionHourIndex"),
    component: () => <ConstructionHourIndex />,
    exact: true,
    sidebarName: 'construction_hour'
  },
  {
    path: "/constructions/create",
    name: 'construction.create',
    permissions: ['mana_construction'],
    title: () => I18n.t("Breadcrumb.constructionCreate"),
    component: () => <ConstructionCreate />,
    exact: true,
    sidebarName: 'construction'
  },
  {
    path: "/constructions/import",
    name: 'construction.import',
    permissions: ['mana_construction'],
    title: () => I18n.t("Breadcrumb.constructionCreate"),
    component: () => <ConstructionImport />,
    exact: true,
    sidebarName: 'construction'
  },
  {
    path: "/constructions/:id",
    name: 'construction.edit',
    permissions: ['sort_user', 'mana_construction', 'profit'],
    title: () => I18n.t("Breadcrumb.constructionEdit"),
    component: () => <ConstructionEdit />,
    exact: true,
    sidebarName: 'construction'
  },

  //Labor
  {
    path: "/labors",
    name: 'labor',
    permissions: ['mana_money'],
    title: () => I18n.t("Breadcrumb.laborIndex"),
    component: () => <LaborIndex />,
    exact: true,
    sidebarName: 'labor'
  },
  {
    path: "/labors/:id",
    name: 'labor.detail',
    permissions: ['mana_money'],
    title: () => I18n.t("Breadcrumb.laborDetail"),
    component: () => <LaborDetail />,
    exact: true,
    sidebarName: 'labor'
  },

  //History
  {
    path: "/histories",
    name: 'history',
    permissions: ['history'],
    title: () => I18n.t("Breadcrumb.historyIndex"),
    component: () => <HistoryIndex />,
    exact: true,
    sidebarName: 'history'
  },
  {
    path: "/histories/:id/detail",
    name: 'history.detail',
    permissions: ['history'],
    title: () => I18n.t("Breadcrumb.historyDetail"),
    component: () => <HistoryDetail />,
    exact: true,
    sidebarName: 'history'
  },
  {
    path: "/histories/:id/edit",
    name: 'history.edit',
    permissions: ['history'],
    title: () => I18n.t("Breadcrumb.historyEdit"),
    component: () => <HistoryEdit />,
    exact: true,
    sidebarName: 'history'
  },

  //Worker
  {
    path: "/workers",
    name: 'worker',
    permissions: ['in_out'],
    title: () => I18n.t("Breadcrumb.worker"),
    component: () => <Worker />,
    exact: true,
    sidebarName: 'worker'
  },

  //Request
  {
    path: "/requests",
    name: 'request',
    permissions: ['allow_level_1', 'allow_level_2'],
    title: () => I18n.t("Breadcrumb.requestIndex"),
    component: () => <RequestIndex />,
    exact: true,
    sidebarName: 'request'
  },

  {
    path: "/requests/create",
    name: 'request.create',
    permissions: ['in_out'],
    title: () => I18n.t("Breadcrumb.requestCreate"),
    component: () => <RequestCreate />,
    exact: true,
    sidebarName: 'request'
  },

  {
    path: "/calendars/requests/create",
    name: 'request.create',
    permissions: ['in_out'],
    title: () => I18n.t("Breadcrumb.requestCreate"),
    component: () => <RequestCreate />,
    exact: true,
    sidebarName: 'calendar'
  },
  {
    path: "/calendars/list",
    name: 'calendar.list',
    // permissions: ['in_out'],
    title: () => '申請一覧',
    component: () => <ListRequest/>,
    exact: true,
    sidebarName: 'calendar'
  },
  {
    path: "/workers/requests/create",
    name: 'request.create',
    permissions: ['in_out'],
    title: () => I18n.t("Breadcrumb.requestCreate"),
    component: () => <RequestCreate />,
    exact: true,
    sidebarName: 'worker'
  },

  {
    path: "/requests/:id",
    name: 'request.detail',
    permissions: ['allow_level_1', 'allow_level_2', 'in_out'],
    title: () => I18n.t("Breadcrumb.requestDetail"),
    component: () => <RequestDetail />,
    exact: true,
    sidebarName: 'request'
  },
  {
    path: "/calendars/requests/:id",
    name: 'request.detail',
    permissions: ['allow_level_1', 'allow_level_2', 'in_out'],
    title: () => I18n.t("Breadcrumb.requestDetail"),
    component: () => <RequestDetail />,
    exact: true,
    sidebarName: 'calendar'
  },
  {
    path: "/workers/requests/:id",
    name: 'request.detail',
    permissions: ['allow_level_1', 'allow_level_2', 'in_out'],
    title: () => I18n.t("Breadcrumb.requestDetail"),
    component: () => <RequestDetail />,
    exact: true,
    sidebarName: 'worker'
  },
  {
    path: "/requests/:id/edit",
    name: 'request.edit',
    permissions: ['in_out'],
    title: () => I18n.t("Breadcrumb.requestEdit"),
    component: () => <RequestEdit />,
    exact: true,
    sidebarName: 'request'
  },

  {
    path: "/calendars/requests/:id/edit",
    name: 'request.edit',
    permissions: ['in_out'],
    title: () => I18n.t("Breadcrumb.requestEdit"),
    component: () => <RequestEdit />,
    exact: true,
    sidebarName: 'calendar'
  },

  {
    path: "/workers/requests/:id/edit",
    name: 'request.edit',
    permissions: ['in_out'],
    title: () => I18n.t("Breadcrumb.requestEdit"),
    component: () => <RequestEdit />,
    exact: true,
    sidebarName: 'worker'
  },


  //Hour
  {
    path: "/hours",
    name: 'hour',
    permissions: ['mana_timekeeping'],
    title: () => I18n.t("Breadcrumb.hourIndex"),
    component: () => <HourIndex />,
    exact: true,
    sidebarName: 'hour'
  },
  {
    path: "/myhour",
    name: 'myhour',
    permissions: ['myhour'],
    title: () => I18n.t("Breadcrumb.hourDetail"),
    component: () => <HourDetail />,
    exact: true,
    sidebarName: 'myhour'
  },

  //Calendar
  {
    path: "/calendars",
    name: 'calendar',
    permissions: ['in_out'],
    title: () => I18n.t("Breadcrumb.calendarIndex"),
    component: () => <CalendarIndex />,
    exact: true,
    sidebarName: 'calendar'
  },

  //Timekeeping
  {
    path: "/timekeepings",
    name: 'timekeeping',
    permissions: ['mana_timekeeping'],
    title: () => I18n.t("Breadcrumb.timekeepingIndex"),
    component: () => <TimekeepingIndex />,
    exact: true,
    sidebarName: 'timekeeping'
  },
  {
    path: "/monthly-report-management",
    name: 'monthly_report_management',
    permissions: ['monthly_report_management'],
    title: () => I18n.t("Breadcrumb.monthlyReportManagement"),
    component: () => <MonthlyReportManagement />,
    exact: true,
    sidebarName: 'monthly_report_management'
  },
  {
    path: "/monthly_reports",
    name: 'monthlyReport',
    permissions: ['mana_monthly_reports'],
    title: () => I18n.t("Breadcrumb.monthlyReport"),
    component: () => <MonthlyReport />,
    exact: true,
    sidebarName: 'monthlyReport'
  },
  {
    path: "/monthly_reports/:id/pdf",
    name: 'monthlyReportExport',
    permissions: ['in_out'],
    title: () => I18n.t("Breadcrumb.monthlyReportManagement"),
    component: () => <MonthlyReportExport />,
    exact: true,
    sidebarName: 'monthlyReportExport',
    raw: true
  },
  {
    path: "/distance_fuel/update",
    name: 'timekeeping.update',
    permissions: ['in_out'],
    title: () => I18n.t("Breadcrumb.distance_fuel"),
    component: () => <TimekeepingUpdate />,
    exact: true,
    sidebarName: 'timekeeping.Edit'
  },

  //Contents
  {
    path: "/contents",
    name: 'contents',
    permissions: ['in_out'],
    title: () => I18n.t("Breadcrumb.contentsIndex"),
    component: () => <ContentsIndex />,
    exact: true,
    sidebarName: 'report'
  },

  {
    path: "/contents/create",
    name: 'contents.create',
    permissions: ['in_out'],
    title: () => I18n.t("Breadcrumb.contentsCreate"),
    component: () => <ContentsCreate />,
    exact: true,
    sidebarName: 'report'
  },

  {
    path: "/contents/:id",
    name: 'contents.edit',
    permissions: ['in_out'],
    title: () => I18n.t("Breadcrumb.contentsEdit"),
    component: () => <ContentsEdit />,
    exact: true,
    sidebarName: 'report'
  },
  {
    path: "/",
    title: () => I18n.t("Breadcrumb.notFound"),
    component: () => {
      return <Redirect to="/not-found" />
    },
    name: 'dashboard'
  },
  //-------- workload management
  {
    path: "/wlm/worker_charges",
    title: <Translate value="Sidebar.workingLoad.worker_charges" />,
    link: "/worker_charges?page_init=init",
    name: 'wlmWorkerCharges',
  },
  {
    path: "/wlm/teams",
    title: <Translate value="Sidebar.workingLoad.team" />,
    link: "/teams",
    name: 'wlmTeam',
  },
  {
    path: "/wlm/loads",
    title: <Translate value="Sidebar.workingLoad.load" />,
    link: "/loads",
    name: 'wlmLoad',
  },
  {
    path: "/wlm/team_managements",
    title: <Translate value="Sidebar.workingLoad.team_managements" />,
    link: "/team_managements?page_init=init",
    name: 'wlmTeamManagements',
  },
  {
    path: "/wlm/constructions",
    title: <Translate value="Sidebar.workingLoad.construction" />,
    link: "/constructions",
    name: 'wlmConstruction',
  },
  {
    path: "/wlm/workloads",
    title: <Translate value="Sidebar.workingLoad.workload" />,
    link: '/workloads?page_init=init',
    name: 'wlmWorkload',
  },
  {
    path: "/wlm/estimates",
    title: <Translate value="Sidebar.workingLoad.estimate" />,
    link: '/estimates',
    name: 'wlmEstimate',
  },
  //---------- asset management
  {
    path: "/calendar_booking",
    name: 'calendar_booking',
    title: <Translate value="Sidebar.calendarBooking" />
  },
  {
    path: "/list_product",
    name: 'list_product',
    title: <Translate value="Sidebar.listProduct" />
  },
  {
    path: "/revenue",
    name: 'revenue',
    title: <Translate value="Sidebar.revenue" />
  },
  {
    path: "/printing",
    name: 'printing',
    title: <Translate value="Sidebar.printing" />
  },
  {
    path: "/damageNotDefine",
    name: 'damageNotDefine',
    title: <Translate value="Sidebar.damageNotDefine" />
  },
  /*__AUTOROUTE__*/
  /*Do not remove __AUTOROUTE__ variable.*/
]

export default routes

import React, { Component } from 'react';
import AutoCompleteField, { Option as OptionAuto } from 'components/Forms/AutoCompleteField';
import { Grid } from '@material-ui/core'
import { I18n } from 'react-redux-i18n'
class Filter extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {areas = []} = this.props
        const reportArea = localStorage.getItem("filter.reportArea") || '';
        return (
            <Grid container style={{paddingLeft: '16px'}}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <AutoCompleteField
                        key="1"
                        fullWidth
                        select
                        label={I18n.t("Input.user.area")}
                        name="areaId"
                        defaultValue = {reportArea}
                        isMulti={false}
                        isClearable={false}
                        onChange = {this.props.onFilterArea}
                    >
                        {
                            areas.map(item => (
                                <OptionAuto key={item._id} value={item.name} showCheckbox={false}>
                                    {item.name}
                                </OptionAuto>
                            ))
                        }
                    </AutoCompleteField>
                </Grid>
            </Grid>
        )
    }
}
export default Filter
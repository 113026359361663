import BaseReducer from './BaseReducer';
/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
class ConstructionReducer extends BaseReducer {
  get actionsAllow() {
    return {
      ...super.actionsAllow,
      "ConstructionHour.fetchAll": {
        path: "list"
      },
    }
  }

  get initialState() {
    return {
      ...super.initialState,
      error: {
        message: null
      }
    }
  }
}
/*
 * bắt buộc gọi hàm export()
 */
export default ConstructionReducer.export()
import Chip from '@material-ui/core/Chip'
import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { IconButton, Icon, Tooltip, Button } from '@material-ui/core'
import BaseView from 'views/BaseView'
import PaperFade from 'components/Main/PaperFade'
import { I18n } from 'react-redux-i18n'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import _ from 'lodash'
const GridTable = React.lazy(() => import('components/Table/GridTable'))

const styles = theme => ({
    gridTable: {
        height: "calc(100vh - 100px)"
    },
    button: {
        marginRight: '5px'
    }
})

class Index extends BaseView {
    constructor(props) {
        super(props)
        this.state = {
        }
        this.table = {
            columns: [
                {
                    name: 'index',
                    title: I18n.t("Table.header.user.index"),
                    type: "text",
                    filterable: false,
                    sortable: false,
                    style: {
                        textAlign: 'center',
                    }
                },
                {
                    name: 'name',
                    title: I18n.t('Table.header.role.name'),
                    style: {
                        textAlign: 'center',
                    }
                },
                {
                    name: 'Permission',
                    title: I18n.t('Table.header.role.permission'),
                    formatterComponent: (data) => {
                        let Permission = _.get(data.row, "Permission", [])
                        return Permission.map((item, index) => {
                            if (index == 3 || index == 6 || index == 9) {
                                return <span key={index}>
                                    <br />
                                    <Chip label={item.name} />
                                </span>
                            }
                            return <Chip key={index} label={item.name} />
                        })
                        // return <Menu permission={Permission} />
                    },
                    filterable: false,
                    sortable: false,
                    style: {
                        textAlign: 'center',
                    }

                },
                {
                    name: 'numberUser',
                    title: I18n.t('Table.header.role.userCount'),
                    formatterComponent: (data) => {
                        let userNumber = _.get(data.row, "userNumber", "")
                        return userNumber
                    },
                    sortable: false,
                    filterable: false,
                    style: {
                        textAlign: 'center',
                    }
                },
                {
                    name: '_id',
                    title: I18n.t('Table.header.action'),
                    sortable: false,
                    filterable: false,
                    formatterComponent: (data) => {
                        return this.customActionColumn(data)
                    },
                    style: {
                        textAlign: 'center',
                    }
                },
            ],
            defaultSort: [],
            tableColumnExtensions: [
                { columnName: 'name', wordWrapEnabled:true },
                { columnName: '_id', align: 'center'},
                {columnName: 'numberUser', align: 'center', wordWrapEnabled:true},
                {columnName: 'Permission'},

                // {columnName: 'name', align: 'center'}

            ],
             //nếu tổng nhỏ hơn 990 thì tính theo %, ngược lại tính theo px
             columnWidths: [
                {
                    name: 'index',
                    width: 70
                },
                {
                    name: 'name',
                    width: 150
                },
                {
                    name: 'Permission',
                    width: 450
                },
                {
                    name: 'numberUser',
                    width: 150
                },
                {
                    name: '_id',
                    width: 140
                },
            
            ]
           
        }
        this.ConfirmDialog = null
        this.renderToolbarActions = this.renderToolbarActions.bind(this)
        this.renderSelectedActions = this.renderSelectedActions.bind(this)
    }

    customUserColumn(data) {
        data = this.getData(data, "value", [])
        return data.length
    }

    customActionColumn(data) {
        let _id = this.getData(data, "value", '')
        const { classes } = this.props;
        return (
            <div>
                <Button className={classes.button} variant='contained' color="primary" onClick={() => this.goto(`/roles/${_id}`)}>
                    {I18n.t("Button.edit")}
                </Button>
                <Button className={classes.button} variant='contained' color="primary" key="delete" onClick={() => this.ConfirmDialog.show([_id])}>
                    {I18n.t('Button.delete')}
                </Button>
            </div>
        )
    }

    renderToolbarActions() {
        return [
            <Tooltip title={I18n.t("toolTip.new")} key="create">
                <Button variant='contained' color='primary' onClick={() => this.goto("/roles/create")}>
                    {I18n.t("Button.create")}
                </Button>
            </Tooltip>,
        ]
    }

    renderSelectedActions(selectedIds) {
        return [
            <Tooltip title={I18n.t("toolTip.delete")} key="create">
                <IconButton key="delete" onClick={() => this.ConfirmDialog.show(selectedIds)}>
                    <Icon>delete</Icon>
                </IconButton>
            </Tooltip>
        ]
    }

    renderDialogConfirmDelete() {
        return (
            <ConfirmDialog
                ref={(ref) => this.ConfirmDialog = ref}
                title={I18n.t('Message.deleteDialogTitle')}
                content={I18n.t('Message.deleteDialogContent')}
                onSubmit={this.props.onDeleteData}
            />
        )
    }
   

    render() {
        const { data, classes } = this.props
        return (
            <PaperFade showLoading={true}>
                <GridTable
                    id="RoleIndex"
                    estimatedRowHeight = {100}
                    className={classes.gridTable}
                    onFetchData={this.props.onFetchData}
                    onRefTable={this.props.onRefTable}
                    columns={this.table.columns}
                    rows={data.data}
                    totalCount={data.total}
                    pageSize={data.pageSize}
                    defaultSort={this.table.defaultSort}
                    showCheckboxColumn={true}
                    height="auto"
                    selectedActions={this.renderSelectedActions}
                    tableActions={this.renderToolbarActions}
                    tableColumnExtensions={this.table.tableColumnExtensions}
                    defaultColumnWidths={this.table.columnWidths}
                />
                {this.renderDialogConfirmDelete()}
            </PaperFade>
        )
    }
}

Index.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(withRouter(Index))
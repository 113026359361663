import React from 'react';
import View from 'views/Construction/Index'
import ConstructionAction from '../../actions/ConstructionAction';
import BaseContainer, { selector } from 'containers/BaseContainer';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n';
/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
class Index extends BaseContainer {
    constructor(props) {
        super(props)
        this.refTable = null
        this.onFetchData = this.onFetchData.bind(this)
        this.onRefTable = this.onRefTable.bind(this)
        this.onDeleteData = this.onDeleteData.bind(this)
    }

    componentDidMount() {
        this.props.dispatch(ConstructionAction.fetchAll({ pageSize: -1 }))
        localStorage.setItem('userCalendar', JSON.parse(localStorage.user)._id)
        localStorage.setItem('dateCalendar', new Date())
    }

    onFetchData(state) {
        this.props.dispatch(ConstructionAction.fetchAll(state))
    }

    onFetchExcelData = () => {
        this.props.dispatch(ConstructionAction.excel())
    }

    onRefTable(ref) {
        this.refTable = ref
    }

    onDeleteData(selectedIds) {
        this.props.dispatch(ConstructionAction.delete({
            ids: selectedIds
        }))
            .then(result => {
                if (!result.error) {
                    //success
                    this.notify(I18n.t('Message.deleteDataSuccess'))
                    if (this.refTable) {
                        this.refTable.onSelectionChange([])
                        this.refTable.onFetchData({currentPage: 0})
                    }
                } else {
                    //error
                    // this.notify(`Response: [${result.error.status}] ${result.error.message}`, 'error')
                    let err = result.error
                    switch (err.status) {
                        case 403: {
                            if (err.message === "No_Del_Permission") {
                                this.notify(I18n.t("Backend.Construction.No_Del_Permission"), "error")
                            }
                            break
                        }
                        case 422: {
                            if (err.message === "Id_Required") {
                                this.notify(I18n.t("Backend.DbObject.Id_Required"), "error")
                            }
                            break
                        }
                        case 404: {
                            if (err.message === "No_Object") {
                                this.notify(I18n.t("Backend.DbObject.No_Object"), "error")
                            }
                            break
                        }
                        case 405: {
                            if (err.message === "Del_Construction_Warn") {
                                this.notify(I18n.t("Backend.Construction.Del_Construction_Warn"), "error")
                            }
                            break
                        }
                        default: this.notify(`Response: [${err.status}] ${err.message}`, 'error')

                    }
                }
            })
    }

    render() {
        return (
            <View
                onFetchExcelData={this.onFetchExcelData}
                onFetchData={this.onFetchData}
                onRefTable={this.onRefTable}
                onDeleteData={this.onDeleteData}
                data={this.props.data}
                excelData={this.props.excelData}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        data: selector(state, "construction.list", {}),
        excelData: selector(state, "construction.excelData")
    }
}

export default withRouter(connect(mapStateToProps)(Index))
import React from "react";
import { Grid } from "@material-ui/core";
import { TextField, Validation } from "components/Forms";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import BaseContainer, { selector } from "containers/BaseContainer";
import moment from "moment";

class WorkingLog extends BaseContainer {
	constructor(props) {
		super(props);
		this.state = {
			editDriverWorkLog: false,
		};
		this.validate = {
			memo: [Validation.maxLength(20, I18n.t("Form.mangleng20"))],
		};
	}

	render() {
		let {
			idRequest,
			updateThisMonth,
			listDriverLengthCalendar,
			startEvent,
		} = this.props;
		let driving_length_on_working = null,
			driving_length_on_private = null,
			fuel_supply_amount = null,
			memo = null;

		if (
			listDriverLengthCalendar &&
			![null, undefined].includes(listDriverLengthCalendar)
		) {
			listDriverLengthCalendar.map((item) => {
				if (item.date == moment(startEvent).format("YYYY-MM-DD")) {
					driving_length_on_working = item.driving_length_on_working;
					driving_length_on_private = item.driving_length_on_private;
					fuel_supply_amount = item.fuel_supply_amount;
					memo = item.memo;
				}
			});
		}

		return (
			<Grid container className="working-value-update">
				<TextField type="hidden" name="idRequest" value={idRequest} />
				<Grid item xs={12} lg={6} style={{ paddingRight: "10px" }}>
					<TextField
						fullWidth
						label={`${I18n.t("Calendar.driving_length_on_working")} (Km)`}
						type="number"
						inputProps={{ step: "any" }}
						disabled={!updateThisMonth}
						value={driving_length_on_working}
						name="driving_length_on_working"
					/>
					<TextField
						fullWidth
						label={`${I18n.t("Calendar.driving_length_on_private")} (Km)`}
						type="number"
						inputProps={{ step: "any" }}
						disabled={!updateThisMonth}
						value={driving_length_on_private}
						name="driving_length_on_private"
					/>
				</Grid>
				<Grid item xs={12} lg={6} style={{ paddingRight: "10px" }}>
					<TextField
						fullWidth
						label={`${I18n.t("Calendar.fuel_supply_amount")} (ℓ)`}
						type="number"
						inputProps={{ step: "any" }}
						disabled={!updateThisMonth}
						value={fuel_supply_amount}
						name="fuel_supply_amount"
					/>
					<TextField
						fullWidth
						label={I18n.t("Calendar.memo")}
						type="text"
						disabled={!updateThisMonth}
						value={memo}
						name="memo"
						validate={this.validate.memo}
					/>
				</Grid>
			</Grid>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		WorkingLog: selector(state, "calendar.WorkingLog", []),
	};
};
export default connect(mapStateToProps)(WorkingLog);

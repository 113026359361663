import React from 'react'
import PropTypes, { element } from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import {
    IconButton,
    Icon,
    Tooltip
} from '@material-ui/core'
import BaseView from 'views/BaseView'
import ExportExcel from 'components/ExportExcel/ExportExcel'
import PaperFade from "components/Main/PaperFade"
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'
import FilterForm from './Components/filter.form'
import _ from 'lodash'


const GridTable = React.lazy(() => import('components/Table/GridTable'))
const styles = theme => ({
    gridTable: {
        height: "calc(100vh - 100px)",
        // marginTop: '-20px'
    }
})


class Index extends BaseView {
    constructor(props) {
        super(props)
        this.state = {
            display: 0,
        }
        this.table = {
            columns: [
                {
                    name: 'index',
                    title: I18n.t("Table.header.user.index"),
                    type: "text",
                    filterable: false,
                    sortable: false
                },
                {
                    name: 'code',
                    title: I18n.t('Table.header.user.code'),
                    filterable: false,
                    sortable: false
                },
                {
                    name: 'name',
                    title: I18n.t('Table.header.user.name'),
                    filterable: false,
                    sortable: false
                },
                {
                    name: 'construction_code',
                    title: I18n.t('Table.header.construction.code'),
                    filterable: false,
                    sortable: false,
                    formatterComponent: (data) => {
                        return _.get(data, 'row.construction_code', '').replace(/-/g, '')
                    }
                },
                {
                    name: 'construction_name',
                    title: I18n.t('Table.header.construction.name'),
                    filterable: false,
                    sortable: false
                },
                {
                    name: 'construction_workTime',
                    title: I18n.t('Table.header.construction._timeConstruction'),
                    filterable: false,
                    sortable: false,
                    formatterComponent: (data) => {
                        let construction_workTime = _.get(data, 'row.construction_workTime', '')
                        return construction_workTime
                    }
                },
                {
                    name: 'totalWorkTime',
                    title: I18n.t('Table.header.construction.totalWorkTime'),
                    filterable: false,
                    sortable: false,
                    formatterComponent: (data) => {
                        let totalWorkTime = _.get(data, 'row.totalWorkTime', '')
                        return totalWorkTime
                    }
                }

                // {
                //     name: '_id',
                //     title: I18n.t('Table.header.action'),
                //     filterable: false,
                //     sortable: false,
                //     formatterComponent: (data) => {
                //         return this.customActionColumn(data)
                //     }
                // },
            ],
            defaultSort: [],
            tableColumnExtensions: [
                // { columnName: '_id', align: 'center' },
                { columnName: 'code', wordWrapEnabled: true },
                { columnName: 'name', wordWrapEnabled: true },
                { columnName: 'construction_code', wordWrapEnabled: true },
                { columnName: 'construction_name', wordWrapEnabled: true },
                { columnName: 'construction_workTime', wordWrapEnabled: true, align: "right" },
                { columnName: 'totalWorkTime', wordWrapEnabled: true, align: "right" },
            ],

            //tên các column name không muốn xuất file
            ignoreExcelColumns: ["index", "_id"],
            //nếu tổng nhỏ hơn 990 thì tính theo %, ngược lại tính theo px
            columnWidths: [
                {
                    name: 'index',
                    width: 70
                },
                {
                    name: 'code',
                    width: 100
                },
                {
                    name: 'name',
                    width: 120
                },
                {
                    name: 'construction_code',
                    width: 100
                },
                {
                    name: 'construction_name',
                    width: 200
                },
                {
                    name: 'construction_workTime',
                    width: 120
                },
                {
                    name: 'totalWorkTime',
                    width: 120
                }
                // {
                //     name: '_id',
                //     width: 150
                // }
            ]
        }
        this.ConfirmDialog = null
        this.renderSelectedActions = this.renderSelectedActions.bind(this)
        this.renderToolbarActions = this.renderToolbarActions.bind(this)
    }

    getExcelName = () => {
        let startDate = moment(this.filterForm.state.startDate).format('YYYY年MM月DD日')
        let endDate = moment(this.filterForm.state.endDate).format('YYYY年MM月DD日')
        return `実質労務費${startDate}${endDate}.xlsx`
    }

    startExport = (cb) => {
        cb(this.props.data)
    }

    renderToolbarActions() {
        const { data = [] } = this.props
        let { ignoreExcelColumns } = this.table

        let excelColumns = [
            {
                name: 'construction_code',
                title: '工事コード',
                type: "text"
            },
            {
                name: 'construction_name',
                title: '工事名',
                type: "text"
            },
            {
                name: 'construction_workTime',
                title: '現場労務時間',
                type: 'number'
            },
            {
                name: 'realityCost',
                title: '実質労務費',
                type: 'number'
            }
        ]

        let columnsExcel = [
            {
                name: 'construction_code',
                width: 100,
            },
            {
                name: 'construction_name',
                width: 170,
            },
            {
                name: 'construction_workTime',
                width: 100,
            },
            {
                name: 'realityCost',
                width: 100,
            }
        ]
        let columnWidthExcel = [...this.table.columnWidths]

        let renumeration = {
            name: "renumeration",
            title: I18n.t("Table.header.renumeration"),
            type: "number",
        }
        let salary = {
            name: "salary",
            title: I18n.t("Table.header.salary"),
            type: "number"
        }

        let renumerationWidth = {
            name: "renumeration",
            width: 100
        }
        let salaryWidth = {
            name: "salary",
            width: 120
        }

        columnWidthExcel.unshift(renumerationWidth)
        columnWidthExcel.push(salaryWidth)

        return [
            <ExportExcel
                ignoreExcelColumns={[]}
                onClick={this.startExport}
                name={this.getExcelName}
                columnWidths={columnsExcel}
                columns={excelColumns}
                key="exportExcel"
                type='constructionExport' />
        ]
    }

    renderSelectedActions(selectedIds) {
        return [
            <Tooltip title={I18n.t("toolTip.delete")} key="create">
                <IconButton key="delete" onClick={() => this.ConfirmDialog.show(selectedIds)}>
                    <Icon>delete</Icon>
                </IconButton>
            </Tooltip>
        ]
    }

    render() {

        const { classes, onSubmitDate } = this.props
        let { data } = this.props || []
        return (
            <PaperFade showLoading={true}>
                <FilterForm
                    onSubmitDate={onSubmitDate}
                    onRef={ref => this.filterForm = ref}
                />
                <GridTable
                    id="ConstructioHourIndex"
                    pageHiding={true}
                    estimatedRowHeight={70}
                    className={classes.gridTable}
                    onFetchData={this.props.onFetchData}
                    onRefTable={this.props.onRefTable}
                    columns={this.table.columns}
                    rows={data}
                    // totalCount={data.total}
                    // pageSize={data.pageSize}
                    defaultSort={this.table.defaultSort}
                    showCheckboxColumn={false}
                    height="auto"
                    selectedActions={this.renderSelectedActions}
                    tableColumnExtensions={this.table.tableColumnExtensions}
                    defaultColumnWidths={this.table.columnWidths}
                    tableActions={this.renderToolbarActions}

                // filterRowHiding={true}

                />
            </PaperFade>
        )
    }
}

Index.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(withRouter(Index))
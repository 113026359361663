import React from "react";
import { Grid, Button, CardContent } from "@material-ui/core";
import { Form, TextField, Validation } from "components/Forms";
import { I18n } from "react-redux-i18n";
import CalendarAction from "../../../actions/CalendarAction";
import { connect } from "react-redux";
import BaseContainer, { selector } from "containers/BaseContainer";
import moment from "moment";

const styles = {
	inputGroup: {
		display: "flex",
		alignItems: "flex-end",
		justifyContent: "flex-start",
	},
};

class LengthInput extends BaseContainer {
	constructor(props) {
		super(props);
		this.validate = {
			length: [Validation.required(I18n.t("Form.required"))],
		};
		this.state = {
			editMode: false,
		};
	}

	onSubmit = (values, e) => {
		const { type } = this.props;
		let dateCalendar = localStorage.getItem("dateCalendar");
		let permissions = JSON.parse(localStorage.user).permissions || [];

		if (dateCalendar) {
			let yearMonth = moment(dateCalendar).format("YYYY-MM");
			this.props
				.dispatch(
					CalendarAction.updateDrivingLength({
						yearMonth,
						length: values.length,
						type: type,
						permissions: permissions,
					})
				)
				.then((res) => {
					if (!res.error) {
						this.notify(I18n.t("Message.editDataSuccess"));
						this.setState({
							editMode: false,
						});
					} else {
						let err = res.error;
						switch (err.status) {
							case 417: {
								if (err.message === "Block_Update") {
									this.notify(I18n.t("Backend.History.Block_Update"), "error");
								}
								break;
							}
							default:
								this.notify(
									`Response: [${err.status}] ${err.message}`,
									"error"
								);
						}
					}
				});
		}
	};

	toggleEditMode = () => {
		this.setState({
			editMode: !this.state.editMode,
		});
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.log != undefined) {
			this.setState({
				editMode: !nextProps.log.length,
			});
		}
	}

	render() {
		const { editMode } = this.state;
		const { log, type } = this.props;

		return (
			<Grid item xs={12} sm={12} lg={5}>
				<Form onSubmit={this.onSubmit}>
					<div style={styles.inputGroup}>
						{editMode ? (
							<TextField
								fullWidth
								label={I18n.t(`Calendar.${type}DrivingLength`)}
								margin="none"
								name="length"
								value={log ? log.length : ""}
								validate={this.validate.length}
								type="number"
								inputProps={{ step: "any" }}
							/>
						) : (
							<div>
								<span style={{ fontWeight: "bold" }}>
									{I18n.t(`Calendar.${type}DrivingLength`)}
								</span>{" "}
								: {log ? log.length : ""}
							</div>
						)}
						<Button
							type="button"
							variant="contained"
							onClick={this.toggleEditMode}
							color="primary"
							style={{
								marginLeft: "0.5rem",
								display: editMode ? "none" : "block",
							}}
						>
							{I18n.t("Button.edit")}
						</Button>

						<Button
							type="submit"
							variant="contained"
							color="primary"
							style={{
								marginLeft: "0.5rem",
								display: editMode ? "block" : "none",
							}}
						>
							{I18n.t("Button.submit")}
						</Button>
					</div>
				</Form>
			</Grid>
		);
	}
}

class DrivingLength extends BaseContainer {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<CardContent>
				<Grid container spacing={8}>
					<LengthInput
						type="prevMonth"
						log={this.props.listDrivingLog.prevMonth}
						{...this.props}
					/>
					<Grid item xs={12} sm={12} lg={2}></Grid>
					<LengthInput
						type="thisMonth"
						log={this.props.listDrivingLog.currentMonth}
						{...this.props}
					/>
				</Grid>
			</CardContent>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		listDrivingLog: selector(state, "calendar.drivingLog", {}),
	};
};
export default connect(mapStateToProps)(DrivingLength);

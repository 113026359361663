import React, { useEffect } from "react";
import axios from "axios";

const Authorize = (props) => {
  
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const remoteAccessToken = props.match.params.accessToken
    const returnUrl = searchParams.get('returnUrl');
    const token = localStorage.token;
    axios.post(`${window.config.API_HOST}/api/v1/users/remote-login`, {
      remoteAccessToken, token
    }).then(({data}) => location.href = `${returnUrl}${data.returnPath}`)
  }, [])

  return (
    <div style={{
      display: "flex",
      backgroundColor: "#fff",
      display: "flex",
      width: "100%",
      height: "100vh",
      background: "#fff",
      justifyContent: "center",
    }}>
      <svg style={{width: "50px"}} version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
        <circle fill="none" stroke="#ccc" strokeWidth={4} cx={50} cy={50} r={44} style={{opacity: '0.5'}} />
        <circle fill="#fff" stroke="#ccc" strokeWidth={3} cx={8} cy={54} r={6}>
          <animateTransform attributeName="transform" dur="2s" type="rotate" from="0 50 48" to="360 50 52" repeatCount="indefinite" />
        </circle>
      </svg>
    </div>
  );
}

export default Authorize;

import React from 'react';
import View from 'views/MonthlyReport/Index'
import UserAction from '../../actions/UserAction'
import MonthlyReportAction from '../../actions/MonthlyReportAction'
import RequestAction from '../../actions/RequestAction'
import { I18n } from 'react-redux-i18n'
import BaseContainer, { selector } from 'containers/BaseContainer'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import common from 'common'
/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
var startDate,endDate;
// var date_current=[]
class Index extends BaseContainer {
    
    constructor(props) {
      super(props)
      this.refTable = null
      this.onFetchData = this.onFetchData.bind(this)
      this.onRefTable = this.onRefTable.bind(this)
      let params = common.params()
      if (params.year_month) {
        startDate = `${params.year_month}-01`
        endDate = moment(new Date(startDate)).endOf('month').format('YYYY-MM-DD')
      } else {
        startDate = moment().startOf('month').format('YYYY-MM-DD')
        endDate = moment().endOf('month').format('YYYY-MM-DD')
      }

      this.query = {
        startDate,
        endDate,
        _id: params.user_id,
        time_keeping: true,
        unique: true,
        pageSize: -1
      }

      this.props.dispatch(MonthlyReportAction.getApprovePaidHalf(this.query))
    }

    storePrintData() {
      const { user = {} } = this.props.data || {}
      let { startDate, _id } = this.query;
      let month = startDate = moment(startDate).format('YYYY.MM');
      localStorage.setItem("print_timekeepings", JSON.stringify({ month, code: user.code, name: user.name }));
    }

    componentWillUnmount() {
      localStorage.removeItem("print_timekeepings");
    }

    onFetchData(state) {
      let checkTimeZoneFE = localStorage.getItem('frontTimeZone')
      this.query = {
        ...this.query,
        ...state,
        checkTimeZoneFE: checkTimeZoneFE
      }

      this.props.dispatch(MonthlyReportAction.getApprovePaidHalf(this.query))
      this.props.dispatch(MonthlyReportAction.fetchAll(this.query));
    }

    onRefTable(ref) {
      this.refTable = ref
    }

    onSubmit = (value) => {
      this.storePrintData()
      this.query = {
          ...this.query,
          ...value
      }
      this.props.dispatch(MonthlyReportAction.fetchAll(this.query))  
      this.props.dispatch(MonthlyReportAction.getApprovePaidHalf(this.query))    
    }

    submitRequest = (params, cb) => {
      this.props.dispatch(MonthlyReportAction.submitRequest(params)).then(res => {
        cb()
        this.onFetchData()
      })
    }

    getApprover = (cb) => {
      let _id = this.query._id || localStorage.getItem("userCalendar")
      this.props.dispatch(RequestAction.getApprover({ userId: _id, pageSize: -1 })).then(res => cb())
    }

    render() {
      let { data, users, approver, requestAppPaidHalf } = this.props
      this.storePrintData()

      return (
        <View
          onFetchData={this.onFetchData}
          onRefTable={this.onRefTable}
          data={data || []}
          is60Time={data.is60Time}
          users={users || []}
          onSubmit={this.onSubmit}
          requestAppPaidHalf={requestAppPaidHalf}
          submitRequest={this.submitRequest}
          getApprover={this.getApprover}
          approver={approver}
        />
      )
    }
}

const mapStateToProps = state => {
  return {
    data: selector(state, "monthlyReport.list", []),
    users: selector(state, "monthlyReport.list.users", []),
    approver: selector(state, 'request.getApprover', {}),
    requestAppPaidHalf: selector(state, "monthlyReport.data", [])
  }
}

export default withRouter(connect(mapStateToProps)(Index))

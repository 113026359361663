import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { I18n } from 'react-redux-i18n';
import BaseView from 'views/BaseView';
import SelectField, { Option } from 'components/Forms/SelectField'
import { Grid, InputAdornment, Card, CardContent, CardHeader, CardActions, Button } from '@material-ui/core'
import { TextField, Validation } from 'components/Forms/';
const styles = theme => ({
    card: {
        padding: `0px ${theme.spacing.unit * 4}px ${theme.spacing.unit * 3}px`,
    },
    cardHeader: {
        padding: ` ${theme.spacing.unit * 3}px ${theme.spacing.unit * 4}px`,
    }
})
class CardRound extends BaseView {
    render() {
        const { classes, data } = this.props
        return (
            <Card>
                <CardHeader
                    className={classes.cardHeader}
                    title={I18n.t("Label.settingRound")}
                />
                <CardContent className={classes.card}>
                    <Grid container direction="row" alignItems="flex-end" spacing={32}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label={I18n.t('Input.roundStart')}
                                type="number"
                                inputProps={{step: 'any'}}
                                name="roundStart"
                                value={String(data.roundStart)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{I18n.t("Common.minute")}</InputAdornment>,
                                }}
                                onKeyDown= {(e) => {
                                    if(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", 'Backspace', 'Tab'].indexOf(e.key) < 0){
                                        e.preventDefault()
                                    } 
                                    if(e.target.value.length >= 3){
                                        if(['Backspace', 'Tab'].indexOf(e.key) < 0){
                                            e.preventDefault()
                                        }
                                    }
                                }}
                                validate={[
                                    Validation.required(I18n.t("Form.required")),
                                    Validation.max(180, I18n.t("Form.max180"))
                                ]}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectField
                                fullWidth
                                name="roundStartType"
                                label={I18n.t("Input.roundType")}
                                value={data.roundStartType}
                            >
                                <Option value="1">{I18n.t('Common.floor')}</Option>
                                <Option value="2">{I18n.t('Common.ceil')}</Option>
                            </SelectField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label={I18n.t('Input.roundEnd')}
                                type="number"
                                inputProps={{step: 'any'}}
                                name="roundEnd"
                                value={String(data.roundEnd)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{I18n.t("Common.minute")}</InputAdornment>,
                                }}
                                onKeyDown= {(e) => {
                                    if(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", 'Backspace', 'Tab'].indexOf(e.key) < 0){
                                        e.preventDefault()
                                    } 
                                    if(e.target.value.length >= 2){
                                        if(['Backspace', 'Tab'].indexOf(e.key) < 0){
                                            e.preventDefault()
                                        }
                                    }
                                }}
                                validate={[
                                    Validation.required(I18n.t("Form.required")),
                                    Validation.max(60, I18n.t("Form.max60"))
                                ]}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectField
                                fullWidth
                                name="roundEndType"
                                label={I18n.t("Input.roundType")}
                                value={data.roundEndType}
                            >
                                <Option value="1">{I18n.t('Common.floor')}</Option>
                                <Option value="2">{I18n.t('Common.ceil')}</Option>
                            </SelectField>
                        </Grid>
                    </Grid>
                </CardContent>
                {/* <CardActions className={classes.card}>
                    <Button size="small" color="primary" type="submit" variant='contained'>
                    {I18n.t("Button.submit")}
                    </Button>
                </CardActions> */}
            </Card>
        )
    }
}

export default withStyles(styles)(CardRound);

import React from 'react';
import { Paper } from '@material-ui/core';

class AccessDenied extends React.Component {
  render() {
    return (
      <Paper>
        AccessDenied
      </Paper>
    );
  }
}

export default AccessDenied
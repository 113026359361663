import React from 'react'
import View from 'views/Report/Index'
import ReportAction from '../../actions/ReportAction'
import AreaAction from '../../actions/AreaAction'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import BaseContainer, { selector } from 'containers/BaseContainer'
import _ from 'lodash'
/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
class Index extends BaseContainer {
    constructor(props) {
        super(props)
        this.refTable = null
        this.onFetchData = this.onFetchData.bind(this)
        this.onRefTable = this.onRefTable.bind(this)
        this.onDeleteData = this.onDeleteData.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.filterData = this.filterData.bind(this)
        this.query = {};
        const reportArea = localStorage.getItem("filter.reportArea") || '';
        this.filterArea = this.getAreaFilter(reportArea)
    }

    componentWillMount() {
        let width = window.innerWidth
        this.props.dispatch(AreaAction.fetchAll({ pageSize: -1 }))
        if (width <= 600) {
            this.props.dispatch(ReportAction.fetchAll({ pageSize: -1 }))
        }

    }

    componentDidMount() {
        localStorage.setItem('userCalendar', JSON.parse(localStorage.user)._id)
        localStorage.setItem('dateCalendar', new Date())
    }

    filterData(value) {
        let element = {
            currentPage: 0,
            pageSize: -1,
            sorting: [],
            filters: value,
        }
        if (!_.isEmpty(value)) {
            this.props.dispatch(ReportAction.fetchAll(element))
        }
    }

    getAreaFilter(value){
        return {
            columnName: "Area.name",
            dataType: "text",
            operation: "equal",
            value: value || ''
        }
    }

    onFilterArea = (data = {}) => {
        this.filterArea = this.getAreaFilter(data.value)
        let query = JSON.parse(JSON.stringify(this.query));
        query.filters.push(this.filterArea);
        this.props.dispatch(ReportAction.fetchAll(query))
    }
    componentWillUnmount(){
        if(this.filterArea){
            localStorage.setItem("filter.reportArea", this.filterArea.value);
        }
    }

    onFetchData(state = {}) {
        this.query = JSON.parse(JSON.stringify(state));
        const reportArea = localStorage.getItem("filter.reportArea") || '';
        if(reportArea) state.filters.push(this.getAreaFilter(reportArea))
        this.props.dispatch(ReportAction.fetchAll(state))
    }

    onRefTable(ref) {
        this.refTable = ref
    }

    onDeleteData(selectedIds) {
        this.props.dispatch(ReportAction.delete({ ids: selectedIds }))
        .then(result => {
            if(!result.error){
                //success
                this.notify(I18n.t('Message.deleteDataSuccess'))
                if(this.refTable) {
                    this.refTable.onSelectionChange([])
                    this.refTable.onFetchData()
                }
            }
            else{
                //error
                this.notify(`Response: [${result.error.status}] ${result.error.message}`, 'error')
            }
        })
    }

    onSubmit(_id) {
        this.notify('no msg')
        this.props.dispatch(ReportAction.edit({ _id: _id }))
    }

    render() {
        return (
            <View
                onFetchData={this.onFetchData}
                onRefTable={this.onRefTable}
                onDeleteData={this.onDeleteData}
                filterData={this.filterData}
                data={this.props.data}
                onSubmit={this.onSubmit}
                area={this.props.area}
                onFilterArea = {this.onFilterArea}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        area: selector(state, 'area.list.data', []),
        data: selector(state, "report.list", {}),
    }
}

export default withRouter(connect(mapStateToProps)(Index))
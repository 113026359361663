import React from 'react'
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { DateTimeField } from 'components/Forms'
import { BaseView } from 'views/BaseView';
import { I18n } from 'react-redux-i18n';
import { Grid, InputAdornment, FormLabel } from '@material-ui/core';
import moment from 'moment'
const styles = theme => ({
   rangeTime: {
      marginBottom: "20px"
   }
})

class RangeTime extends BaseView {
   constructor(props) {
      super(props);
      this.state = {
         reload: false,
      }
      this.start = null;
      this.end = null;
      this.error = undefined;
      this.handleChange = this.handleChange.bind(this)
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      this.start = nextProps.fromProps.value;
      this.end = nextProps.toProps.value;
   }

   handleChange(value, name) {
      this[name] = value;
      this.checkError(this.start, this.end);
      this.setState({ reload: !this.state.reload })
      let _name = name === 'start' ? 'fromProps' : 'toProps'
      if (typeof this.props[_name].onChange === 'function') {
         this.props[_name].onChange(value)
      }
   }

   checkError = (start, end) => {
      // end time can be 0:00 - 06:00 of next day
      const beforeTime = moment('00:00:00', 'HH:mm:ss')
      const afterTime = moment('06:00:00', 'HH:mm:ss')

      if (moment(start) >= moment(end)) {
         if (moment(moment(end).format('HH:mm:ss'), 'HH:mm:ss').isBetween(beforeTime, afterTime, null, '[]')) {
            this.error = undefined
            return
         } else {
            this.error = I18n.t('Exception.endDate')
         }
         this.error = I18n.t('Exception.outOfEndDateRange')
      } else {
         this.error = undefined
      }
   }

   render() {
      const { formLabelText, fromProps, toProps, classes, disabledError } = this.props
      return (
         <React.Fragment>
            <FormLabel>{formLabelText}</FormLabel>
            <Grid container direction="row" alignItems="flex-end" spacing={16} className={classes.rangeTime}>
               <Grid item xs={12} lg={6}>
                  <DateTimeField
                     fullWidth
                     label={I18n.t('Common.start')}
                     showDate={false}
                     format={'HH:mm'}
                     InputLabelProps={{ shrink: true }}
                     InputProps={{
                        startAdornment: <InputAdornment position="start">{I18n.t("Common.from")}</InputAdornment>,
                     }}
                     {...fromProps}
                     onChange={value => this.handleChange(value, 'start')}
                     defaultValue={this.props.fromProps.value}
                  />
               </Grid>
               <Grid item xs={12} lg={6}>
                  <DateTimeField
                     fullWidth
                     showDate={false}
                     label={I18n.t('Common.end')}
                     format={'HH:mm'}
                     InputLabelProps={{ shrink: true }}
                     InputProps={{
                        startAdornment: <InputAdornment position="start">{I18n.t("Common.to")}</InputAdornment>,
                     }}
                     {...toProps}
                     error={disabledError ? undefined : this.error}
                     onChange={value => this.handleChange(value, 'end')}
                     validate={[() => disabledError ? undefined : this.error]}
                     defaultValue={this.props.toProps.value}
                  />
               </Grid>
            </Grid>
         </React.Fragment>)
   }
}

RangeTime.propTypes = {
   formLabelText: PropTypes.string.isRequired,
   fromProps: PropTypes.object.isRequired,
   toProps: PropTypes.object.isRequired,
};

export default withStyles(styles)(RangeTime);
import React from "react";
import PropTypes from "prop-types";
import BaseView from "views/BaseView";
import { IconButton, Icon, Tooltip } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Template from "./Template";

class Index extends BaseView {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <PDFDownloadLink
          document={<Template data={this.props.data} />}
          fileName="monthly-report.pdf"
        >
          {({ blob, url, loading, error }) =>
            <Tooltip title={I18n.t("toolTip.print")} key="print">
                <IconButton>
                  <Icon>local_printshop</Icon>
                </IconButton>
              </Tooltip>
          }
        </PDFDownloadLink>
      </>
    );
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Index;

import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import PaperFade from "components/Main/PaperFade";
import { I18n } from "react-redux-i18n";
import _ from "lodash";

const styles = (theme) => ({
	paper: {
		margin: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 0}px`,
	},
	root: {
		width: "100%",
		marginTop: `${theme.spacing.unit * 3}px`,
		overflowX: "auto",
	},
	fixLeftCell: {
		[theme.breakpoints.down("sm")]: {
			paddingLeft: `${theme.spacing.unit * 1}px`,
			paddingRight: `${theme.spacing.unit * 2}px`,
		},
	},
	fixRightCell: {
		[theme.breakpoints.down("sm")]: {
			// paddingLeft: `${theme.spacing.unit * 1}px`,
			paddingRight: `${theme.spacing.unit * 1}px !important`,
		},
	},
});

class CustomTable extends Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				name: "User.code",
				title: I18n.t("Table.header.user.code"),
			},
			{
				name: "User.name",
				title: I18n.t("Table.header.user.name"),
			},
			{
				name: "totalDays",
				title: I18n.t("Table.header.hour.totalDays"),
			},
			{
				name: "normalDays.standardHour",
				title: I18n.t("Table.header.hour.standardHour"),
			},
			{
				name: "normalDays.workNightHour",
				title: I18n.t("Table.header.hour.workNightHour"),
			},
			{
				name: "normalDays.morningOT",
				title: I18n.t("Table.header.hour.morningOT"),
			},
			{
				name: "normalDays.normalOT",
				title: I18n.t("Table.header.hour.normalOT"),
			},
			{
				name: "normalDays.nightOT",
				title: I18n.t("Table.header.hour.nightOT"),
			},
			{
				name: "statutoryDays.standardHour",
				title: I18n.t("Table.header.hour.statutoryDays_standardHour"),
			},
			// {
			//     name: 'statutoryDays.workNightHour',
			//     title: I18n.t('Table.header.hour.statutoryDays_workNightHour')
			// },
			{
				name: "statutoryDays.morningOT",
				title: I18n.t("Table.header.hour.statutoryDays_morningOT"),
			},
			{
				name: "statutoryDays.normalOT",
				title: I18n.t("Table.header.hour.statutoryDays_normalOT"),
			},
			{
				name: "statutoryDays.nightOT",
				title: I18n.t("Table.header.hour.statutoryDays_nightOT"),
			},
			{
				name: "nonStatutoryDays.standardHour",
				title: I18n.t("Table.header.hour.nonStatutoryDays_standardHour"),
			},
			// {
			//     name: 'nonStatutoryDays.workNightHour',
			//     title: I18n.t('Table.header.hour.nonStatutoryDays_workNightHour')
			// },
			{
				name: "nonStatutoryDays.morningOT",
				title: I18n.t("Table.header.hour.nonStatutoryDays_morningOT"),
			},
			{
				name: "nonStatutoryDays.normalOT",
				title: I18n.t("Table.header.hour.nonStatutoryDays_normalOT"),
			},
			{
				name: "nonStatutoryDays.nightOT",
				title: I18n.t("Table.header.hour.nonStatutoryDays_nightOT"),
			},
			{
				name: "lateWork",
				title: I18n.t("Table.header.hour.lateWork"),
			},
			{
				name: "absent",
				title: I18n.t("Table.header.hour.absent"),
			},
			{
				name: "work_dayoff_holiday",
				title: I18n.t("Table.header.hour.work_dayoff_holiday"),
			},
			{
				name: "rosteredDayOff",
				title: I18n.t("Table.header.hour.rosteredDayOff"),
			},
			{
				name: "remainedPaidHoliday",

				title: I18n.t("Table.header.hour.remainedPaidHoliday"),
			},
			{
				name: "usedPaidHoliday",
				title: I18n.t("Table.header.hour.usedPaidHoliday"),
			},

			{
				name: "totalTime",
				title: I18n.t("Table.header.hour.totalTime"),
			},
		];
	}
	render() {
		const { classes, row } = this.props;
		return (
			<PaperFade className={classes.paper}>
				<Table className={classes.table}>
					<TableBody>
						{this.columns.map((column, index) => (
							<TableRow key={index}>
								<TableCell
									className={classes.fixLeftCell}
									component="th"
									scope="row"
								>
									{column.title}
								</TableCell>
								<TableCell className={classes.fixRightCell} align="right">
									{_.get(row, column.name, 0)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</PaperFade>
		);
	}
}

CustomTable.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomTable);

import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { Form } from "components/Forms";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import BaseContainer from "containers/BaseContainer";
import UpdateContructionTime from "./event.updateConstructionTime";
import moment from "moment";

class DialogConstruction extends BaseContainer {
	constructor(props) {
		super(props);
		this.state = {
			value: 0,
		};
	}

	render() {
		let value = this.state.value;
		let listConstruction = _.get(this.props, "listConstruction", []) || [];
		let listDriverLengthCalendar =
			_.get(this.props, "listDriverLengthCalendar", []) || [];
		let getAllConstructionDropDown = _.get(
			this.props,
			"getAllConstructionDropDown",
			[] || []
		);
		let notCheckInOut = true;
		let listHistory = _.get(this.props, "listHistory", []) || []; // data trong history

		let startEvent = _.get(this.props, "start", ""); // start Event trong state.date
		let classes = _.get(this.props, "classes", "");
		let startDate = _.get(this.props, "construction.start", null);
		let getRequests = _.get(this.props, "getRequests", []);
		let userCalendar = localStorage.getItem("userCalendar");
		let checkUserLogin = false;
		if (JSON.parse(localStorage.user)._id === userCalendar) {
			checkUserLogin = true;
		}

		let checkEistDate = false;

		listConstruction.map((item) => {
			let start = _.get(item, "schedules.start", "");
			if (moment(startEvent).format("YYYY-MM-DD") === start) {
				checkEistDate = true;
			}
		});
		let converGetRequest = [];
		getRequests.map((item) => {
			if (item.date == moment(startDate).format("YYYY-MM-DD")) {
				converGetRequest.push(item);
			}
		});
		let checkHistory = false;
		if (Array.isArray(listHistory)) {
			listHistory.map((item) => {
				let date = _.get(item, "date", "");

				if (
					(!item.type || item.type == undefined) &&
					moment(startEvent).format("YYYY/MM/DD") ===
						moment(date).format("YYYY/MM/DD")
				) {
					checkHistory = true;
				}
			});
		}
		let checkExit = false;
		let checkExistStartConstruction = [];
		let countExistStart = 0;
		let updateThisMonth = true;
		return (
			<Form
				className={classes.fixHeight}
				onSubmit={this.props.updateHistoryCalendar}
			>
				{value === 0 && startEvent && (
					<Typography
						component="div"
						style={{ padding: 8 * 3 }}
						className={classes.fixXX}
					>
						<Grid>
							<Button variant="contained" color="default">
								{moment(startEvent).format("YYYY/MM/DD")}
							</Button>
						</Grid>
						{checkHistory ? (
							listHistory.map((item, index) => {
								let constructions = _.get(item, "constructions", []);
								let startTime = _.get(item, "startTime", "");
								let endTime = _.get(item, "endTime", "");
								let date = _.get(item, "date", "");

								let idRequest = undefined,
									newStartTime = undefined,
									newEndTime = undefined;
								if (item.titleHoliday && item.type) {
									return;
								} else {
									idRequest = item._id;
								}

								return (
									<Grid key={item._id}>
										<Grid>
											{moment(startEvent).format("YYYY/MM/DD") ==
											moment(date).format("YYYY/MM/DD") ? (
												<React.Fragment>
													<UpdateContructionTime
														innerRef={(ref) =>
															(this.UpdateContructionTime = ref)
														}
														constructions={constructions}
														startEvent={startEvent}
														getAllConstructionDropDown={
															getAllConstructionDropDown
														}
														classes={classes}
														getRequests={getRequests}
														idRequest={idRequest}
														notExistStart={true}
														listConstruction={listConstruction}
														listDriverLengthCalendar={listDriverLengthCalendar}
														index={index}
														endTime={
															newEndTime !== undefined ? newEndTime : endTime
														}
														startTime={
															newStartTime !== undefined
																? newStartTime
																: startTime
														}
														workingLogItem={item}
														onCancel={this.props.onCancel}
														flagSubmit={this.props.flagSubmit}
													/>
												</React.Fragment>
											) : (
												""
											)}
										</Grid>
									</Grid>
								);
							})
						) : checkEistDate ? (
							listConstruction.map((element, count) => {
								let notExistStart = false;
								if (listConstruction.length == count + 1) {
									checkExit = true;
								} else {
									checkExit = false;
								}
								let start = _.get(element, "schedules.start", "");

								if (
									(checkExit ||
										moment(startEvent).format("YYYY-MM-DD") === start) &&
									checkEistDate
								) {
									let code = _.get(element, "code", "");
									let name = _.get(element, "name", "");
									notCheckInOut = false;

									let index = 0;
									listConstruction.map((item) => {
										if (item.schedules.start === start) index += 1;
									});

									if (checkExistStartConstruction.indexOf(start) > -1) {
										countExistStart += 1;
									} else {
										countExistStart += 1;
										checkExistStartConstruction.push(start);
									}

									if (index == 1 || countExistStart == index) {
										notExistStart = true;
										countExistStart = 0;
									}

									if (
										moment(startEvent).format("YYYY/MM/DD") ==
										moment(start).format("YYYY/MM/DD")
									) {
										return (
											<React.Fragment key={element._id}>
												{!updateThisMonth ? (
													!checkExit ? (
														<div className="menu-link-text">
															<span>
																<p>
																	{I18n.t("Input.construction.code")}: {code}
																</p>
																<p>
																	{I18n.t("Input.construction.name")}: {name}
																</p>
																<hr></hr>
															</span>
														</div>
													) : moment(startEvent).format("YYYY-MM-DD") ===
													  start ? (
														<div className="menu-link-text">
															<span>
																<p>
																	{I18n.t("Input.construction.code")}: {code}
																</p>
																<p>
																	{I18n.t("Input.construction.name")}: {name}
																</p>{" "}
																<hr></hr>
															</span>
														</div>
													) : (
														""
													)
												) : (
													""
												)}
												{updateThisMonth ? (
													<div className="menu-link-text2">
														<React.Fragment>
															<UpdateContructionTime
																innerRef={(ref) =>
																	(this.UpdateContructionTime = ref)
																}
																constructions={element}
																startEvent={startEvent}
																getAllConstructionDropDown={
																	getAllConstructionDropDown
																}
																classes={classes}
																getRequests={getRequests}
																idRequest={null}
																notExistStart={notExistStart}
																listConstruction={listConstruction}
																listDriverLengthCalendar={
																	listDriverLengthCalendar
																}
																index={count}
																endTime={null}
																startTime={null}
																workingLogItem={element}
																onCancel={this.props.onCancel}
																flagSubmit={this.props.flagSubmit}
															/>
														</React.Fragment>
													</div>
												) : notExistStart ? (
													<div className="menu-link-text2">
														<div key={count} style={{ color: "red" }}>
															<p>
																{I18n.t("Calendar.checkIn")}:{" "}
																{I18n.t("Exception.notCheckIn")}
															</p>
															<p>
																{I18n.t("Calendar.checkOut")}:{" "}
																{I18n.t("Exception.notCheckOut")}
															</p>
														</div>
													</div>
												) : (
													""
												)}
											</React.Fragment>
										);
									}
								}
							})
						) : updateThisMonth ? (
							<React.Fragment>
								<UpdateContructionTime
									innerRef={(ref) => (this.UpdateContructionTime = ref)}
									constructions={null}
									startEvent={startEvent}
									getAllConstructionDropDown={getAllConstructionDropDown}
									classes={classes}
									getRequests={getRequests}
									idRequest={null}
									notExistStart={true}
									listConstruction={listConstruction}
									listDriverLengthCalendar={listDriverLengthCalendar}
									index={0}
									endTime={null}
									startTime={null}
									workingLogItem={null}
									onCancel={this.props.onCancel}
									flagSubmit={this.props.flagSubmit}
								/>
							</React.Fragment>
						) : (
							""
						)}
					</Typography>
				)}
				{value === 1 && (
					<Typography
						component="div"
						style={{ padding: 8 * 3 }}
						className={classes.fixXX}
					>
						<div>
							{converGetRequest.map((item, index) => {
								let _id = _.get(item, "_id", "");
								let typeRequest = item.type;
								let setData = _.get(item, "data", []);
								let status = _.get(item, "status", "");
								let deletedByAdmin = _.get(item, "deletedByAdmin", "");
								switch (typeRequest) {
									case 1:
										typeRequest = I18n.t("Input.request.typeEarlyLate");
										break;
									case 2:
										typeRequest = I18n.t("Input.request.typeOT");
										break;
									case 3:
										typeRequest = I18n.t("Input.request.typeSalaryCalendar");
										break;
									case 4:
										typeRequest = I18n.t("Input.request.typeWorkOnDayOff");
										break;
									case 5:
										typeRequest = I18n.t(
											"Input.request.typeCompensatedCalendar"
										);
										break;
									case 6:
										typeRequest = I18n.t("Input.request.typeReplace");
										break;
									case 8:
										typeRequest = I18n.t("Input.request.absent");
										break;
									case 9:
										typeRequest = I18n.t("Input.request.dayoff");
										break;
									case 10:
										typeRequest = I18n.t("Input.request.typeLate");
										break;
									default:
										break;
								}
								return (
									<div key={index}>
										<Grid container spacing={32}>
											<Grid item xs={8}>
												<p>
													{I18n.t("Input.date")}:{" "}
													{moment(item.date).format("YYYY/MM/DD")}
												</p>
												<p>
													<Link
														to={{ pathname: `/calendars/requests/${_id}` }}
														className={classes.cssLink}
													>
														{I18n.t("Input.typeRequest")}: {typeRequest}
													</Link>
												</p>
											</Grid>
											<Grid item xs={4}>
												{status ? (
													<Button
														className={classes.button}
														color={"primary"}
														variant="contained"
														size="small"
													>
														{I18n.t("Button.approved")}
													</Button>
												) : checkUserLogin ? ( // Kiem tra neu xem cong truong cua chinh minh moi tao xin phep dc
													<Link
														to={{ pathname: `/calendars/requests/${_id}/edit` }}
														className={classes.cssLink}
													>
														<Button
															className={classes.button}
															color={"primary"}
															variant="contained"
															size="small"
														>
															{I18n.t("Button.unApproved")}
														</Button>
													</Link>
												) : (
													<Button
														disabled={true}
														className={classes.button}
														color={"primary"}
														variant="contained"
														size="small"
													>
														{I18n.t("Button.unApproved")}
													</Button>
												)}
											</Grid>
										</Grid>
									</div>
								);
							})}
						</div>
						<div className={`${classes.setRight} ${classes.btnAddRequest}`}>
							{checkUserLogin ? ( // kiem tra nếu là xem lịch của chính mình mới dc xin phép lại
								<Grid container spacing={7}>
									<Grid item xs={7}></Grid>
									<Grid
										item
										xs={4}
										style={{ textAlign: "right", paddingBottom: "5%" }}
									>
										<Link
											style={{ textDecoration: "none" }}
											to={{ pathname: "/calendars/requests/create" }}
										>
											<Button
												style={{ width: "70%" }}
												variant="outlined"
												size="small"
												color="primary"
												onClick={() => this.setBackCalendar()}
											>
												{I18n.t("Button.addRequest")}
											</Button>
										</Link>
									</Grid>
								</Grid>
							) : (
								<Grid container spacing={7}>
									<Grid item xs={7}></Grid>
									<Grid
										item
										xs={4}
										style={{ textAlign: "right", paddingBottom: "5%" }}
									>
										<Button
											disabled={true}
											variant="outlined"
											size="lg"
											color="primary"
											onClick={() => this.setBackCalendar()}
										>
											{I18n.t("Button.addRequest")}
										</Button>
									</Grid>
								</Grid>
							)}
						</div>
					</Typography>
				)}
			</Form>
		);
	}
}

export default connect()(DialogConstruction);

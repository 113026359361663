import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { I18n } from 'react-redux-i18n'
import BaseView from 'views/BaseView'
import { TextField } from 'components/Forms'
import {
   Card,
   CardContent,
   CardHeader,
   FormControlLabel,
   FormControl,
   FormLabel,
   RadioGroup,
   Radio
} from '@material-ui/core'
import RangeTime from './RangeTime'
import _ from 'lodash'
const styles = theme => ({
   card: {
      height: '817px',
      padding: `0px ${theme.spacing.unit * 4}px ${theme.spacing.unit * 3}px`,
   },
   cardHeader: {
      padding: ` ${theme.spacing.unit * 3}px ${theme.spacing.unit * 4}px`,
   }
})

class CardWorkingTime extends BaseView {
   constructor(props) {
      super(props)
      this.changeTypeSetting = this.changeTypeSetting.bind(this)
      this.state = { 
         type: 'A',
         reload: false,
         data: [],
      }
      this.onHandleChange = this.onHandleChange.bind(this)
   }

   onHandleChange(index, value, name){
      let { data } = this.state || []
      if(data.length === 3){
         data[index][name] = value
         this.setState({ data: data})
         this.setState({ reload: !this.state.reload })
      }
   }

   componentWillReceiveProps(nextProps){
      let data = _.get(nextProps, 'data', [])
      this.setState({ data: data })
      this.setState({ reload: !this.state.reload})
   }

   changeTypeSetting(event) {
      this.setState({ type: event.target.value })
      this.setState({ reload: !this.state.reload })
   }

   renderTime = () => {
      let { data = [] } = this.state
      if (data.length === 0) data = [{ type: 'A' }, { type: 'B' }, { type: 'C' }]
      return data.map((item, index) => {
         let isShow = this.state.type === item.type
         return <div key={index} style={{ display: isShow ? 'block' : 'none' }}>
            <TextField 
               name={`time[${index}]['type']`} 
               type="hidden" value={item.type} 
               onChange={ (value) => this.onHandleChange(index, value, 'type')}
            />
            <RangeTime
               formLabelText={I18n.t('Label.overTimeBefore')}
               fromProps={{
                  name: `time[${index}]['overTimeBeforeStart']`,
                  value: item.overTimeBeforeStart,
                  onChange: (value) => this.onHandleChange(index, value, 'overTimeBeforeStart')
               }}
               toProps={{
                  name: `time[${index}]['overTimeBeforeEnd']`,
                  value: item.overTimeBeforeEnd,
                  onChange: (value) => this.onHandleChange(index, value, 'overTimeBeforeEnd')
               }}
            />
            <RangeTime
               formLabelText={I18n.t('Label.workingTime')}
               fromProps={{
                  name: `time[${index}]['workingtimeStart']`,
                  value: item.overTimeBeforeEnd,
                  disabled: true
               }}
               toProps={{
                  name: `time[${index}]['workingTimeEnd']`,
                  value: item.workingTimeEnd,
                  onChange: (value) => this.onHandleChange(index, value, 'workingTimeEnd')
               }}
            />
            <RangeTime
               formLabelText={I18n.t('Label.noonBreak')}
               fromProps={{
                  name: `time[${index}]['noonBreakStart']`,
                  value: item.noonBreakStart,
               }}
               toProps={{
                  name: `time[${index}]['noonBreakEnd']`,
                  value: item.noonBreakEnd,
               }}
            />
            <RangeTime
               formLabelText={I18n.t('Label.overTime')}
               fromProps={{
                  name: `time[${index}]['overTimeStart']`,
                  value: item.workingTimeEnd,
                  disabled: true
               }}
               toProps={{
                  name: `time[${index}]['overTimeEnd']`,
                  value: item.overTimeEnd,
                  onChange: (value) => this.onHandleChange(index, value, 'overTimeEnd')
               }}
            />
            <RangeTime
               formLabelText={I18n.t('Label.nightTime')}
               disabledError={true}
               fromProps={{
                  name: `time[${index}]['overTimeNightStart']`,
                  value: item.overTimeEnd,
                  disabled: true,
               }}
               toProps={{
                  name: `time[${index}]['overTimeNightEnd']`,
                  disabled: true,
                  value: this.state.overTimeBeforeStart || item.overTimeBeforeStart
               }}
            />
         </div>
      })
   }

   render() {
      const { classes } = this.props
      return (
         <Card>
            <CardHeader
               title={I18n.t("Label.settingWorkingTime")}
               className={classes.cardHeader}
            />
            <CardContent className={classes.card}>
               <FormControl component="fieldset">
                  <FormLabel component="legend">{I18n.t("Exception.typeSettingTime")}</FormLabel>
                  <RadioGroup
                     aria-label="position"
                     name="radio"
                     value={this.state.type}
                     onChange={(value) => this.changeTypeSetting(value)}
                     row
                  >
                     <FormControlLabel
                        value="A"
                        control={<Radio color="primary" />}
                        label="A"
                        labelPlacement="end"
                     />
                     <FormControlLabel
                        value="B"
                        control={<Radio color="primary" />}
                        label="B"
                        labelPlacement="end"
                     />
                     <FormControlLabel
                        value="C"
                        control={<Radio color="primary" />}
                        label="C"
                        labelPlacement="end"
                     />
                  </RadioGroup>
               </FormControl>
               <br />
               {this.renderTime()}
            </CardContent>
            {/* <CardActions className={classes.card}>
                    <Button size="small" color="primary" type="submit" variant='contained'>
                        {I18n.t("Button.submit")}
                    </Button>
                </CardActions> */}
         </Card>
      )
   }
}

export default withStyles(styles)(CardWorkingTime);
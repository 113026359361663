import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List'
import Grid from '@material-ui/core/Grid'
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import { withStyles } from '@material-ui/core/styles';
import sidebar from '../../config/sidebar'
import routes from 'config/routes'
import permission from 'helpers/permission'
import Divider from '@material-ui/core/Divider';
import { NavLink, withRouter } from 'react-router-dom';

const styles = theme => ({
   root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
   },
   nested: {
      paddingLeft: theme.spacing.unit * 4,
   },
   listItem: {
      margin: "10px 14px",
      borderRadius: "3px",
      width: "auto",
      transition: "all 300ms linear",
   },
   listItemTextPrimary: {
      fontSize: '15px',
      fontWeight: 300,
      color: '#000'
   },
   listItemTextRoot: {
      padding: "0px"
   },
   listItemActive: {
      '&:hover': {
         color: '#fff !important',
         background: theme.palette.primary.main,
      },
      color: '#fff !important',
      background: theme.palette.primary.main,
      boxShadow: "0 12px 20px -10px rgba(100, 181, 246, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(100, 181, 246, 0.2)"
   },
   listItemTextPrimaryActive: {
      fontWeight: 400,
      color: '#fff'
   },
   listItemIconActive: {
      color: '#fff'
   },
});

class Sidebar extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         expand: {
         }
      }
   }
   handleClick = () => {
      this.setState(state => ({ open: !state.open }));
   }

   findRouteByName(routeName) {
      return routes.filter(route => route.name === routeName)[0]
   }

   renderSubMenu(menuList, key) {
      const { classes } = this.props;
      let result = []
      let index = 0;
      for (let menu of menuList) {
         result.push(this.renderMenuLink(menu, index++, classes.nested))
      }
      return (
         <Collapse 
         in={this.state.expand[key]} 
         timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
               {result}
            </List>
         </Collapse>
      )
   }

   renderMenuLink(menu, key, classesDefault) {
      const { classes, route } = this.props
      let isActive = (route.sidebarName === menu.name)

      let menuRoute = undefined
      if(menu.route === undefined) {
         menuRoute = this.findRouteByName(menu)
      } else {
         menuRoute = this.findRouteByName(menu.route)
      }
      
      if (!menuRoute && !menu.path) {
         throw Error(`Not found Route: menu.route in config/routes.jsx`)
      }

      menuRoute = menuRoute || {} //handle undefined
      let roleRequired = menuRoute.role
      if (!permission.hasPermission(roleRequired)) {
         return
      }

      let path = menuRoute.path || menu.path
      let title = menuRoute.title || menu.title
      let link = menuRoute.link || menu.link
      let name = menuRoute.name || menu.name

      let WORKING_LOAD_MANAGEMENT_URL = ""
      if(link && link !== undefined && typeof link === 'string') {
         WORKING_LOAD_MANAGEMENT_URL = process.env.REACT_APP_WORKING_LOAD_PRODUCTION_URL
         if (process.env.NODE_ENV === `development` || ["localhost", "time-management.javis.vn", "time-wlm-management.javis.vn"].includes(window.location.hostname)) {
            WORKING_LOAD_MANAGEMENT_URL = process.env.REACT_APP_WORKING_LOAD_STAGING_URL
         }
      }

      return (
         <Grid key={name + key}>
            {
               link && link !== undefined && typeof link === 'string'
               ?
               <ListItem
                  button
                  onClick={() => { window.location = `${WORKING_LOAD_MANAGEMENT_URL}${link}` }}
                  key={name + key}
                  className={`${classesDefault} ${classes.listItem} ${isActive ? classes.listItemActive : ''}`}
               >
                  <ListItemIcon classes={{ root: `${classes.listItemIcon} ${isActive ? classes.listItemIconActive : ''}` }} >
                     {menu.icon || <StarBorder />}
                  </ListItemIcon>
                  <ListItemText
                     primary={title}
                     classes={{
                        root: `${classes.listItemTextRoot}`,
                        primary: `${classes.listItemTextPrimary} ${isActive ? classes.listItemTextPrimaryActive : ''}`
                     }}
                  />
               </ListItem>
               :
               <ListItem
                  button
                  component={NavLink}
                  to={path}
                  key={name + key}
                  className={`${classesDefault} ${classes.listItem} ${isActive ? classes.listItemActive : ''}`}
               >
                  <ListItemIcon classes={{ root: `${classes.listItemIcon} ${isActive ? classes.listItemIconActive : ''}` }} >
                     {menu.icon || <StarBorder />}
                  </ListItemIcon>
                  <ListItemText
                     primary={title}
                     classes={{
                        root: `${classes.listItemTextRoot}`,
                        primary: `${classes.listItemTextPrimary} ${isActive ? classes.listItemTextPrimaryActive : ''}`
                     }}
                  />
               </ListItem>
            }   
         </Grid>
      )
   }

   renderMenuWithChildren(menu, key) {
      const { classes } = this.props
      let isShow = this.state.expand[key] == null ? false : this.state.expand[key]

      return (
         <div key={key} >
            <ListItem
               button
               onClick={() => { this.setState({ expand: { ...this.state.expand, [key]: !isShow } }) }}
               className={classes.listItem}
            >
               <ListItemIcon className={classes.listItemIcon}>
                  {menu.icon || <StarBorder />}
               </ListItemIcon>
               <ListItemText
                  primary={menu.title}
                  classes={{ root: classes.listItemTextRoot, primary: classes.listItemTextPrimary }}
               />
               {isShow ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {this.renderSubMenu(menu.children, key)}
         </div>
      )
   }

   renderMenu(sidebar, indexGroup) {
      let result = []

      for (let menu of sidebar) {
         if (menu.children && menu.children.length > 0) {
            result.push(this.renderMenuWithChildren(menu, indexGroup))
         } else {
            result.push(this.renderMenuLink(menu, indexGroup))
         }
      }
      return result
   }

   render() {
      let permissions = JSON.parse(localStorage.user).permissions || []
      let convertSidebar = []
      sidebar.map((item, index) => {
         let count = 1
         let check = 1
         let checkConstruction1 = 1
         let checkConstruction2 = 1
         for (let xx of permissions) {
            switch (xx) {
               case 'in_out': // check in / out
                  if ([
                     'worker',
                     'calendar',
                     'report',
                     'timekeeping.Edit'
                  ].indexOf(item.name) > -1) {
                     convertSidebar.push(item)
                  }
                  break
               // case 'sort_user': // construction
               //    if ((checkConstruction2 === 1 && item.name === 'construction') || (checkConstruction2 === 1 && item.name === 'construction_hour')) {
               //       checkConstruction1 = 0
               //       convertSidebar.push(item)
               //    }
               //    break
               // case 'mana_construction': // construction, bỏ profit, sort_user
               //    if ((checkConstruction1 === 1 && item.name === 'construction') || (checkConstruction1 === 1 && item.name === 'construction_hour')) {
               //       // if (checkConstruction1 === 1 && item.name === 'construction') {
               //       checkConstruction2 = 0
               //       convertSidebar.push(item)
               //    }
               //    break
               // case 'sort_user': // construction
               //    if ((checkConstruction2 === 1 && item.name === 'construction')) {
               //       checkConstruction1 = 0
               //       convertSidebar.push(item)
               //    }
               //    break
               case 'monthly_report_management':
                  if (item.name === 'monthly_report_management') {
                     convertSidebar.push(item)
                  }
                  break
               case 'mana_monthly_reports':
                  if (item.name === 'monthlyReport') {
                     convertSidebar.push(item)
                  }
                  break
               case 'sort_user': 
               case 'mana_construction': 
                  if (item.name === 'construction') {
                     checkConstruction2 = 0
                     convertSidebar.push(item)
                  }
                  break
               case 'construction_hour': 
                  if (item.name === 'construction_hour') {
                     convertSidebar.push(item)
                  }
                  break
               case 'mana_user': // create, edit, delete, role, positions /users, /roles, /positions
                  if (item.name === 'user' || item.name === 'role' || item.name === 'position' || item.name === 'area') {
                     convertSidebar.push(item);
                  }
                  break;
               case 'setting_time':
                  if (item.name === 'setting') {
                     convertSidebar.push(item);
                  }
                  break;
               case 'history': // lịch sử
                  if (item.name === 'history') {
                     convertSidebar.push(item)
                  }
                  break
               case 'mana_money': // quản lý phí lao động  /labors
                  if (item.name === 'labor') {
                     convertSidebar.push(item)
                  }
                  break
               case 'mana_timekeeping': // quản lý chấm công
                  if (item.name === 'timekeeping' || item.name === 'hour') {
                     convertSidebar.push(item)
                  }
                  break
               case 'mana_holiday': // quản ly ngày nghỉ /dayoff
                  if (item.name === 'dayoff') {
                     convertSidebar.push(item)
                  }
                  break
               case 'allow_level_1':
                  count = 0
                  if (check === 1 && item.name === 'request') {
                     convertSidebar.push(item)
                  }
                  break
               case 'allow_level_2':
                  check = 0
                  if (count === 1 && item.name === 'request') {
                     convertSidebar.push(item)
                  }
                  break
               case 'myhour':
                  if (item.name === 'myhour') {
                     convertSidebar.push(item)
                  }
                  break
               case 'material_browsing':
                  if ([
                     'calendar_booking',
                     'list_product',
                     'revenue',
                     'assetManagementSideBar'
                  ].indexOf(item.name) > -1) {
                     convertSidebar.push(item)
                  }
                  break
               case 'material_management':
                  if ([
                     'calendar_booking',
                     'list_product',
                     'revenue',
                     'printing',
                     'damageNotDefine',
                     'assetManagementSideBar'
                  ].indexOf(item.name) > -1) {
                     convertSidebar.push(item)
                  }
                  break
               case 'manage_estimates':
                  if (item.name == 'wlmEstimate') {
                     convertSidebar.push(item)
                  }
                  break
               case 'manage_construction_data': 
                  if (['wlmLoad', 'wlmWorkload'].includes(item.name)) {
                     convertSidebar.push(item)
                  }
                  break
               case 'manage_team_members_construction': 
                  if (item.name == 'wlmTeam') {
                     convertSidebar.push(item)
                  }
                  break
               default:
                  break
            }
         }
         if (permissions.length === 12 && item.name === 'delete') {
            convertSidebar.push(item)
         }
         if (['wlmConstruction', 'wlmWorkerCharges', 'wlmTeamManagements', 'wlmWorkLoadSideBar'].includes(item.name)) {
            convertSidebar.push(item)
         }
      })

      //xét các group sidebar
      let groups = [
         ['worker'],
         ['calendar', 'myhour', 'report', 'monthlyReport', 'timekeeping.Edit'],
         ['construction', 'request', 'construction_hour'],
         //---- working load -----
         ['wlmWorkLoadSideBar', 'wlmConstruction', 'wlmWorkerCharges', 'wlmTeamManagements', 'wlmWorkload', 'wlmEstimate', 'wlmLoad', 'wlmTeam'],
         //---- asset management --
         ['assetManagementSideBar', 'calendar_booking', 'list_product', 'revenue', 'printing', 'damageNotDefine'],
         ['history', 'hour', 'labor', 'timekeeping', 'monthly_report_management'],
         ['dayoff'],
         ['user', 'role', 'position', 'area'],
         ['setting', 'delete']
      ]
      
      convertSidebar.map((sidebar) => {
         groups.map((group, i) => {
            let index = group.indexOf(sidebar.name)
            if (index !== -1) {
               groups[i][index] = sidebar
            }
         })
      })
      let permit_groups = groups.map(group => {
         return group.filter(item => typeof item === 'object')
      })
      //lấy các sidebar ra
      return (
         <List>
            {
               permit_groups.map((item, index) => {
                  if (!item.length) return false
                  if (permit_groups[index + 1]) {
                     return <div key={index}>
                        {this.renderMenu(item, index)}
                        <Divider style={{ height: '2px', marginTop: '8px' }} variant='fullWidth' />
                     </div>
                  } else {
                     return this.renderMenu(item, index)
                  }

               })
            }

         </List>
      )
   }
}

export default withStyles(styles)(withRouter(Sidebar))
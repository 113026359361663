import BaseAction from './BaseAction'
/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
class MonthlyReport extends BaseAction {
  get actions() {
    return {
      fetchAll: {
        method: 'get',
        url: '/api/v1/monthlyReports',
        type: 'MonthlyReport.fetchAll'
      },
      fetch: {
        method: 'get',
        url: '/api/v1/monthlyReports/:_id',
        type: 'MonthlyReport.fetch'
      },
      submitRequest: {
        method: 'post',
        url: '/api/v1/monthlyReports/submitRequest',
        type: 'MonthlyReport.submitRequest'
      },
      getApprovePaidHalf: {
        method: 'get',
        url: '/api/v1/monthlyReports/getApprovePaidHalf',
        type: 'MonthlyReport.getApprovePaidHalf'
      }
    }
  }
}
/*
 * bắt buộc gọi hàm export()
 */
export default MonthlyReport.export()

import React from 'react';
import View from 'views/Hour/Index'
import HourAction from '../../actions/HourAction';
import BaseContainer, { selector } from 'containers/BaseContainer';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n';
import moment from 'moment'
/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
class Index extends BaseContainer {
    constructor(props) {
        super(props)
        this.refTable = null
        this.onFetchData = this.onFetchData.bind(this)
        this.onRefTable = this.onRefTable.bind(this)
        this.state = {
            currentPage: 0,
            pageSize: 20,
            filters: [],
            sorting: []
        }

        this.query = {
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD')
        }
        this.storePrintData();
    }

    storePrintData() {
        let { startDate, endDate } = this.query;
        startDate = moment(startDate).format('YYYY/MM/DD');
        endDate = moment(endDate).format('YYYY/MM/DD')
        localStorage.setItem("print_hours", JSON.stringify({startDate, endDate}));
    }

    componentDidUpdate(prevProps) {
        //example for check props change
        // if(prevProps.x !== this.state){
        // //props x changed
        // } 
    }   

    componentWillUnmount() {
        localStorage.removeItem("print_hours");
    }

    //filter date 
    onSubmitDate = (dateRange) => {
        this.onFetchData(dateRange)
    }

    componentDidMount(){
        localStorage.setItem('userCalendar', JSON.parse(localStorage.user)._id)
        localStorage.setItem('dateCalendar', new Date())  
    }

    onFetchData(state) {
        //thêm date vào query để gửi lên server
        this.query = {
            ...this.query,
            ...state
        }
        this.storePrintData();
        this.props.dispatch(HourAction.fetchAll({
            ...this.query,
            pageSize: -1
        })).then(data => {
            if (data.error) {
                this.notify(`Response: [${data.error.status}] ${data.error.message}`, 'error')
            }
        })
    }

    onRefTable(ref) {
        this.refTable = ref
    }

    render() {
        return (
            <View
                onFetchData={this.onFetchData}
                onRefTable={this.onRefTable}
                data={this.props.data}
                onSubmitDate={this.onSubmitDate}
                is60Time={this.is60Time}
            />
        )
    }
}


const mapStateToProps = state => {
    return {
        data: selector(state, "hour.list", {}),
    }
}

export default withRouter(connect(mapStateToProps)(Index))
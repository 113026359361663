import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { I18n } from "react-redux-i18n";
import BaseView from "views/BaseView";
import SelectField, { Option } from "components/Forms/SelectField";
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
} from "@material-ui/core";
import { TextField, Validation } from "components/Forms/";
const styles = (theme) => ({
  cardContainer: {
    marginTop: `${theme.spacing.unit * 3}px`,
  },
  card: {
    padding: `0px ${theme.spacing.unit * 4}px ${theme.spacing.unit * 3}px`,
  },
  cardHeader: {
    padding: ` ${theme.spacing.unit * 3}px ${theme.spacing.unit * 4}px`,
  },
});

const months = Array.from({ length: 12 }, (_, i) => ({
  value: String(i + 1),
  label: i + 1,
}));

class CardRound extends BaseView {
  render() {
    const { classes, data } = this.props;

    return (
      <Card className={classes.cardContainer}>
        <CardHeader
          className={classes.cardHeader}
          title={I18n.t("Label.compensatoryAvailable")}
        />
        <CardContent className={classes.card}>
          <Grid container spacing={32}>
            <Grid item xs={6}>
              <SelectField
                fullWidth
                name="compensatoryMonth"
                label={I18n.t("Input.compensatoryAvailable")}
                value={String(data.compensatoryMonth)}
              >
                {months.map((month) => (
                  <Option key={month.value} value={month.value}>
                    {month.label}
                  </Option>
                ))}
              </SelectField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(CardRound);

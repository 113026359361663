import BaseAction from './BaseAction'
/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
class DeleteAction extends BaseAction {
    get actions() {
        return {
            delete: {
                method: 'delete',
                url: '/api/v1/deletedb',
                type: 'Delete.deletedb'
            }
        }
    }
}
/*
 * bắt buộc gọi hàm export()
 */
export default DeleteAction.export()
import React from 'react';
import View from 'views/Timekeeping/Index'
import HourAction from '../../actions/HourAction'
import UserAction from '../../actions/UserAction'
import TimekeepingAction from '../../actions/TimekeepingAction'
import ReportAction from '../../actions/ReportAction'
import { I18n } from 'react-redux-i18n'
import BaseContainer, { selector } from 'containers/BaseContainer'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
/**
 * Files are automatically generated from the template.
 * 
 */
class Index extends BaseContainer {
    constructor(props) {
        super(props)
        this.refTable = null
        this.onFetchData = this.onFetchData.bind(this)
        this.onRefTable = this.onRefTable.bind(this)
        this.query = {
            startDate: moment().startOf('month').format('YYYY-MM-DD'),  // lấy ngày đầu tháng của ngày hiện tại
            endDate: moment().endOf('month').format('YYYY-MM-DD'),  // lấy ngày cuối tháng của ngày hiện tại
            _id: JSON.parse(localStorage.user)._id,
            time_keeping: true,
            unique: true,
            pageSize: -1
        }
        this.onSubmitDistanceFuel = this.onSubmitDistanceFuel.bind(this)
    }

    componentDidMount() {
        localStorage.setItem('userCalendar', JSON.parse(localStorage.user)._id)
        localStorage.setItem('dateCalendar', new Date())
        this.props.dispatch(UserAction.fetchAll({ pageSize: -1, time_keeping: true })).then(data => {
            if (data.error) {
                this.notify(`Response: [${data.error.status}] ${data.error.message}`, 'error')
            }
        })
    }

    componentDidUpdate(prevProps) {
        //example for check props change
        /* if(prevProps.x !== this.props.x){
        //props x changed
        } */
    }

    storePrintData() {
        const { data = [] } = this.props.users || {}
        let { startDate, _id } = this.query;
        const user = data.find(item => item._id === _id) || {}
        let month = startDate = moment(startDate).format('YYYY.MM');
        localStorage.setItem("print_timekeepings", JSON.stringify({ month, code: user.code, name: user.name }));
    }

    componentWillUnmount() {
        localStorage.removeItem("print_timekeepings");
    }

    onFetchData(state) {
        let checkTimeZoneFE = localStorage.getItem('frontTimeZone')
        this.query = {
            ...this.query,
            ...state,
            checkTimeZoneFE: checkTimeZoneFE
        }
        this.props.dispatch(HourAction.fetchAll(this.query))
        this.props.dispatch(TimekeepingAction.getApprovePaidHalf(this.query))
    }

    onRefTable(ref) {
        this.refTable = ref
    }

    onSubmitDistanceFuel(data){
        this.props.dispatch(TimekeepingAction.edit({ _id: data._id, ...data }))
        .then(data => {
            if (!data.error) {
                this.notify(I18n.t('Message.createDataSuccess'))
                this.goto(`/timekeepings`)
            } else {
                this.notify(`Response: [${data.error.status}] ${data.error.message}`, 'error')
            }
        })
    }

    onSubmit = (value) => {
        this.storePrintData()
        this.query = {
            ...this.query,
            ...value
        }
        this.props.dispatch(HourAction.fetchAll(this.query))
        this.props.dispatch(TimekeepingAction.getApprovePaidHalf(this.query))
    }

    render() {
        const { data, users, requestAppPaidHalf } = this.props
        this.storePrintData()
        
        return (
            <View
                onFetchData={this.onFetchData}
                onRefTable={this.onRefTable}
                data={data || []}
                is60Time={data.is60Time}
                users={users.data || []}
                requestAppPaidHalf={requestAppPaidHalf}
                onSubmit={this.onSubmit}
                onSubmitDistanceFuel={this.onSubmitDistanceFuel}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        data: selector(state, "hour.list", []),
        users: selector(state, "user.list", []),
        requestAppPaidHalf: selector(state, "timekeeping.data", [])
    }
}

export default withRouter(connect(mapStateToProps)(Index))
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';
import { DateTimeField } from 'components/Forms';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import AutoCompleteField, {
  Option as OptionAuto,
} from 'components/Forms/AutoCompleteField';
import {} from '@material-ui/core';
import BaseView from 'views/BaseView';
import PaperFade from 'components/Main/PaperFade';
import { I18n } from 'react-redux-i18n';
import { Button, Grid, Icon, Tooltip, IconButton } from '@material-ui/core';
import _ from 'lodash';
import './indexMobile.css';

const styles = (theme) => ({
  paper: {
    [theme.breakpoints.down('sm')]: {
      // minHeight: '100%',
      // overflowY: 'scroll',
      overflowX: 'hidden',
      height: 'calc(100vh - 56px)',
      width: '100%',
    },
  },
  fixScoll: {
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  fixTop: {
    marginTop: '-40px',
  },
});

let filters = [];
class Index extends BaseView {
  constructor(props) {
    super(props);
    this.state = {
      dataItem: null,
      startDate: '',
      endDate: '',
    };
    this.onCancel = this.onCancel.bind(this);
    this.onChangeArea = this.onChangeArea.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
  }

  onChangeArea(value) {
    let element = {
      columnName: 'Area.name',
      value: value.label,
      operation: 'contains',
      dataType: 'text',
    };
    if (filters.length == 0) {
      filters.push(element);
    } else if (filters.length == 1) {
      if (filters[0].dataType == 'text') {
        filters[0] = element;
      }
      if (filters[0].dataType == 'date') {
        filters.push(element);
      }
    } else if (filters.length == 2) {
      if (filters[0].dataType == 'text') {
        filters[0] = element;
      }
      if (filters[1].dataType == 'text') {
        filters[1] = element;
      }
    }
    this.props.filterData(filters);
  }

  onChangeDate(value, name) {
    if (name == 'startDate') {
      this.setState({ startDate: value });
      let element = {
        columnName: 'date',
        operation: 'daterange',
        dataType: 'date',
        value: {
          startDate: moment(value).startOf('day'),
          endDate: moment(this.state.endDate).endOf('day'),
          key: 'dateRange',
        },
      };
      if (filters.length == 0) {
        filters.push(element);
      } else if (filters.length == 1) {
        if (filters[0].dataType == 'date') {
          filters[0] = element;
        }
        if (filters[0].dataType == 'text') {
          filters.push(element);
        }
      } else if (filters.length == 2) {
        if (filters[0].dataType == 'date') {
          filters[0] = element;
        }
        if (filters[1].dataType == 'date') {
          filters[1] = element;
        }
      }
      if (this.state.endDate) {
        this.props.filterData(filters);
      }
    }
    if (name == 'endDate') {
      this.setState({ endDate: value });
      let element = {
        columnName: 'date',
        operation: 'daterange',
        dataType: 'date',
        value: {
          startDate: moment(this.state.startDate).startOf('day'),
          endDate: moment(value).endOf('day'),
          key: 'dateRange',
        },
      };
      if (filters.length == 0) {
        filters.push(element);
      } else if (filters.length == 1) {
        if (filters[0].dataType == 'date') {
          filters[0] = element;
        }
        if (filters[0].dataType == 'text') {
          filters.push(element);
        }
      } else if (filters.length == 2) {
        if (filters[0].dataType == 'date') {
          filters[0] = element;
        }
        if (filters[1].dataType == 'date') {
          filters[1] = element;
        }
      }
      if (this.state.startDate) {
        this.props.filterData(filters);
      }
    }
  }

  onCancel(data) {
    let { onSubmit } = this.props;
  }

  gotoDetail(status, _id) {
    this.goto(`/reports/${_id}`);
    if (status === false) {
      this.props.onSubmit(_id);
    }
  }

  createReport = () => {
    localStorage.removeItem('constructionId');
    localStorage.removeItem('today');
    localStorage.removeItem('title');
    localStorage.removeItem('contractor');
    localStorage.removeItem('construction_unit');
    localStorage.removeItem('problem_report');
    localStorage.removeItem('areaId');
    localStorage.removeItem('warning');
    localStorage.removeItem('titleContent');
    localStorage.removeItem('content');
    localStorage.setItem('backReport', 'backReport');
    this.goto(`/reports/create`);
  };

  render() {
    const { classes, onSubmit } = this.props;
    let area = this.props.area || [];
    let data = _.get(this.props.data, 'data', []) || [];
    return (
      <PaperFade className={classes.paper}>
        <Grid container spacing={32} style={{width: '100%', margin: 0}}>
          <Grid item xs={9}></Grid>
          <Grid item xs={3} style={{textAlign: 'right'}}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => this.createReport()}
            >
              {I18n.t('Button.create')}
            </Button>
          </Grid>
          <Grid item xs={12} lg={12} className={classes.fixTop}>
            <AutoCompleteField
              key='3'
              fullWidth
              select
              label={I18n.t('Input.area.name')}
              name='area'
              onChange={(value) => this.onChangeArea(value)}
              isMulti={false}
              isClearable={false}
            >
              {area.map((item, index) => (
                <OptionAuto key={index} value={item._id} showCheckbox={false}>
                  {item.name}
                </OptionAuto>
              ))}
            </AutoCompleteField>
          </Grid>
          <Grid item xs={6} lg={6} className={classes.fixTop}>
            <DateTimeField
              fullWidth
              clearable={true}
              label={I18n.t('Common.from')}
              name='startDate'
              format={'MM/DD'}
              onChange={(value) => this.onChangeDate(value, 'startDate')}
              showTime={false}
            />
          </Grid>
          <Grid item xs={6} lg={6} className={classes.fixTop}>
            <DateTimeField
              fullWidth
              format={'MM/DD'}
              clearable={true}
              label={I18n.t('Common.to')}
              name='endDate'
              onChange={(value) => this.onChangeDate(value, 'endDate')}
              showTime={false}
            />
          </Grid>
        </Grid>
        <div className='report-smartphone'>
          {data.length ? (
            data.map((item, index) => {
              let date = _.get(item, 'date', null);
              let subject = _.get(item, 'subject', null);
              let _id = _.get(item, '_id', '');
              let warning = _.get(item, 'warning', null);
              let listUserSeen = _.get(item, 'listUserSeen', []);
              let status = false;
              for (let e of listUserSeen) {
                if (e === JSON.parse(localStorage.user)._id) {
                  status = true;
                }
              }
              return (
                <div key={index} className='report-item'>
                  <Grid
                    container
                    spacing={1}
                    justify='center'
                    alignItems='center'
                  >
                    <Grid item xs={7} spacing={0} style={{fontWeight: 'bold', paddingLeft: '20px'}}>
                      {moment(date).format('YYYY/MM/DD')}
                    </Grid>
                    <Grid item xs={5} spacing={0} style={{textAlign: 'right'}}>
                      {warning &&
                        <Tooltip title={I18n.t('Input.warning')} key='warning'>
                          <IconButton style={{ color: 'red', padding: '0 10px 0px 0px' }}>
                            <Icon>warning</Icon>
                          </IconButton>
                        </Tooltip>
                      }
                      <Link
                        to={{ pathname: `/reports/${_id}` }}
                        style={{ textDecoration: 'none' }}
                      >
                        <Button
                          onClick={() => this.gotoDetail(status, _id)}
                          className={classes.button}
                          color='primary'
                          variant='contained'
                          size='small'
                        >
                          {I18n.t('Button.detail')}
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                  <div style={{display: 'flex', marginTop: '10px'}}>
                    <div style={{minWidth: '14px'}}>
                      {!status &&
                        <Icon
                          style={{ color: '#476b6b', fontSize: '15px' }}
                          size='small'
                        >
                          fiber_manual_record
                        </Icon>
                      }
                    </div>
                    <div style={{fontSize: '15px', marginLeft: '5px'}}>{item.subject}</div>
                  </div>
                </div>
              );
            })
          ) : (
            <center>{I18n.t('Exception.noData')}</center>
          )}
        </div>
      </PaperFade>
    );
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Index));

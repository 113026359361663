import React, {Component} from "react";
import { FixedSizeList as List } from "react-window";

const height = 35;
class OptionList extends Component {
    render() {
        const { options, children, maxHeight, maxWidth, getValue } = this.props;

        const [value] = getValue();
        const initialOffset = options.indexOf(value) * height;

        return (
            <List
                height={maxHeight ? maxHeight : 0}
                width={maxWidth}
                itemCount={children.length}
                itemSize={height}
                initialScrollOffset={initialOffset}
            >
                {
                ({ index, style }) => 
                    {  
                    style = {
                        ...style,
                        height: '30px',
                        overflow: 'hidden'
                    }
                         
                    return <div style={style}>{ children[index] }</div>
                    }
                }
            </List>
        );
    }
}

export default OptionList

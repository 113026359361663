import React from 'react';
import View from 'views/DayOff/Index';
import BaseContainer, { selector } from 'containers/BaseContainer';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import UserAction from 'actions/UserAction';
import HourAction from 'actions/HourAction';
import DayOffAction from 'actions/DayOffAction';
import _ from 'lodash';

class Index extends BaseContainer {
  constructor(props) {
    super(props);
    this.refTable = null;
    this.onFetchData = this.onFetchData.bind(this);
    this.onRefTable = this.onRefTable.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.state = {
      data: null,
    };
  }

  onRefTable(ref) {
    this.refTable = ref;
  }

  componentDidMount() {
    localStorage.setItem('userCalendar', JSON.parse(localStorage.user)._id);
    localStorage.setItem('dateCalendar', new Date());
    this.onFetchData();
  }

  onFetchData(state) {
    let checkTimeZoneFE = localStorage.getItem('frontTimeZone');
    state = { ...state, checkTimeZoneFE: checkTimeZoneFE };
    state.pageSize = -1;
    this.props.dispatch(DayOffAction.fetchAll(state)).then((res) => {
      this.setState({ data: res.data.data });
    });
  }

  validateDayOff(dayOffCurrentInDB, dayOffDate) {
    let checkExist = dayOffCurrentInDB.filter(
      (dayOff) =>
        moment(dayOff.date).format('YYYY-MM-DD') ==
          moment(dayOffDate).format('YYYY-MM-DD') ||
        new Date(dayOff.date) > new Date(dayOffDate)
    );
    if (checkExist.length > 0) {
      return false;
    }
    return true;
  }

  onSubmit(values) {
    // Add Ngày nghỉ
    this.props.dispatch(DayOffAction.create(values)).then((result) => {
      if (!result.error) {
        //success
        this.notify(I18n.t('Message.editDataSuccess'));
        if (this.refTable) {
          this.refTable.onSelectionChange([]);
          this.refTable.onFetchData();
        }
      } else {
        //error
        // this.notify(`Response: [${result.error.status}] ${result.error.message}`, 'error')
        let err = result.error;
        switch (err.status) {
          case 400: {
            if (err.message === 'DayOff_Exist') {
              this.notify(I18n.t('Backend.DayOff.DayOff_Exist'), 'error');
            }
            if (err.message === 'Out_Of_DayOff') {
              this.notify(I18n.t('Backend.DayOff.Out_Of_DayOff'), 'error');
            }
            if (err.message === 'Out_Of_DayOff_By_Working_On_DayOff') {
              this.notify(
                I18n.t('Backend.DayOff.Out_Of_DayOff_By_Working_On_DayOff'),
                'error'
              );
            }
            if (err.message === 'Holiday_Warning') {
              this.notify(I18n.t('Backend.DayOff.Holiday_Warning'), 'error');
            }
            if (err.message === 'DayOff_Warning') {
              this.notify(I18n.t('Backend.DayOff.DayOff_Warning'), 'error');
            }
            break;
          }
          case 403: {
            if (err.message === 'No_Add_Permission') {
              this.notify(I18n.t('Backend.DayOff.No_Add_Permission'), 'error');
            }
            break;
          }
          case 404: {
            if (err.message === 'Type_Not_Exist') {
              this.notify(I18n.t('Backend.DayOff.Type_Not_Exist'), 'error');
            }
            break;
          }
          default:
            this.notify(
              `Response: [${result.error.status}] ${result.error.message}`,
              'error'
            );
        }
      }
    });
  }

  onDelete(data) {
    let checkTimeZoneFE = localStorage.getItem('frontTimeZone');
    this.props
      .dispatch(
        DayOffAction.delete({ ids: [data], checkTimeZoneFE: checkTimeZoneFE })
      )
      .then((result) => {
        if (!result.error) {
          //success
          this.notify(I18n.t('Message.editDataSuccess'));
          if (this.refTable) {
            this.refTable.onSelectionChange([]);
            this.refTable.onFetchData();
          }
        } else {
          //error
          // this.notify(`Response: [${result.error.status}] ${result.error.message}`, 'error')
          let err = result.error;
          switch (err.status) {
            case 403: {
              if (err.message === 'No_Del_Permission') {
                this.notify(
                  I18n.t('Backend.DayOff.No_Del_Permission'),
                  'error'
                );
              }
              break;
            }
            default:
              this.notify(`Response: [${err.status}] ${err.message}`, 'error');
          }
        }
      });
  }

  render() {
    if (this.state.data == undefined) return null;
    return (
      <div>
        <View
          onFetchData={this.onFetchData}
          onRefTable={this.onRefTable}
          onChooseDayOffChange={this.onChooseDayOffChange}
          onRadioDayOffTypeChange={this.onRadioDayOffTypeChange}
          onSubmit={this.onSubmit}
          onDelete={this.onDelete}
          data={this.state.data}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: selector(state, 'dayoff.list.data', []),
  };
};

export default withRouter(connect(mapStateToProps)(Index));

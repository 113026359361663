import BaseAction from './BaseAction'

class MenuToggleAction extends BaseAction {
    get actions() {
        return {}
    }

    toggleMenu({ status }) {
        return (dispatch) => {
            dispatch({
                type: "toggle_menu",
                data: { status: status }
            })
        }
    }
}

/**
 * bắt buộc gọi hàm export()
 */
export default MenuToggleAction.export()

import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Button, Grid } from "@material-ui/core";
import BaseView from "views/BaseView";
import PaperFade from "components/Main/PaperFade";
import { I18n } from "react-redux-i18n";
import MonthPicker from "components/Forms/MonthPicker";

const styles = (theme) => ({
	paper: {
		padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 4}px`,
	},
});

class Edit extends BaseView {
	constructor(props) {
		super(props);
		this.month = moment().format("YYYY-MM");
	}

	onChange = (data) => {
		let month = moment(data.startDate).format("YYYY-MM");
		this.month = month;
	};

	render() {
		const { classes } = this.props;
		return (
			<PaperFade showLoading={true} className={classes.paper}>
				<Grid
					container
					direction="row"
					justify="space-evenly"
					alignItems="flex-end"
				>
					<Grid item xs={12} lg={4}>
						<MonthPicker
							fullWidth
							label={I18n.t("Input.pickMonth")}
							name="selectField"
							datestart="2019-01-01"
							dateend={moment().format("YYYY-MM-DD")}
							onChange={this.onChange}
						/>
					</Grid>

					<Grid item xs={12} lg={4}>
						<Button
							variant="contained"
							color="primary"
							style={{ marginBottom: "8px" }}
							onClick={() =>
								this.goto(`/distance_fuel/update?month=${this.month}`)
							}
						>
							{I18n.t("Button.chose")}
						</Button>
					</Grid>
				</Grid>
			</PaperFade>
		);
	}
}

Edit.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Edit));

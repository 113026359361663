import { savePDF,
  //  PDFExport
} from '@progress/kendo-react-pdf';
// import { defineFont } from '@progress/kendo-drawing/pdf';

class DocService {
  createPdf = (html, name) => {
    savePDF(html, { 
      paperSize: 'A4',
      fileName: `${name}.pdf`,
      margin: 1,
      landscape: true,
      scale: 0.4
    })
  }
}

const Doc = new DocService();
export default Doc;

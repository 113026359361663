import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { I18n } from 'react-redux-i18n'
import BaseView from 'views/BaseView'
import { Card, CardContent, CardHeader, CardActions, Button } from '@material-ui/core'
import MultipleSelectField, { Option } from 'components/Forms/MultipleSelectField'
import { Grid } from '@material-ui/core'


const styles = theme => ({
    cardContainer: {
        marginTop: `${theme.spacing.unit * 3}px`
    },
    card: {
        padding: `0px ${theme.spacing.unit * 4}px ${theme.spacing.unit * 3}px`,
    },
    cardHeader: {
        padding: ` ${theme.spacing.unit * 3}px ${theme.spacing.unit * 4}px`,
    }
})

class CardDayInWeek extends BaseView {
    constructor(props) {
        super(props)
        this.state = {
            checkDataIn: [],
            checkDataOut: [],
            holidayInProvision: [
                { name: I18n.t("Common.monday"), value: 1 },
                { name: I18n.t("Common.tuesday"), value: 2 },
                { name: I18n.t("Common.wednesday"), value: 3 },
                { name: I18n.t("Common.thursday"), value: 4 },
                { name: I18n.t("Common.friday"), value: 5 },
                { name: I18n.t("Common.saturday"), value: 6 },
                { name: I18n.t("Common.sunday"), value: 0 },
            ]
        }
        this.onChange = this.onChange.bind(this)
        this.converDataHolidayIn = this.converDataHolidayIn.bind(this)
        this.converDataHolidayOut = this.converDataHolidayOut.bind(this)
    }

    onChange = (data, name) => {
        if (name == "holidayInProvision") {
            this.setState({ checkDataIn: data })
        }
        if (name == 'holidayOutProvision') {
            this.setState({ checkDataOut: data })
        }
    }

    converDataHolidayIn() {
        const { holidayInProvision, checkDataIn, checkDataOut } = this.state
        let converDataHolidayIn = []
        holidayInProvision.map((item, index) => {
            converDataHolidayIn.push(item)
            checkDataIn.map(element => {
                if (item.value === element) {
                    let daleteItem = item.value
                    let i = converDataHolidayIn.indexOf(daleteItem)
                    converDataHolidayIn.splice(i, 1)
                }
            })
        })
        return converDataHolidayIn
    }

    converDataHolidayOut() {
        const { holidayInProvision, checkDataIn, checkDataOut } = this.state
        let converDataHolidayOut = []
        holidayInProvision.map((item, index) => {
            converDataHolidayOut.push(item)
            checkDataOut.map(element => {
                if (item.value === element) {
                    let daleteItem = item.value
                    let i = converDataHolidayOut.indexOf(daleteItem)
                    converDataHolidayOut.splice(i, 1)
                }
            })
        })
        return converDataHolidayOut
    }

    render() {
        const { classes, data } = this.props
        const converDataHolidayIn = this.converDataHolidayIn()
        const converDataHolidayOut = this.converDataHolidayOut()

        return (
            <Card className={classes.cardContainer}>
                <CardHeader
                    className={classes.cardHeader}
                    title={I18n.t("Label.settingWeekHoliday")}
                />
                <CardContent className={classes.card}>
                    <Grid container direction="row" alignItems="flex-end" spacing={32}>
                        <Grid item xs={6}>
                            <MultipleSelectField
                                fullWidth
                                select
                                label={I18n.t("Input.holidayInProvision")}
                                name="holidayInProvision"
                                onChange={(value) => { this.onChange(value, "holidayInProvision") }}
                                value={data.holidayInProvision}
                            >
                                {
                                    converDataHolidayOut.map((item, index) => (
                                        <Option key={index} value={item.value} showCheckbox={true}>
                                            {item.name}
                                        </Option>
                                    ))
                                }
                            </MultipleSelectField>
                        </Grid>
                        <Grid item xs={6}>
                            <MultipleSelectField
                                fullWidth
                                select
                                label={I18n.t("Input.holidayOutProvision")}
                                name="holidayOutProvision"
                                onChange={(value) => { this.onChange(value, "holidayOutProvision") }}
                                value={data.holidayOutProvision}
                            >
                                {
                                    converDataHolidayIn.map((item, index) => (
                                        <Option key={index} value={item.value} showCheckbox={true}>
                                            {item.name}
                                        </Option>
                                    ))
                                }
                            </MultipleSelectField>
                        </Grid>
                    </Grid>
                </CardContent>
                {/* <CardActions className={classes.card}>
                    <Button size="small" color="primary" type="submit" variant='contained'>
                    {I18n.t("Button.submit")}
                    </Button>
                </CardActions> */}
            </Card>
        )
    }
}

export default withStyles(styles)(CardDayInWeek);
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { I18n } from "react-redux-i18n";
import BaseView from "views/BaseView";
import Calendar from "react-big-calendar";
// import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import moment from "moment";
import EventConstruction from "./components/event.construction";
import EventRequest from "./components/event.request";
import EventDayOff from "./components/event.dayoff";
import EventHoliday from "./components/event.holiday";
import CheckboxField from "components/Forms/CheckboxField";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import DrivingLength from "./components/driving.length";

import {
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogTitle,
	Button,
	Grid,
	Typography,
	Icon,
} from "@material-ui/core";
import AutoCompleteField, {
	Option as OptionAuto,
} from "components/Forms/AutoCompleteField";
import _ from "lodash";

const styles = (theme) => ({
	button: {
		marginTop: `${theme.spacing.unit * 3}px`,
	},
});
// const DragAndDropCalendar = withDragAndDrop(Calendar)
const localizer = Calendar.momentLocalizer(moment);

class Employee extends BaseView {
	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			events: [],
			event: [],
			statusCreate: false,
			open: false,
			checkboxId: "",
			checkboxStatus: "",
			changeConstruction: false,
			reload: false,
			arrConstruction: [],
			userId: JSON.parse(localStorage.user)._id,
			yearMonth: moment(new Date()).format("YYYY-MM"),
			showWorkingLog: false,
		};
		this.onEventDrop = this.onEventDrop.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.show = this.show.bind(this);
		this.onChange = this.onChange.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.selecConstruction = this.selecConstruction.bind(this);
	}

	onChange(values) {
		let dateCalendar = localStorage.getItem("dateCalendar");
		localStorage.setItem("userCalendar", values.value);
		this.setState({
			userId: values.value,
		});
		this.props.onSubmit({
			userId: values.value,
			yearMonth: moment(dateCalendar).format("YYYY-MM"),
		});
	}

	onNavigate(date) {
		let userCalendar = localStorage.getItem("userCalendar");
		localStorage.setItem("dateCalendar", date);
		this.props.onSubmit({
			userId: userCalendar,
			yearMonth: moment(date).format("YYYY-MM"),
		});
		this.setState({
			yearMonth: date,
		});
	}

	show() {
		this.setState({ open: true });
	}

	onCancel() {
		this.setState({ open: false });
	}

	componentDidUpdate(prevProps) {
		// khởi tạo events
		if (this.eventId === 0 && this.props.listHistory.length) {
			let i = 0;
			let listHistory = _.get(this.props, "listHistory", []);
			this.setState({
				events: [
					...listHistory.map((item) => {
						// item.id    = new Date(item.start).toISOString()
						item.start = new Date(item.start);
						item.end = new Date(item.start);
						// item.employees = item.employees
						return item;
					}),
				],
			});
			this.eventId = this.props.listHistory.length;
		}
	}

	getEventById(eventId) {
		//lấy dữ liệu 1 event theo ID
		return this.state.events.filter((event) => event.id === eventId)[0];
	}

	eventPropGetter = (event, start, end, isSelected) => {
		let code = _.get(event, "code", "");
		code = code.replace(/-/g, "");

		let style = { fontSize: "15px" };
		let type = _.get(event, "type", "");
		let keyword = _.get(event, "keyword", "");
		if (this.state.changeConstruction) {
			switch (type) {
				case "inOut":
					style = { ...style, backgroundColor: `#77b300` }; // đặt tên màu theo mã công trường Construction
					return { style, type: "button", x: 1 };
				default: // đặt tên màu theo mã công trường Construction
					style = { ...style, backgroundColor: `#666699` };
					return { style, type: "button", x: 1 };
			}
		} else {
			switch (type) {
				case "inOut":
					// style = { ...style, backgroundColor: `#${code}` } // đặt tên màu theo mã công trường Construction
					return { style, type: "button", x: 1 };
				case "request":
					if (parseInt(event.dataRequest[0].type) === 6) {
						style = event.dataRequest[0].status
							? { ...style, backgroundColor: `yellow`, color: "black" }
							: (style = { ...style, backgroundColor: `#019844` });
					} else if (parseInt(event.dataRequest[0].type) === 4) {
						style = event.dataRequest[0].status
							? { ...style, backgroundColor: `blue` }
							: (style = { ...style, backgroundColor: `#019844` });
					} else {
						style = event.dataRequest[0].status
							? { ...style, backgroundColor: `#E4007F` }
							: (style = { ...style, backgroundColor: `#019844` });
					}

					return { style, type: "button", x: 1 };
				case "dayOff":
					style = { ...style, backgroundColor: "#E4007F" }; // dayOff
					return { style, type: "button", x: 1 };
				case "holiday":
					style = { ...style, backgroundColor: "#ff6600" }; // dayOff
					return { style, type: "button", x: 1 };
				// default: // xét màu cho tung cong trường
				//     style = { ...style, backgroundColor: `#${code}` } // Construction
				//     return { style, type: 'button', x: 1 }
			}
		}
		if (code === "休日") {
			style = { ...style, backgroundColor: "#ff6600" };
			return { style, type: "button", x: 1 };
		}
	};

	updateEvent = (event) => {
		event = {
			id: event.id,
			start: event.start,
			end: event.end,
			employees: event.employees,
			title: event.employees.map((employee) => employee.label).join(", "),
		};
		if (!event.employees.length) return;
		if (!this.getEventById(event.id)) {
			//thêm event mới
			this.setState({ events: [...this.state.events, event] }, () => {
				this.props.onChangeSchedule(this.state.events);
			});
		} else {
			//update event cũ
			const { events } = this.state;
			for (let eventIndex in events) {
				if (events[eventIndex].id === event.id) {
					events[eventIndex] = { ...event };
				}
			}
			this.setState({ events: [...this.state.events] }, () => {
				this.props.onChangeSchedule(this.state.events);
			});
		}
	};

	deleteEvent = (event) => {
		const newEvents = this.state.events.filter(
			(existEvent) => existEvent.id !== event.id
		);
		this.setState({ events: [...newEvents] });
	};

	onEventDrop = ({ event, start, end }) => {
		const { events } = this.state;
		const idx = events.indexOf(event);
		const updatedEvent = { ...event, start, end };
		const nextEvents = [...events];
		nextEvents.splice(idx, 1, updatedEvent);
		this.setState({ events: nextEvents });
	};

	onEventResize = ({ event, start, end }) => {
		const { events } = this.state;
		const nextEvents = events.map((existingEvent) => {
			return existingEvent.id === event.id
				? { ...existingEvent, start, end }
				: existingEvent;
		});
		this.setState({ events: nextEvents });
	};

	componentDidMount() {
		let event = JSON.parse(localStorage.getItem("event")) || {};
		this.handleSelect(event);
	}

	onChangeCheckBox(_id, value, start) {
		this.setState({ checkboxId: _id, checkboxStatus: value });
		this.setState({ reload: !this.state.reload });
		let { arrConstruction } = this.state;
		if (value) {
			let element = {
				constructionId: _id,
				date: moment(start).format("YYYY-MM-DD"),
			};
			arrConstruction.push(element);
		} else {
			arrConstruction.map((item, index) => {
				let constructionId = _.get(item, "constructionId", "");
				let date = _.get(item, "date", "");
				if (_id == constructionId && start == date) {
					arrConstruction.splice(index, 1);
				}
			});
		}

		this.setState({ arrConstruction: this.state.arrConstruction });
	}

	handleSelect(event) {
		let keyword = _.get(event, "keyword", ""); // check sửa công trường
		if (this.showMore || keyword) {
			this.showMore = false;
			return false;
		}

		let title = _.get(event, "title", null);
		if (typeof title == "string") {
			localStorage.setItem("event", JSON.stringify(event));
		} else {
			localStorage.removeItem("event");
		}

		let start = _.get(event, "start", "");
		localStorage.setItem("dateRequest", start);
		localStorage.setItem("today", start);
		if (start) {
			let id = new Date(start).toISOString();
			let eventExist = this.getEventById(id);
			if (eventExist) {
				event = eventExist;
			} else {
				event.employees = [];
			}
			switch (event.type) {
				// case 'inOut':
				//     this.EventConstruction.show(event)
				//     break
				case "request":
					this.EventRequest.show(event);
					break;
				case "dayOff":
					this.EventDayOff.show(event);
					break;
				case "holiday":
					this.EventHoliday.show(event);
					break;
				default:
					let showWorkingLog = true;
					if (new Date(start) > new Date()) {
						showWorkingLog = false;
					}
					this.setState({
						event: event,
						open: true,
						showWorkingLog: showWorkingLog,
					});
					break;
			}
		}
	}

	setLocalStorage(_id) {
		localStorage.setItem("createRequest", "constructionRequest");
		localStorage.setItem("constructionId", _id);
		this.goto("/calendars/requests/create");
	}

	setCreateRequest() {
		localStorage.setItem("createRequest", "createRequest");
	}

	setShowWorkingLog() {
		this.EventConstruction.show(this.state.event);
	}

	deduplicate(arr) {
		// xóa các phần tử giống nhau trong arr
		let isExist = (arr, x) => {
			for (let i = 0; i < arr.length; i++) {
				if (arr[i] === x) return true;
			}
			return false;
		};

		let ans = [];
		arr.forEach((element) => {
			if (!isExist(ans, element)) ans.push(element);
		});
		return ans;
	}

	renderLink(_id, code) {
		code = code.replace(/-/g, "");
		return (
			<Typography color="inherit" onClick={() => this.setLocalStorage(_id)}>
				{code}
			</Typography>
		);
	}

	formatData(listHolidayContruction = []) {
		// this.props.data = this.props.listHistory
		let holiday = _.get(listHolidayContruction[0], "data", []);
		let { listConstruction, listUserDayOff, listWorkerRequests, listHistory } =
			this.props || []; // listHistory
		let events = [];
		let eventChange = [];
		let userCalendar = localStorage.getItem("userCalendar");
		let userId = userCalendar
			? userCalendar
			: JSON.parse(localStorage.user)._id; // userId request
		if (!_.isEmpty(listConstruction)) {
			// lich lam viec tai cong truong
			listConstruction.map((item) => {
				let employees = _.get(item, "schedules.employees", []);
				let manage = _.get(item, "schedules.manage", []);
				let slideTime = _.get(item, "schedules.slideTime");
				let lengEventsOld = events.length;
				let codeReplace = item.code.replace(/-/g, "");
				let showElementCode = slideTime ? "【ス】" + codeReplace : codeReplace;
				// user login là công nhân
				employees.map((_item) => {
					let start = _.get(item, "schedules.start", "");
					let _id = _.get(item, "_id", "");
					let checkOld = true;
					if (new Date(start) > new Date()) {
						checkOld = false;
					} else if (
						moment(new Date()).format("YYYY/MM/DD") ==
						moment(new Date(start)).format("YYYY/MM/DD")
					) {
						checkOld = false;
					}

					if (_item.value === userId) {
						// userId gửi request lên khi la Nhân vien
						let event = {
							start: new Date(start),
							end: new Date(start),
							name: item.name,
							code: item.code,
							address: item.address,
							constructionId: _id,
							key: "construction",
							cost: item.cost,
							amount: item.amount,
							contructionProfit: item.contructionProfit,
							type: checkOld ? "inOut" : 0, // type = 11 ngày quá khứ,  type = 0 ngày tương lai
							title: checkOld ? (
								<Typography color="inherit">{showElementCode}</Typography>
							) : (
								this.renderLink(_id, showElementCode)
							),
						};
						events.push(event);
						let eventSelec = {
							start: new Date(start),
							end: new Date(start),
							name: item.name,
							code: item.code,
							address: item.address,
							constructionId: _id,
							key: "construction",
							cost: item.cost,
							amount: item.amount,
							contructionProfit: item.contructionProfit,
							type: checkOld ? "inOut" : 0, // type = 11 ngày quá khứ,  type = 0 ngày tương lai

							title: (
								<span>
									{
										<CheckboxField
											label=""
											onChange={(value) =>
												this.onChangeCheckBox(_id, value, start)
											}
											style={{
												height: "3px",
												marginRight: "-20px",
												color: "white",
											}}
											name="checkbox"
										/>
									}{" "}
									{item.code}{" "}
								</span>
							),
							keyword: "noviews",
						};
						eventChange.push(eventSelec);
					}
				});
				let lengEventsNew = events.length;
				// user login là quản lý
				if (lengEventsOld === lengEventsNew) {
					// chống Duplace công trường khi User nằm cả ở employess và manage
					manage.map((_item) => {
						let start = _.get(item, "schedules.start", "");
						let _id = _.get(item, "_id", "");
						let checkOld = true;
						if (new Date(start) > new Date()) {
							checkOld = false;
						} else if (
							moment(new Date()).format("YYYY/MM/DD") ==
							moment(new Date(start)).format("YYYY/MM/DD")
						) {
							checkOld = false;
						}
						if (_item.value == userId) {
							// userId gửi request lên khi là quản lý
							let event = {
								start: new Date(start),
								end: new Date(start),
								title: checkOld ? (
									<Typography color="inherit">{showElementCode}</Typography>
								) : (
									this.renderLink(_id, showElementCode)
								),
								code: item.code,
								name: item.name,
								constructionId: _id,
								address: item.address,
								key: "construction",
								cost: item.cost,
								amount: item.amount,
								contructionProfit: item.contructionProfit,
								type: checkOld ? "inOut" : 0,
							};
							events.push(event);

							let eventSelec = {
								start: new Date(start),
								end: new Date(start),
								title: (
									<span>
										{
											<CheckboxField
												label=""
												onChange={(value) =>
													this.onChangeCheckBox(_id, value, start)
												}
												style={{
													height: "3px",
													marginRight: "-20px",
													color: "white",
												}}
												name="checkbox"
											/>
										}{" "}
										{item.code}{" "}
									</span>
								),
								code: item.code,
								name: item.name,
								address: item.address,
								constructionId: _id,
								key: "construction",
								keyword: "noviews",
								cost: item.cost,
								amount: item.amount,
								contructionProfit: item.contructionProfit,
								type: checkOld ? "inOut" : 0,
							};
							eventChange.push(eventSelec);
						}
					});
				}
			});
		}
		// Thêm sự kiện vào ngày tự thêm công trường
		if (!_.isEmpty(listHistory)) {
			listHistory.map((item) => {
				let constructions = _.get(item, "constructions", []) || [];
				let startTime = _.get(item, "date", "");
				let slideTime = _.get(item, "slideTime", false);

				if (constructions.length > 0) {
					constructions.map((element) => {
						let codeReplace = element.code.replace(/-/g, "");
						let showElementCode = slideTime
							? "【ス】" + codeReplace
							: codeReplace;
						let constructionId = _.get(element, "constructionId", "");
						let checkOld =
							moment(startTime).format("YYYY/MM/DD") ==
							moment().format("YYYY/MM/DD")
								? true
								: false;

						let event = {
							start: new Date(startTime),
							end: new Date(startTime),
							title: !checkOld ? (
								<Typography color="inherit">{showElementCode}</Typography>
							) : (
								this.renderLink(constructionId, showElementCode)
							),
							name: element.name,
							code: element.code,
							address: element.address,
							constructionId: constructionId,
							cost: "",
							amount: "",
							contructionProfit: "",
							key: "history",
							titleHoliday: item.titleHoliday,
							typeDayOff: item.typeDayOff,
							type: item.type || 0,
						};
						// kểm tra nếu công trường đã tồn tại rồi thì không thêm vào events nữa
						let checkDuplicate = false;
						for (let i = 0; i < events.length; i++) {
							if (
								moment(events[i].start).format("YYYY/MM/DD") ==
									moment(startTime).format("YYYY/MM/DD") &&
								events[i].key == "construction" &&
								events[i].code == event.code
							) {
								checkDuplicate = true;
							}
						}
						if (!checkDuplicate) {
							events.push(event);
						}

						let eventSelec = {
							start: new Date(startTime),
							end: new Date(startTime),
							title: (
								<span>
									{
										<CheckboxField
											label=""
											onChange={(value) =>
												this.onChangeCheckBox(constructionId, value, startTime)
											}
											style={{
												height: "3px",
												marginRight: "-20px",
												color: "white",
											}}
											name="checkbox"
										/>
									}{" "}
									{element.code}{" "}
								</span>
							),
							name: element.name,
							code: element.code,
							address: element.address,
							constructionId: constructionId,
							cost: "",
							amount: "",
							contructionProfit: "",
							keyword: "noviews",
							key: "history",
							titleHoliday: item.titleHoliday,
							typeDayOff: item.type ? 1 : null,
							type: item.type || 0,
						};
						// kểm tra nếu công trường đã tồn tại rồi thì không thêm vào events nữa
						let checkDuplicateSelec = false;
						for (let i = 0; i < eventChange.length; i++) {
							if (
								moment(eventChange[i].start).format("YYYY/MM/DD") ==
									moment(startTime).format("YYYY/MM/DD") &&
								eventChange[i].key == "construction" &&
								eventChange[i].code == event.code
							) {
								checkDuplicateSelec = true;
							}
						}
						if (!checkDuplicateSelec) {
							eventChange.push(eventSelec);
						}
					});
				} else if (startTime && startTime !== undefined) {
					let event = {
						start: new Date(startTime),
						end: new Date(startTime),
						title: (
							<Typography
								color="inherit"
								style={{ display: "inline-block" }}
							></Typography>
						),
						name: "",
						code: "",
						address: "",
						constructionId: "",
						cost: "",
						amount: "",
						contructionProfit: "",
						key: "history",
						titleHoliday: item.titleHoliday,
						typeDayOff: item.typeDayOff,
						type: item.type || 0,
					};
					events.push(event);
				}
			});
		}
		if (!_.isEmpty(listUserDayOff)) {
			// Events DayOff
			listUserDayOff.map((item) => {
				let titleTypeDayOff = "休日";
				if (parseInt(item.type) === 6) {
					titleTypeDayOff = "振替休日";
				}
				// if(item.type ===  '5'){
				//     titleTypeDayOff = "代休承認済"
				// } else if (item.type === '3') {
				//     titleTypeDayOff = "有休承認済"
				// } else if (item.type === '9') {
				//     titleTypeDayOff = '特別休暇'
				// }
				if (item.type !== "5" && item.type !== "3" && item.type !== "9") {
					let element = {
						start: new Date(item.date),
						end: new Date(item.date),
						title: <Typography color="inherit">{titleTypeDayOff}</Typography>,
						typeDayOff: item.type,
						type: "dayOff",
					};
					events.push(element);
				}
			});
		}
		if (!_.isEmpty(holiday)) {
			// Events ngày nghỉ lễ
			holiday.map((item) => {
				let element = {
					start: new Date(item.start),
					end: new Date(item.start),
					titleHoliday: item.title,
					title: <Typography color="inherit">{item.title}</Typography>,
					typeDayOff: item.type,
					type: "holiday",
				};
				events.push(element);
			});
		}

		if (!_.isEmpty(listWorkerRequests)) {
			// Events Xin phép
			let arrDate = [];
			listWorkerRequests.map((item) => {
				if (item.type !== 7) {
					arrDate.push(item.date);
				}
			});
			arrDate = this.deduplicate(arrDate);
			for (let e of arrDate) {
				// let dataRequest = [], title = ''
				listWorkerRequests.map((item) => {
					if (e === item.date) {
						let dataRequest = [],
							title = "";
						dataRequest.push(item);
						title = this.convertTitleTypeRequest(item);
						let element = {
							dataRequest: dataRequest,
							start: new Date(e),
							end: new Date(e),
							title: <Typography color="inherit">{title}</Typography>,
							type: "request",
						};
						events.push(element);
					}
				});
			}
		}
		return {
			events: events,
			eventChange: eventChange,
		};
	}

	convertTitleTypeRequest(item) {
		switch (item.type) {
			case 1:
				return item.status ? "早上がり承認済" : "早上がり申請";
			case 2:
				return item.status ? "残業承認済" : "残業申請";
			case 3:
				return item.status ? "有休承認済" : "有休申請";
			case 4:
				return item.status ? "休日出勤承認済" : "休日出勤申請";
			case 5:
				return item.status ? "代休承認済" : "代休申請";
			case 6:
				return item.status ? "振替休日出勤承認済" : "振替休日出勤申請";
			case 8:
				return item.status ? "欠勤承認済" : "欠勤申請";
			case 9:
				return item.status ? "特別休暇承認済" : "特別休暇申請";
			case 10:
				return item.status ? "遅刻承認済" : "遅刻申請";
			default:
				break;
		}
	}

	selecConstruction() {
		this.setState({ changeConstruction: !this.state.changeConstruction });
	}

	setBackCalendar() {
		localStorage.setItem("back", "calendars");
		localStorage.setItem("createRequest", "createRequest");
		let dateCalendar = localStorage.getItem("dateCalendar");
		let defaultDate = dateCalendar ? new Date(dateCalendar) : new Date();
		localStorage.setItem("dateRequest", defaultDate);
	}

	render() {
		const {
			classes,
			users,
			listHistory,
			getRequests,
			constructions,
			listDriverLengthCalendar,
			listConstruction,
			getAllConstructionDropDown,
		} = this.props;
		let listHolidayContruction = this.props.listHolidayContruction || [];
		localStorage.setItem("back", "calendars");
		let events = this.formatData(listHolidayContruction).events;
		let eventChange = this.formatData(listHolidayContruction).eventChange;
		let lastEvents;
		if (this.state.changeConstruction) {
			lastEvents = eventChange;
		} else {
			lastEvents = events;
		}
		let width = window.innerWidth;
		let messages = {
			previous: I18n.t("Calendar.back"),
			next: I18n.t("Calendar.next"),
			today: I18n.t("Calendar.today"),
		};
		let formats = {
			monthHeaderFormat: "YYYY年MM月",
		};
		let userCalendar = localStorage.getItem("userCalendar");
		let dateCalendar = localStorage.getItem("dateCalendar");
		let defaultDate = dateCalendar ? new Date(dateCalendar) : new Date();

		let checkUserLogin = false;
		if (JSON.parse(localStorage.user)._id === userCalendar) {
			checkUserLogin = true;
		}

		return (
			<Card>
				<CardContent>
					<Grid container spacing={16} direction="row" alignItems="center">
						<Grid
							item
							xs={12}
							lg={2}
							style={{ marginTop: width > 600 ? "" : "-20px" }}
						>
							{users.length ? (
								<AutoCompleteField
									key="1"
									fullWidth
									isClearable={false}
									select
									label={I18n.t("Input.name")}
									name="userId"
									onChange={(value) => {
										this.onChange(value);
									}}
									// defaultValue={JSON.parse(localStorage.user)._id}
									isChangeBackgroud={
										JSON.parse(localStorage.user)._id === userCalendar
											? false
											: true
									}
									defaultValue={userCalendar}
									isMulti={false}
								>
									{users.map((item) => (
										<OptionAuto
											key={item._id}
											value={item._id}
											showCheckbox={false}
										>
											{item.name}
										</OptionAuto>
									))}
								</AutoCompleteField>
							) : (
								""
							)}
						</Grid>
						{/* <Grid item xs={12} lg={2} >
                            <Button 
                                onClick={() => this.selecConstruction()} 
                                style={{marginTop: width > 600 ? '40px' : '0px'}} 
                                color='primary'
                                variant='contained'
                            >
                                Sửa công trường
                            </Button>
                        </Grid> */}
						<Grid item xs={12} lg={2}>
							{this.state.changeConstruction ? (
								<table style={{ marginTop: width > 600 ? "35px" : "0px" }}>
									<tbody>
										<tr>
											<td>
												<Typography>CT SlideTime</Typography>
											</td>
											<td>
												<Icon style={{ fontSize: "40px", color: "#77b300" }}>
													fiber_manual_record
												</Icon>
											</td>
										</tr>
									</tbody>
								</table>
							) : (
								""
							)}
						</Grid>
						<Grid item xs={12} lg={2}>
							{this.state.changeConstruction ? (
								<table style={{ marginTop: width > 600 ? "35px" : "0px" }}>
									<tbody>
										<tr>
											<td>
												<Typography>CT Hành chính</Typography>
											</td>
											<td>
												<Icon style={{ fontSize: "40px", color: "#666699" }}>
													fiber_manual_record
												</Icon>
											</td>
										</tr>
									</tbody>
								</table>
							) : (
								""
							)}
						</Grid>
						<Grid item xs={12} lg={2}>
							{users.length && this.state.changeConstruction ? (
								<AutoCompleteField
									key="1"
									fullWidth
									isClearable={false}
									select
									label={I18n.t("Input.Công trường thay đổi")}
									name="userId"
									onChange={(value) => {
										this.onChange(value);
									}}
									// defaultValue={JSON.parse(localStorage.user)._id}
									// defaultValue={userCalendar}
									isMulti={false}
								>
									{users.map((item) => (
										<OptionAuto
											key={item._id}
											value={item._id}
											showCheckbox={false}
										>
											{item.name}
										</OptionAuto>
									))}
								</AutoCompleteField>
							) : (
								""
							)}
						</Grid>
						<Grid item xs={12} lg={4}>
							<Link
								to={{ pathname: "/calendars/requests/create" }}
								style={{ textDecoration: "none" }}
							>
								<Button
									variant="outlined"
									onClick={() => this.setBackCalendar()}
									style={{
										border: "1px solid black",
										width: "95px",
										height: "34px",
									}}
								>
									<img
										src={process.env.PUBLIC_URL + "/button/create_request.png"}
										style={{ width: "26px" }}
									/>
									<span style={{ fontSize: "15px", color: "black" }}>
										{I18n.t("Button.request")}{" "}
									</span>
								</Button>
							</Link>
							<Link
								to={{ pathname: `/calendars/list` }}
								style={{ textDecoration: "none" }}
							>
								<Button
									variant="outlined"
									style={{
										border: "1px solid black",
										width: "95px",
										height: "34px",
										marginLeft: "10px",
										textDecoration: "none",
									}}
								>
									<span style={{ fontSize: "15px", color: "black" }}>
										{I18n.t("Button.listRequest")}
									</span>
								</Button>
							</Link>
							<Link
								to={{ pathname: "/reports/create" }}
								style={{ textDecoration: "none" }}
							>
								<Button
									variant="outlined"
									onClick={() => this.setBackCalendar()}
									style={{
										border: "1px solid black",
										width: "95px",
										height: "34px",
										marginLeft: "10px",
									}}
								>
									<img
										src={process.env.PUBLIC_URL + "/button/report.png"}
										style={{ width: "26px", marginRight: "5px" }}
									/>
									<span style={{ fontSize: "15px", color: "black" }}>
										{I18n.t("Button.report")}{" "}
									</span>
								</Button>
							</Link>
						</Grid>
					</Grid>
					<br />
					<Calendar
						localizer={localizer}
						classes={classes}
						drilldownView="agenda"
						resizable
						selectable
						culture={I18n.t("Calendar.localizer")}
						formats={formats}
						style={{ height: "calc(100vh - 190px)", maxHeight: "700px" }}
						events={lastEvents}
						// onRangeChange={(value) => this.onRangeChange(value)}
						messages={messages}
						onSelectSlot={this.handleSelect}
						onSelectEvent={this.handleSelect}
						onEventDrop={this.onEventDrop}
						onNavigate={(date) => this.onNavigate(date)}
						defaultDate={defaultDate}
						eventPropGetter={this.eventPropGetter}
						onEventResize={this.onEventResize}
						// longPressThreshold={3}
						tooltipAccessor={null}
						popup={true}
						onShowMore={() => {
							this.showMore = true;
						}}
						views={["month"]} //  'week', 'day', 'agenda'
					/>
					<EventConstruction
						innerRef={(ref) => (this.EventConstruction = ref)}
						listHistory={listHistory}
						getAllConstructionDropDown={getAllConstructionDropDown}
						classes={classes}
						listConstruction={listConstruction}
						listDriverLengthCalendar={listDriverLengthCalendar}
						getRequests={getRequests}
						defaultDate={defaultDate}
						onUpdateHistory={this.props.onUpdateHistory}
						onUpdateWorkingDriver={this.props.onUpdateWorkingDriver}
					/>
					<EventRequest
						constructions={constructions}
						innerRef={(ref) => (this.EventRequest = ref)}
						users={users}
						classes={classes}
						getRequests={getRequests}
						defaultDate={defaultDate}
						notify={this.notify}
					/>
					<EventDayOff
						constructions={constructions}
						innerRef={(ref) => (this.EventDayOff = ref)}
						users={users}
						classes={classes}
						getRequests={getRequests}
						defaultDate={defaultDate}
					/>
					<EventHoliday
						constructions={constructions}
						innerRef={(ref) => (this.EventHoliday = ref)}
						users={users}
						classes={classes}
						getRequests={getRequests}
						defaultDate={defaultDate}
					/>
					<Dialog
						open={this.state.open}
						onClose={this.onCancel}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						maxWidth="lg"
					>
						<DialogTitle
							id="alert-dialog-title"
							style={{
								width: width > 600 ? "300px" : "200px",
								height: "100px",
							}}
						>
							{I18n.t("Calendar.titleRequest")}
						</DialogTitle>
						<DialogActions>
							{this.state.showWorkingLog ? (
								<Button
									color="primary"
									onClick={() => this.setShowWorkingLog()}
								>
									{I18n.t("Button.showWorkingLog")}
								</Button>
							) : (
								""
							)}

							<Link
								style={{ textDecoration: "none", color: "#0099ff" }}
								to={{ pathname: "/calendars/requests/create" }}
								color="primary"
							>
								<Button color="primary" onClick={() => this.setCreateRequest()}>
									{I18n.t("Button.request")}
								</Button>
							</Link>

							<Button color="primary" onClick={this.onCancel}>
								{I18n.t("Button.cancel")}
							</Button>
						</DialogActions>
					</Dialog>
				</CardContent>
				<DrivingLength />
			</Card>
		);
	}
}

export default withStyles(styles)(withRouter(Employee));

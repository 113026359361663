import React from 'react'
import { connect } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import LineAPI from '../../containers/LineAPI/LineAPI'
import DrawerButton from './DrawerButton'
import DrawerMobileMenu from './DrawerMobileMenu'
import LogOut from './LogOut'
import ProfileMenu from './ProfileMenu'
import BaseContainer, { selector } from 'containers/BaseContainer'

class HeaderAppBar extends BaseContainer {
    render() {
        const { classes, route, user, onLogout, title, data } = this.props

        return (
            <AppBar position="fixed" className={classes.appBar} color="primary" style={!data.menuToggle.status ? {width: '100%'} : null}>
                <Toolbar>
                    <DrawerButton classes={classes} />
                    <DrawerMobileMenu route={route} ref={el => (this.menuRef = el)} />
                    <Typography variant="h6" color="inherit" noWrap className={classes.grow}>
                        {title}
                    </Typography>

                    <Hidden smDown>
                        <Typography variant="subtitle1" color="inherit" className={classes.Typography} noWrap>
                            {user.name}
                        </Typography>
                        <LogOut onLogout={onLogout} />
                    </Hidden>
                    <Hidden mdUp>
                        <ProfileMenu onLogout={onLogout} />
                    </Hidden>
                </Toolbar>
            </AppBar>
        )
    }
}

const mapStateToProps = state => {
    return {
        data: selector(state, "menuToggle", {})
    }
}

export default connect(mapStateToProps)(HeaderAppBar)

import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n'
import { Grid } from "@material-ui/core";
import './style.css'

export default class PrintTable extends Component {
    render() {
        const { exportType } = this.props;
        let kLass = exportType || 'print'
         
        return (
            <div>
                <div className={`page-a4 ${kLass}`} style={{height: '40cm'}}>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <table className={`${kLass} table2`} aria-label="table" style={{width: '100%'}}>
                                <thead>
                                    <tr>
                                        <td style ={{width: 150,fontSize: '2rem'}}>{ I18n.t('Table.header.user.code') }</td> 
                                        <td style ={{width: 150,fontSize: '2rem'}}>{ I18n.t('Table.header.user.name') }</td>
                                        <td style ={{width: 160,fontSize: '2rem'}}>{ I18n.t('Table.header.user.paidVacationDays') }</td>
                                        <td style ={{width: 130,fontSize: '2rem'}}>{ I18n.t('Table.header.user.remain') }</td>
                                        <td style ={{width: 130,fontSize: '2rem'}}>{ I18n.t('Table.header.user.remainByWorkingOnTimeOff') }</td>
                                        <td style ={{width: 250,fontSize: '2rem'}}>{ I18n.t('Table.header.user.dayOffInFuture') }</td>
                                    </tr>
                                </thead>
                                <tbody >
                                    {this.props.data.map((item, index) => (
                                        <tr key={index}>
                                            <td style ={{width: 150,fontSize: '2rem'}}>{item.code}</td>
                                            <td style ={{width: 150,fontSize: '2rem'}}>{item.name}</td>
                                            <td style ={{width: 160,fontSize: '2rem'}}>{item.paidVacationDays}</td>
                                            <td style ={{width: 130,fontSize: '2rem'}}>{item.remainTotal}</td>
                                            <td style ={{width: 130,fontSize: '2rem'}}>{item.remainByWorkingOnDayOff}</td>
                                            <td style ={{width: 250,fontSize: '2rem'}}>{item.dayOffs.map((item1, index1) => (<span key={index1}>{item1.date}, </span>))}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}
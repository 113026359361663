import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import {
   IconButton,
   Icon,
   Tooltip,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
} from '@material-ui/core'
import BaseView from 'views/BaseView'
import PaperFade from 'components/Main/PaperFade';
import { I18n } from 'react-redux-i18n';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import _ from 'lodash'
import FilterForm from './components/FilterForm'


const GridTable = React.lazy(() => import('components/Table/GridTable'))

const styles = theme => ({
   gridTable: {
      height: "calc(100vh)"
   }
})

class Index extends BaseView {
   constructor(props) {
      super(props)
      this.state = {
         open: false,
         reload: false,
      }
      this.table = {
         columns: [
            {
               name: 'index',
               title: I18n.t("Table.header.user.index"),
               type: "text",
               filterable: false,
               sortable: false,
               // style: {
               //     textAlign: 'center',
               // }
            },
            {
               name: 'date',
               title: I18n.t('Table.header.date'),
               formatterComponent: (data) => {
                  let date = moment(_.get(data.row, "date", "")).format("YYYY/MM/DD")
                  return date
               },
               style: {
                  textAlign: 'center',
               },
               filterable: false
            },
            {
               name: 'Construction.name',
               title: I18n.t('Table.header.construction.name'),
               formatterComponent: (data) => {
                  let Construction = _.get(data.row, "Construction", [])
                  return Construction.map((item, index) => {
                     if (Construction.length == 1) {
                        return <p key={index}>{item.name}</p>
                     }
                     return <p key={index}>{index + 1}, {item.name}</p>
                  })
               },
               style: {
                  textAlign: 'center',
               }
            },
            {
               name: 'Construction.code',
               title: I18n.t('Table.header.construction.code'),
               formatterComponent: (data) => {
                  let construction = _.get(data.row, "Construction", [])
                  return construction.map((item, index) => {
                     if (construction.length === 1) {
                        return <p key={index}>{item.code.replace(/-/g, "")}</p>
                        // return <p key={index}>{item.code}</p>
                     }
                     return <p key={index}>{index + 1}, {item.code}</p>
                  })
               },
               style: {
                  textAlign: 'center',
               }
            },
            {
               name: 'User.code',
               title: I18n.t('Table.header.user.code'),
               formatterComponent: (data) => {
                  let userName = _.get(data.row, "User.code", "")
                  return userName
               },
               style: {
                  textAlign: 'center',
               }
            },
            {
               name: 'User.name',
               title: I18n.t('Table.header.construction.userName'),
               formatterComponent: (data) => {
                  let userName = _.get(data.row, "User.name", "")
                  return userName
               },
               style: {
                  textAlign: 'center',
               }
            },
            {
               name: '_startTime',
               title: I18n.t('Table.header.construction._startTime'),
               filterable: false,
               sortable: false,
               formatterComponent: (data) => {
                  let _startTime = _.get(data.row, '_startTime', null)
                  if (_startTime) {
                     return moment(_startTime).format("HH:mm")
                  } else {
                     return '-' //<p style={{color:"red"}}>{I18n.t('Table.header.construction.notCheckOut')}</p>
                  }

               },
               style: {
                  textAlign: 'center',
               }
            },
            {
               name: '_endTime',
               title: I18n.t('Table.header.construction._endTime'),
               filterable: false,
               sortable: false,
               formatterComponent: (data) => {
                  let _endTime = _.get(data.row, '_endTime', null)
                  if (_endTime) {
                     return moment(_endTime).format("HH:mm")
                  } else {
                     return '-' //<p style={{color:"red"}}>{I18n.t('Table.header.construction.notCheckOut')}</p>
                  }

               },
               style: {
                  textAlign: 'center',
               }
            },
            {
               name: 'startTime',
               title: I18n.t('Table.header.construction.startTime'),
               filterable: false,
               sortable: false,
               formatterComponent: (data) => {
                  let startTime = _.get(data.row, 'startTime', null)
                  if (startTime)
                     return moment(startTime).format("HH:mm")
                  else
                     return '-' //I18n.t('Calendar.notCheckOut')
               },
               style: {
                  textAlign: 'center',
               }
            },
            {
               name: 'endTime',
               title: I18n.t('Table.header.construction.endTime'),
               filterable: false,
               sortable: false,
               formatterComponent: (data) => {
                  let endTime = _.get(data.row, 'endTime', null)
                  if (endTime)
                     return moment(endTime).format("HH:mm")
                  else
                     return '-' //I18n.t('Calendar.notCheckOut')
               },
               style: {
                  textAlign: 'center',
               }
            },
            {
               name: '_id',
               title: I18n.t('Table.header.action'),
               filterable: false,
               sortable: false,
               formatterComponent: (data) => {
                  return this.customActionColumn(data)
               },
               style: {
                  textAlign: 'center',
               }
            },
         ],
         defaultSort: [],
         tableColumnExtensions: [
            { columnName: 'index', wordWrapEnabled: true },
            { columnName: '_id', align: 'center' },
            { columnName: 'Construction.code', wordWrapEnabled: true },
            { columnName: 'Construction.name', wordWrapEnabled: true },
            { columnName: 'User.code', wordWrapEnabled: true },
            { columnName: 'User.name', wordWrapEnabled: true },
            { columnName: '_startTime', wordWrapEnabled: true },
            { columnName: 'startTime', wordWrapEnabled: true },
            { columnName: '_endTime', wordWrapEnabled: true },
            { columnName: 'endTime', wordWrapEnabled: true },

         ],
         columnWidths: [
            {
               name: 'index',
               width: 80
            },
            {
               name: 'date',
               width: 100
            },
            {
               name: 'Construction.name',
               width: 130
            },
            {
               name: 'Construction.code',
               width: 100
            },
            {
               name: 'User.name',
               width: 100
            },
            {
               name: 'User.code',
               width: 100
            },
            {
               name: '_startTime',
               width: 100
            },
            {
               name: '_endTime',
               width: 100
            },
            {
               name: 'startTime',
               width: 100
            },
            {
               name: 'endTime',
               width: 100
            },
            {
               name: '_id',
               width: 220
            },
         ]
      }
      this.ConfirmDialog = null
      this.renderSelectedActions = this.renderSelectedActions.bind(this)
      this.renderToolbarActions = this.renderToolbarActions.bind(this)
      this.onShow = this.onShow.bind(this)
      this.onHide = this.onHide.bind(this)
      this.onCancel = this.onCancel.bind(this)
   }

   onShow() {
      this.setState({ open: true })
   }

   onHide() {
      this.setState({ open: false })
   }

   onCancel() {
      this.onHide()
   }

   customActionColumn(data) {
      let endTime = _.get(data, 'row.endTime', '') // check out
      let _id = data.value || ''
      let Level = '0'
      let user = JSON.parse(localStorage.getItem('user'))
      let permissions = _.get(user, 'permissions', [])
      for (let x of permissions) {
         if (x === 'allow_level_2') {
            Level = 'allow_level_2'
         }
      }
      let date = moment(_.get(data.row, 'date', ''))
      let nextMonth = date.clone().add(1, 'M')
      let startOfNextMonth = nextMonth.startOf('month')
      let dayLevelTow = moment(startOfNextMonth.clone().add(13, 'd')).endOf('day')
      let checkEdit_Level_2 = (new Date().getTime()) > (new Date(dayLevelTow)).getTime() ? true : false

      switch (Level) {
         case '0':
            return (
               <div>
                  <Button
                     variant='contained'
                     color='primary'
                     onClick={endTime ? () => this.goto(`/histories/${_id}/detail`) : () => this.onShow()}
                  >
                     {I18n.t("Button.detail")}
                  </Button>
                  <Button
                     variant='contained'
                     color='primary'
                     onClick={endTime ? () => this.goto(`/histories/${_id}/edit`) : () => this.onShow()}
                     disabled={true}
                     style={{ marginLeft: '3px' }}
                  >
                     {I18n.t("Button.edit")}
                  </Button>
                  <Button
                     variant='contained' color="primary" key="delete"
                     onClick={() => this.ConfirmDialog.show([_id])}
                     disabled={true}
                     style={{ marginLeft: '3px' }}
                  >
                     {I18n.t('Button.delete')}
                  </Button>
               </div>
            )
         case 'allow_level_2':
            return (
               <div>
                  <Button
                     variant='contained'
                     color='primary'
                     onClick={endTime ? () => this.goto(`/histories/${_id}/detail`) : () => this.onShow()}
                  >
                     {I18n.t("Button.detail")}
                  </Button>
                  <Button
                     variant='contained'
                     color='primary'
                     onClick={endTime ? () => this.goto(`/histories/${_id}/edit`) : () => this.onShow()}
                     style={{ marginLeft: '3px' }}
                     disabled={checkEdit_Level_2}
                  >
                     {I18n.t("Button.edit")}
                  </Button>
                  <Button
                     variant='contained' color="primary" key="delete"
                     onClick={() => this.ConfirmDialog.show([_id])}
                     style={{ marginLeft: '3px' }}
                  >
                     {I18n.t('Button.delete')}
                  </Button>
               </div>
            )
      }
   }

   renderDialog() {
      return (
         <Dialog
            open={this.state.open}
            onClose={this.onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
         >
            <DialogTitle id="alert-dialog-title">
               {I18n.t('Exception.warningCheckOut')}
            </DialogTitle>
            <DialogContent>
               <p></p>
            </DialogContent>
            <DialogActions >
               <Button color="primary" onClick={this.onCancel} >
                  {I18n.t("Button.cancel")}
               </Button>
            </DialogActions>
         </Dialog>
      )
   }

   renderSelectedActions(selectedIds) {
      return [
         <Tooltip title={I18n.t("toolTip.delete")} key="detail">
            <IconButton key="delete" onClick={() => this.ConfirmDialog.show(selectedIds)}>
               <Icon>delete</Icon>
            </IconButton>
         </Tooltip>
      ]
   }

   renderToolbarActions() {
      return [

      ]
   }

   renderDialogConfirmDelete() {
      return (
         <ConfirmDialog
            ref={(ref) => this.ConfirmDialog = ref}
            title={I18n.t('Message.deleteDialogTitle')}
            content={I18n.t('Message.deleteDialogContent')}
            onSubmit={this.props.onDeleteData}
         />
      )
   }

   render() {
      const { data, classes, onSubmitDate } = this.props
      return (
         <PaperFade showLoading={true}>
            <FilterForm onSubmitDate={onSubmitDate} />
            {
               this.renderDialog()
            }
            <GridTable
               estimatedRowHeight={90}
               id="HistoriesIndex"
               className={classes.gridTable}
               onFetchData={this.props.onFetchData}
               onRefTable={this.props.onRefTable}
               columns={this.table.columns}
               rows={data.data}
               totalCount={data.total}
               pageSize={data.pageSize}
               defaultSort={this.table.defaultSort}
               showCheckboxColumn={false}
               height="auto"
               selectedActions={this.renderSelectedActions}
               tableActions={this.renderToolbarActions}
               tableColumnExtensions={this.table.tableColumnExtensions}
               defaultColumnWidths={this.table.columnWidths}

            />
            {this.renderDialogConfirmDelete()}
         </PaperFade>
      )
   }
}

Index.propTypes = {
   classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(withRouter(Index));
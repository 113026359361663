import React from 'react'
import PropTypes, { element } from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'
import { IconButton, Icon, Tooltip, Button } from '@material-ui/core'
import BaseView from 'views/BaseView'
import PaperFade from 'components/Main/PaperFade'
import { I18n } from 'react-redux-i18n'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import _ from 'lodash'
import moment from 'moment'
import ExportExcel from 'components/ExportExcel/ExportExcel'
import http from '../../helpers/http'

const GridTable = React.lazy(() => import('components/Table/GridTable'))
const styles = theme => ({
    gridTable: {
        height: "calc(100vh - 100px)"
    }
})


class Index extends BaseView {
    constructor(props) {
        super(props)
        this.state = {
            display: 0,
        }
        this.table = {
            columns: [
                {
                    name: 'index',
                    title: I18n.t("Table.header.user.index"),
                    type: "text",
                    filterable: false,
                    sortable: false
                },
                {
                    name: 'code',
                    title: I18n.t('Table.header.construction.code'),
                    formaterComponent: (data) => {
                        return this.textLink(data.value, `/constructions/${data.row._id}`)
                    }
                },
                {
                    name: 'name',
                    title: I18n.t('Table.header.construction.name'),
                },
                {
                    name: 'dateStart',
                    title: I18n.t('Table.header.construction.dateStart'),
                    formatterComponent: (data) => {
                        let dateStart = _.get(data.row, 'dateStart', '')
                        return dateStart ? moment(dateStart).format('YYYY/MM/DD') : ''
                    },
                    type: "date", //type để xuất excel
                    filterable: false
                },
                {
                    name: 'dateEnd',
                    title: I18n.t('Table.header.construction.dateEnd'),
                    formatterComponent: (data) => {
                        let dateEnd = _.get(data.row, 'dateEnd', '')
                        return dateEnd ? moment(dateEnd).format('YYYY/MM/DD') : ''
                    },
                    type: "date", //type để xuất excel
                    filterable: false
                },
                {
                    name: 'address',
                    title: I18n.t('Table.header.construction.address'),
                },
                {
                    name: '_id',
                    title: I18n.t('Table.header.action'),
                    filterable: false,
                    sortable: false,
                    formatterComponent: (data) => {
                        return this.customActionColumn(data)
                    }
                },
            ],
            excelColumns: [
                {
                    name: 'code',
                    title: '工事コード',
                    type: "text"
                },
                {
                    name: 'name',
                    title: '工事名',
                    type: "text"
                },
                {
                    name: 'construction_workTime',
                    title: '現場労務時間',
                    type: 'number'
                },
                {
                    name: 'realityCost',
                    title: '実質労務費',
                    type: 'number'
                }
            ],
            excelColumnWidths: [
                {
                    name: 'code',
                    width: 100,
                },
                {
                    name: 'name',
                    width: 170,
                },
                {
                    name: 'construction_workTime',
                    width: 100,
                },
                {
                    name: 'realityCost',
                    width: 100,
                }
            ],
            defaultSort: [],
            tableColumnExtensions: [
                { columnName: '_id', align: 'center' },
                {columnName: 'code',  wordWrapEnabled:true},
                {columnName: 'name', wordWrapEnabled:true},
                {columnName: 'address',  wordWrapEnabled:true},

            ],
            //tên các column name không muốn xuất file
            ignoreExcelColumns: ["_id"],
            //nếu tổng nhỏ hơn 990 thì tính theo %, ngược lại tính theo px
            columnWidths: [
                {
                    name: 'index',
                    width: 60
                },
                {
                    name: 'code',
                    width: 120
                },
                {
                    name: 'name',
                    width: 150
                },
                {
                    name: 'dateStart',
                    width: 100
                },
                {
                    name: 'dateEnd',
                    width: 100
                },
                {
                    name: 'address',
                    width: 200
                },
                {
                    name: '_id',
                    width: 150
                }
            ]
        }
        this.ConfirmDialog = null
        this.renderToolbarActions = this.renderToolbarActions.bind(this)
        this.renderSelectedActions = this.renderSelectedActions.bind(this)
    }

    customActionColumn(data) {
        let _id = data.value || ''
        let permissions = JSON.parse(localStorage.user).permissions || []
        let manaConstruction = false
        for (let e of permissions) {
            if (e == 'mana_construction'){
                manaConstruction = true
                break
            }
        }
        return (
            <div>
                <Tooltip title='' key="edit">
                    <Button variant='contained' color='primary' onClick={() => this.goto(`/constructions/${_id}`)}>
                        {I18n.t('Button.edit')}
                    </Button>
                </Tooltip>
                <Tooltip title='' key="Delete" style={{ marginLeft: '5px' }}>
                    <Button disabled={!manaConstruction} variant='contained' color="primary" key="delete" onClick={() => this.ConfirmDialog.show([_id])}>
                        {I18n.t('Button.delete')}
                    </Button>
                </Tooltip>
            </div>
        )
    }

    onFetchExcelData = async (cb) => {
        let response = await http.get('/api/v1/constructions/excel', { ids: this.selectedUserIds() })
        cb(response.data)
    }

    onRefTable = (ref) => {
      this.ConstructionTable = ref
    }

    selectedUserIds = () => {
      return this.ConstructionTable.state.selection
    }

    renderToolbarActions() {
        let { data = [] } = this.props

        const { ignoreExcelColumns, excelColumnWidths, excelColumns } = this.table
        let permissions = JSON.parse(localStorage.user).permissions || []
        let manaConstruction = false
        for (let e of permissions) {
            if (e == 'mana_construction'){
                manaConstruction = true
                break
            }
        }
        return [
            <Tooltip title={I18n.t("toolTip.new")} key="create">
                <Button variant='contained' color='primary' disabled={!manaConstruction}
                    onClick={() => this.goto("/constructions/create")}
                >
                    {I18n.t("Button.create")}
                </Button>
            </Tooltip>,
            <Tooltip title='' key="importExcel">
                <Button disabled={!manaConstruction} style={{marginLeft: "8px"}} variant='contained' color='primary'
                    onClick={() => this.goto("/constructions/import")}
                >
                    {I18n.t("Button.importFile")}
                </Button>
            </Tooltip>,
            <ExportExcel
                onClick={this.onFetchExcelData}
                ignoreExcelColumns={[]}
                name="constructions"
                data={data}
                type='constructionExport'
                columnWidths={excelColumnWidths}
                columns={excelColumns}
                key="exportExcel" />
        ]
    }

    renderSelectedActions(selectedIds) {
        return [
            <Tooltip title={I18n.t("toolTip.delete")} key="create">
                <IconButton key="delete" onClick={() => this.ConfirmDialog.show(selectedIds)}>
                    <Icon>delete</Icon>
                </IconButton>
            </Tooltip>
        ]
    }

    renderDialogConfirmDelete() {
        return (
            <ConfirmDialog
                ref={(ref) => this.ConfirmDialog = ref}
                title={I18n.t('Message.deleteDialogTitle')}
                content={I18n.t('Message.deleteDialogContent')}
                onSubmit={this.props.onDeleteData}
            />
        )
    }


    render() {
        const { data, classes } = this.props
        return (
            <PaperFade showLoading={true}>
                <GridTable
                    id="ConstructionIndex"
                    estimatedRowHeight = {70}
                    className={classes.gridTable}
                    onFetchData={this.props.onFetchData}
                    onRefTable={this.onRefTable}
                    columns={this.table.columns}
                    rows={data.data}
                    totalCount={data.total}
                    pageSize={data.pageSize}
                    defaultSort={this.table.defaultSort}
                    showCheckboxColumn={true}
                    height="auto"
                    selectedActions={this.renderSelectedActions}
                    tableActions={this.renderToolbarActions}
                    tableColumnExtensions={this.table.tableColumnExtensions}
                    defaultColumnWidths={this.table.columnWidths}
                />
                {this.renderDialogConfirmDelete()}
            </PaperFade>
        )
    }
}

Index.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(withRouter(Index))
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import { IconButton, Icon, Tooltip } from "@material-ui/core";
import BaseView from "views/BaseView";
import PaperFade from "components/Main/PaperFade";
import { I18n } from "react-redux-i18n";
import FilterForm from "./components/filter.form";
import _ from "lodash";
import ExportExcel from "components/ExportExcel/ExportExcel";
import ReactToPrint from "react-to-print";
import PrintTable from "../../components/Print/PrintTable";

const GridTable = React.lazy(() => import("components/Table/GridTable"));
const styles = (theme) => ({
	gridTable: {
		height: "calc(100vh - 170px)",
	},
});

class Index extends BaseView {
	constructor(props) {
		super(props);
		this.setColumn();

		this.ConfirmDialog = null;
		this.renderToolbarActions = this.renderToolbarActions.bind(this);
	}
	setColumn() {
		this.table = {
			columns: [
				{
					name: "index",
					title: I18n.t("Table.header.user.index"),
					type: "text",
					filterable: false,
					sortable: false,
					printStyle: {
						textAlign: "center",
					},
				},

				{
					name: "User.code",
					title: I18n.t("Table.header.user.code"),
					type: "text",
					formatterComponent: (data) => {
						return this.customUserCodeColumn(data);
					},
					style: {
						textAlign: "center",
					},
				},
				{
					name: "User.name",
					title: I18n.t("Table.header.user.name"),
					type: "text",
					formatterComponent: (data) => {
						return this.customUserNameColumn(data);
					},
					style: {
						textAlign: "center",
					},
				},
				{
					name: "totalDays",
					type: "number",
					title: I18n.t("Table.header.hour.totalDays"),
					filterable: false,
					formatterComponent: (data) => {
						let totalDays = _.get(data.row, "totalDays", "");
						return totalDays;
					},
					printStyle: {
						textAlign: "center",
					},
				},
				{
					name: "normalDays.standardHour",
					type: "number",
					title: I18n.t("Table.header.hour.standardHour"),
					filterable: false,
					formatterComponent: (data) => {
						return this.customColumn(data, "row.normalDays.standardHour");
					},
					style: {
						backgroundColor: "#7FDBFF",
					},
					printStyle: {
						textAlign: "center",
					},
				},
				{
					name: "normalDays.workNightHour",
					type: "number",
					title: I18n.t("Table.header.hour.workNightHour"),
					filterable: false,
					formatterComponent: (data) => {
						return this.customColumn(data, "row.normalDays.workNightHour");
					},
					style: {
						backgroundColor: "#7FDBFF",
					},
					printStyle: {
						textAlign: "center",
					},
				},

				{
					name: "normalDays.normalOT",
					type: "number",
					title: I18n.t("Table.header.hour.normalOT"),
					filterable: false,
					formatterComponent: (data) => {
						return this.customColumn(data, "row.normalDays.normalOT");
					},
					style: {
						backgroundColor: "#7FDBFF",
					},
					printStyle: {
						textAlign: "center",
					},
				},

				{
					name: "statutoryDays.standardHour",
					type: "number",
					title: I18n.t("Table.header.hour.statutoryDays_standardHour"),
					filterable: false,
					formatterComponent: (data) => {
						return this.customColumn(data, "row.statutoryDays.standardHour");
					},
					printStyle: {
						textAlign: "center",
					},
				},
				// {
				//     name: 'statutoryDays.workNightHour',
				//     type: "number",
				//     title: I18n.t('Table.header.hour.statutoryDays_workNightHour'),
				//     filterable: false,
				//     formatterComponent: (data) => {
				//         return this.customColumn(data, "row.statutoryDays.workNightHour")
				//     },
				//     printStyle: {
				//         textAlign: 'center',
				//     }
				// },
				{
					name: "nonStatutoryDays.standardHour",
					type: "number",
					title: I18n.t("Table.header.hour.nonStatutoryDays_standardHour"),
					filterable: false,
					formatterComponent: (data) => {
						return this.customColumn(data, "row.nonStatutoryDays.standardHour");
					},
					style: {
						backgroundColor: "#7FDBFF",
					},
					printStyle: {
						textAlign: "center",
					},
				},
				// {
				//     name: 'nonStatutoryDays.workNightHour',
				//     type: "number",
				//     title: I18n.t('Table.header.hour.nonStatutoryDays_workNightHour'),
				//     filterable: false,
				//     formatterComponent: (data) => {
				//         return this.customColumn(data, "row.nonStatutoryDays.workNightHour")
				//     },
				//     style: {
				//         backgroundColor: "#7FDBFF",
				//     },
				//     printStyle: {
				//         textAlign: 'center',
				//     }
				// },
				{
					name: "lateWork",
					type: "number",
					title: I18n.t("Table.header.hour.lateWork"),
					filterable: false,
					formatterComponent: (data) => {
						return this.customColumn(data, "row.lateWork");
					},
					printStyle: {
						textAlign: "center",
					},
				},
				{
					name: "absent",
					type: "number",
					title: I18n.t("Table.header.hour.absent"),
					filterable: false,
					formatterComponent: (data) => {
						return this.customColumn(data, "row.absent");
					},
					printStyle: {
						textAlign: "center",
					},
				},
				{
					name: "work_dayoff_holiday",
					type: "number",
					title: I18n.t("Table.header.hour.work_dayoff_holiday"),
					filterable: false,
					formatterComponent: (data) => {
						return this.customColumn(data, "row.work_dayoff_holiday");
					},
					printStyle: {
						textAlign: "center",
					},
				},
				{
					name: "rosteredDayOff",
					type: "number",
					title: I18n.t("Table.header.hour.rosteredDayOff"),
					filterable: false,
					formatterComponent: (data) => {
						let rosteredDayOff = _.get(data.row, "rosteredDayOff", "");
						return rosteredDayOff;
					},
					printStyle: {
						textAlign: "center",
					},
				},
				{
					name: "usedPaidHoliday",
					type: "number",
					title: "有休取得日数",
					formatterComponent: (data) => {
						return this.customColumn(data, "row.usedPaidHoliday");
					},
					filterable: false,
				},
				{
					name: "remainedPaidHoliday",
					type: "number",
					// title: I18n.t('Table.header.hour.remainedPaidHoliday')+'123' ,
					title: "有休残日数",
					formatterComponent: (data) => {
						return this.customColumn(data, "row.remainedPaidHoliday");
					},
					filterable: false,
				},

				{
					name: "totalTime",
					type: "number",
					title: I18n.t("Table.header.hour.totalTime"),
					filterable: false,
					formatterComponent: (data) => {
						return this.customColumn(data, "row.totalTime");
					},
					style: {
						textAlign: "center",
					},
					printStyle: {
						textAlign: "right",
					},
				},
			],
			tableColumnExtensions: [
				{ columnName: "index", align: "center", wordWrapEnabled: true },
				{ columnName: "User.code", wordWrapEnabled: true },
				{ columnName: "User.name", wordWrapEnabled: true },
				{ columnName: "totalDays", align: "center", wordWrapEnabled: true },
				{
					columnName: "normalDays.standardHour",
					align: "center",
					wordWrapEnabled: true,
				},
				{
					columnName: "normalDays.workNightHour",
					align: "center",
					wordWrapEnabled: true,
				},
				// { columnName: 'normalDays.morningOT', align: 'center', wordWrapEnabled: true },
				{
					columnName: "normalDays.normalOT",
					align: "center",
					wordWrapEnabled: true,
				},
				// { columnName: 'normalDays.nightOT', align: 'center', wordWrapEnabled: true },
				{
					columnName: "statutoryDays.standardHour",
					align: "center",
					wordWrapEnabled: true,
				},
				{
					columnName: "statutoryDays.workNightHour",
					align: "center",
					wordWrapEnabled: true,
				},
				{
					columnName: "statutoryDays.morningOT",
					align: "center",
					wordWrapEnabled: true,
				},
				{
					columnName: "statutoryDays.normalOT",
					align: "center",
					wordWrapEnabled: true,
				},
				{
					columnName: "statutoryDays.nightOT",
					align: "center",
					wordWrapEnabled: true,
				},
				{
					columnName: "nonStatutoryDays.standardHour",
					align: "center",
					wordWrapEnabled: true,
				},
				{
					columnName: "nonStatutoryDays.workNightHour",
					align: "center",
					wordWrapEnabled: true,
				},
				{
					columnName: "nonStatutoryDays.morningOT",
					align: "center",
					wordWrapEnabled: true,
				},
				{
					columnName: "nonStatutoryDays.normalOT",
					align: "center",
					wordWrapEnabled: true,
				},
				{
					columnName: "nonStatutoryDays.nightOT",
					align: "center",
					wordWrapEnabled: true,
				},
				{ columnName: "lateWork", align: "center", wordWrapEnabled: true },
				{ columnName: "absent", align: "center", wordWrapEnabled: true },
				{
					columnName: "work_dayoff_holiday",
					align: "center",
					wordWrapEnabled: true,
				},
				{
					columnName: "rosteredDayOff",
					align: "center",
					wordWrapEnabled: true,
				},
				{
					columnName: "remainedPaidHoliday",
					align: "center",
					wordWrapEnabled: true,
				},
				{
					columnName: "usedPaidHoliday",
					align: "center",
					wordWrapEnabled: true,
				},

				{ columnName: "totalTime", align: "right", wordWrapEnabled: true },
			],
			defaultSort: [],
			//tên các column name không muốn xuất file
			ignoreExcelColumns: ["index"],
			ignorePrintColumns: [],
			columnWidths: [
				{
					name: "index",
					width: 90,
				},
				{
					name: "User.code",
					width: 200,
				},
				{
					name: "User.name",
					width: 350,
				},
				{
					name: "totalDays",
					width: 150,
				},
				{
					name: "normalDays.standardHour",
					width: 180,
				},
				{
					name: "normalDays.workNightHour",
					width: 180,
				},
				// {
				//     name: 'normalDays.morningOT',
				//     width: 180
				// },
				{
					name: "normalDays.normalOT",
					width: 180,
				},
				// {
				//     name: 'normalDays.nightOT',
				//     width: 180
				// },
				{
					name: "statutoryDays.standardHour",
					width: 180,
				},
				{
					name: "statutoryDays.workNightHour",
					width: 180,
				},
				{
					name: "statutoryDays.morningOT",
					width: 180,
				},
				{
					name: "statutoryDays.normalOT",
					width: 180,
				},
				{
					name: "statutoryDays.nightOT",
					width: 180,
				},
				{
					name: "nonStatutoryDays.standardHour",
					width: 180,
				},
				{
					name: "nonStatutoryDays.workNightHour",
					width: 180,
				},
				{
					name: "nonStatutoryDays.morningOT",
					width: 180,
				},
				{
					name: "nonStatutoryDays.normalOT",
					width: 180,
				},
				{
					name: "nonStatutoryDays.nightOT",
					width: 180,
				},
				{
					name: "lateWork",
					width: 180,
				},
				{
					name: "absent",
					width: 180,
				},
				{
					name: "work_dayoff_holiday",
					width: 180,
				},
				{
					name: "rosteredDayOff",
					width: 100,
				},
				{
					name: "usedPaidHoliday",
					width: 180,
				},
				{
					name: "remainedPaidHoliday",
					width: 180,
				},
				{
					name: "totalTime",
					width: 200,
				},
			],
		};
	}

	componentDidUpdate() {
		this.setColumn();
	}

	customUserNameColumn(data) {
		return this.getData(data, "row.User.name", "");
	}

	customUserCodeColumn(data) {
		return this.getData(data, "row.User.code", "");
	}

	customColumn(data, path) {
		data = this.getData(data, path, "0");

		return data;
	}

	getHeaderText() {
		let print_hours = JSON.parse(localStorage.getItem("print_hours")) || {};

		return `${I18n.t("Breadcrumb.hourIndex")} ${print_hours.startDate} - ${
			print_hours.endDate
		}`;
	}

	renderToolbarActions() {
		const { data = [] } = this.props.data;
		const { ignoreExcelColumns, columnWidths, columns, ignorePrintColumns } =
			this.table;
		const headerText = this.getHeaderText();

		return [
			<ReactToPrint
				key="print"
				trigger={() => (
					<Tooltip title={I18n.t("toolTip.print")} key="print">
						<IconButton>
							<Icon>local_printshop</Icon>
						</IconButton>
					</Tooltip>
				)}
				content={() => this.componentRef}
			/>,
			<div key="printTable" style={{ display: "none" }}>
				<PrintTable
					data={data}
					ignore={ignorePrintColumns}
					columns={columns}
					headerText={headerText}
					columnWidths={columnWidths}
					ref={(el) => (this.componentRef = el)}
				/>
			</div>,
			// ignoreExcelColumns: tên các cột không muốn xuất, name: tên bảng, columnWidths: độ rộng của các cột, columns: tên column truyền vào
			<ExportExcel
				ignoreExcelColumns={ignoreExcelColumns}
				name="hours"
				data={data}
				columnWidths={columnWidths}
				columns={columns}
				key="exportExcel"
			/>,
		];
	}
	render() {
		const { data = {}, classes, _60Time, _isRound } = this.props;
		let rows = data.data || [];
		return (
			<PaperFade showLoading={true}>
				<FilterForm
					onSubmitDate={this.props.onSubmitDate}
					isRound={this.props.isRound}
					_isRound={_isRound}
					_60Time={_60Time}
					is60Time={this.props.is60Time}
				/>
				<GridTable
					id="HourIndex"
					pageHiding={true}
					className={classes.gridTable}
					onFetchData={this.props.onFetchData}
					onRefTable={this.props.onRefTable}
					columns={this.table.columns}
					rows={[...(rows || [])]}
					defaultSort={this.table.defaultSort}
					height="auto"
					tableColumnExtensions={this.table.tableColumnExtensions}
					tableActions={this.renderToolbarActions}
					defaultColumnWidths={this.table.columnWidths}
				/>
			</PaperFade>
		);
	}
}

Index.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Index));

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { I18n } from 'react-redux-i18n';
import BaseView from 'views/BaseView';
import SelectField, { Option } from 'components/Forms/SelectField'
import { Grid, Card, CardContent, CardHeader, CardActions, Button } from '@material-ui/core'
import { TextField, Validation } from 'components/Forms/';
const styles = theme => ({
    cardContainer: {
        marginTop: `${theme.spacing.unit * 3}px`
    },
    card: {
        padding: `0px ${theme.spacing.unit * 4}px ${theme.spacing.unit * 3}px`,
    },
    cardHeader: {
        padding: ` ${theme.spacing.unit * 3}px ${theme.spacing.unit * 4}px`,
    }
})
class CardRound extends BaseView {
    render() {
        const { classes, data } = this.props
        return (
            <Card className = {classes.cardContainer}>
                <CardHeader
                    className={classes.cardHeader}
                    title={I18n.t("Label.hourSetting")}
                />
                <CardContent className={classes.card}>
                    <Grid container spacing={32}>
                        <Grid item xs={6}>
                            <SelectField
                                fullWidth
                                name="is60Time"
                                label={I18n.t("Input.hourType")}
                                value={String(data.is60Time)}
                            >
                                <Option value={'true'}>{I18n.t('Button.60 進数')}</Option>
                                <Option value={'false'}>{I18n.t('Button.10 進数')}</Option>
                            </SelectField>
                        </Grid>
                    </Grid>
                </CardContent>
                {/* <CardActions className={classes.card}>
                    <Button size="small" color="primary" type="submit" variant='contained'>
                    {I18n.t("Button.submit")}
                    </Button>
                </CardActions> */}
            </Card>
        )
    }
}

export default withStyles(styles)(CardRound);

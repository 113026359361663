import BaseAction from './BaseAction'
/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
class ConstructionAction extends BaseAction {
  get actions() {
    return {
      fetchAll: {
        method: 'get',
        url: '/api/v1/totalWorkingHour',
        type: 'ConstructionHour.fetchAll'
      },
      // fetch: {
      //   method: 'get',
      //   url: '/api/v1/totalWorkingHour/:_id',
      //   type: 'ConstructionHour.fetch'
      // }, 
    }
  }
}
/*
 * bắt buộc gọi hàm export()
 */
export default ConstructionAction.export()
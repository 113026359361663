import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import withStyles from "@material-ui/core/styles/withStyles";
import DialogConstruction from "./DialogConstruction";
import "../style.css";

import { Dialog, AppBar, IconButton } from "@material-ui/core";
import _ from "lodash";

let width = window.innerWidth;
const styles = (theme) => ({
	cssLink: {
		textDecoration: "none",
	},
	setRight: {
		textAlign: "right",
	},
	fixXX: {
		minHeight: "400px",
		padding: "24px",
		position: "relative",
	},
	btnAddRequest: {
		position: "absolute",
		bottom: "19px",
		right: "19px",
	},
	iconCreateRequest: {
		fontSize: "41px",
		[theme.breakpoints.down("xs")]: {
			fontSize: "41px",
			position: "fixed",
			bottom: "5px",
			background: "white",
			borderRadius: " 50%",
		},
	},
	fixHeight: {
		maxHeight: "100%",
		minHeight: "auto",
		[theme.breakpoints.down("xs")]: {
			maxHeight: "100%",
			minHeight: "auto",
			height: "100vh",
		},
	},
	fixHight: {
		overflowY: "hidden",
		padding: "0px !important",
	},
	itemFixPaddingDialong: {
		padding: "0px",
	},
	button: {
		marginTop: `${theme.spacing.unit * 2}px`,
	},
	customScroll: {
		maxHeight: "100%",
	},
});

class DialogEvent extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			data: { employees: [] },
			startDate: null,
			newValue: 0,
			listHistory: [],
			flagSubmit: false,
		};
		this.updateHistoryCalendar.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.toggleEditContructs = this.toggleEditContructs.bind(this);
	}

	componentDidUpdate() {
		this.state.listHistory = this.props.listHistory;
	}

	componentWillMount() {
		this.setState({
			listHistory: this.props.listHistory,
		});
	}

	updateHistoryCalendar = (data, e) => {
		if (this.state.flagSubmit) {
			return;
		}
		let constructions = _.get(data, "constructions", []);
		let arrConstruction = [];
		let userCalendar = localStorage.getItem("userCalendar");

		constructions.map((item, index) => {
			if (item.constructionId && item.constructionId !== undefined) {
				let hours = _.get(item, "hours", "");
				let minutes = _.get(item, "minutes", "");
				let workTime =
					Number(hours) * 3600 * 1000 + Number(minutes) * 60 * 1000;
				let element = {
					constructionId: item.constructionId,
					workTime: workTime,
				};
				arrConstruction.push(element);
			}
		});

		let driving_length_on_working = null,
			driving_length_on_private = null,
			fuel_supply_amount = null,
			memo = "";
		if (data != undefined && data.driving_length_on_working != undefined) {
			driving_length_on_working = data.driving_length_on_working;
		}
		if (data != undefined && data.driving_length_on_private != undefined) {
			driving_length_on_private = data.driving_length_on_private;
		}
		if (data != undefined && data.fuel_supply_amount != undefined) {
			fuel_supply_amount = data.fuel_supply_amount;
		}
		if (data != undefined && data.memo != undefined) {
			memo = data.memo.toString();
		}

		let dateStart = data.start;
		let dataSubmit = {};
		if (data.updateWorkingDriver === "true") {
			dataSubmit = {
				userId: userCalendar,
				idRequest: data.idRequest,
				driving_length_on_working: driving_length_on_working,
				driving_length_on_private: driving_length_on_private,
				fuel_supply_amount: fuel_supply_amount,
				memo: memo,
				date: dateStart,
			};

			this.onCancel();
			this.setState({ flagSubmit: true });
			return this.props.onUpdateWorkingDriver(dataSubmit);
		} else {
			dataSubmit = {
				userId: userCalendar,
				idRequest: data.idRequest,
				date: dateStart,
				startTime: data.startTime,
				endTime: data.endTime,
				totalTime: data.totalTime,
				slideTime: data.slideTime,
				constructions: arrConstruction,
				driving_length_on_working: driving_length_on_working,
				driving_length_on_private: driving_length_on_private,
				fuel_supply_amount: fuel_supply_amount,
				memo: memo,
			};

			this.onCancel();
			this.setState({ flagSubmit: true });
			return this.props.onUpdateHistory(dataSubmit);
		}
	};
	show(data = null) {
		this.setState({
			open: true,
			data: { ...this.state.data, ...data },
			startDate: data.start,
			flagSubmit: false,
		});
	}

	hide() {
		this.setState({ open: false, flagSubmit: false });
	}

	onCancel() {
		const { onCancel } = this.props;
		this.hide();
		if (typeof onCancel === "function") {
			onCancel(this.state.data);
		}
		localStorage.removeItem("event");
		this.setState({ flagSubmit: false });
	}

	toggleEditContructs() {
		this.setState({
			editingCheckIn: !this.state.editingCheckIn,
		});
	}

	submitListHistory = (listHistory) => {
		this.setState({
			listHistory: listHistory,
		});
	};

	render() {
		let construction = this.state.data;
		let {
			getRequests,
			classes,
			listConstruction,
			getAllConstructionDropDown,
			listDriverLengthCalendar,
		} = this.props;
		let start = _.get(this.state.data, "start", "");

		if (this.state.listHistory.length <= 0) {
			this.state.listHistory = this.props.listHistory;
		}

		return (
			<div className={classes.fixHight}>
				<Dialog
					fullScreen={width <= 500 ? true : false}
					fullWidth={true}
					open={this.state.open}
					maxWidth="sm"
					aria-labelledby="draggable-dialog-title"
					className={`${classes.itemFixPaddingDialong} changeWidthScroll`}
				>
					<AppBar position="sticky">
						<div className={classes.setRight}>
							<IconButton
								onClick={this.onCancel}
								edge="start"
								color="inherit"
								aria-label="Close"
							>
								<CloseIcon />
							</IconButton>
						</div>
					</AppBar>
					{this.state.startDate && start && (
						<DialogConstruction
							start={start}
							flagSubmit={this.state.flagSubmit}
							listConstruction={listConstruction}
							listDriverLengthCalendar={listDriverLengthCalendar}
							listHistory={this.state.listHistory}
							classes={classes}
							construction={construction}
							getAllConstructionDropDown={getAllConstructionDropDown}
							getRequests={getRequests}
							onClick={this.toggleEditContructs}
							submitListHistory={this.submitListHistory}
							updateHistoryCalendar={this.updateHistoryCalendar}
							onCancel={this.onCancel}
						/>
					)}
				</Dialog>
			</div>
		);
	}
}

export default withStyles(styles)(DialogEvent);

import React from 'react';
import { Translate } from 'react-redux-i18n'
import Icon from '@material-ui/core/Icon';
import UserIndex from 'containers/User/Index'
//import GroupIndex from 'containers/Group/Index'
import AreaIndex from 'containers/Area/Index'
import RoleIndex from 'containers/Role/Index'
// import PermissionIndex from 'containers/Permission/Index'
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import PositionIndex from 'containers/Position/Index'
import SettingTime from 'containers/Setting/Time'
import DayOffIndex from 'containers/DayOff/Index'
import DeleteIndex from 'containers/Delete/Index'
import ReportIndex from 'containers/Report/Index'
import ConstructionIndex from 'containers/Construction/Index'
import ConstructionHourIndex from 'containers/ConstructionHour/Index'
import LaborIndex from 'containers/Labor/Index'
import HistoryIndex from 'containers/History/Index'
import Worker from 'containers/Worker/Worker'
import RequestIndex from 'containers/Request/Index'
import HourIndex from 'containers/Hour/Index'
import HourDetail from 'containers/Hour/Detail'
import Calendar from 'containers/Calendar/Calendar'
import TimekeepingIndex from 'containers/Timekeeping/Index'
import TimekeepingEdit from 'containers/Timekeeping/Edit'
import MonthlyReport from 'containers/MonthlyReport/Index'
// import ContentsIndex from 'containers/Contents/Index'

const sidebar = [
  {
    path: "/workers",
    name: 'worker',
    title: <Translate value="Sidebar.worker" />,
    icon: <Icon>directions_walk</Icon>,
    component: () => <Worker />,
  },
  // ---------------------------------------------
  {
    path: "/calendars",
    name: 'calendar',
    title: <Translate value="Sidebar.calendar" />,
    icon: <Icon>calendar_today</Icon>,
    component: () => <Calendar />,
  },
  {
    path: "/myhour",
    name: 'myhour',
    title: <Translate value="Sidebar.hourDetail" />,
    component: () => <HourDetail />,
    exact: true
  },
  {
    path: "/reports",
    name: 'report',
    title: <Translate value="Sidebar.report" />,
    icon: <Icon>import_contacts</Icon>,
    component: () => <ReportIndex />,
  },
  {
    path: "/monthly_reports",
    name: 'monthlyReport',
    title: <Translate value="Sidebar.monthlyReport" />,
    icon: <Icon>train</Icon>,
    component: () => <MonthlyReport />,
  },
  // {
  //   path: "/reports",
  //   name: 'report',
  //   title: <Translate value="Sidebar.report" />,
  //   icon: <Icon>import_contacts</Icon>,
  //   component: () => <ReportIndex />,
  // },
  //-------------------------------------------
  {
    path: "/constructions",
    name: 'construction',
    title: <Translate value="Sidebar.construction" />,
    icon: <Icon>local_grocery_store</Icon>,
    component: () => <ConstructionIndex />,
  },

  {
    path: "/constructions-hour",
    name: 'construction_hour',
    title: <Translate value="Sidebar.constructionHour" />,
    icon: <AccessAlarmsIcon />,
    component: () => <ConstructionHourIndex />,
  },
  {
    path: "/requests",
    name: 'request',
    title: <Translate value="Sidebar.request" />,
    icon: <Icon>input</Icon>,
    component: () => <RequestIndex />,
  },
  //------------------------------
  {
    path: "/histories",
    name: 'history',
    title: <Translate value="Sidebar.history" />,
    icon: <Icon>history</Icon>,
    component: () => <HistoryIndex />,
  },
  {
    path: "/hours",
    name: 'hour',
    title: <Translate value="Sidebar.hour" />,
    icon: <Icon>access_time</Icon>,
    component: () => <HourIndex />,
  },

  {
    path: "/labors",
    name: 'labor',
    title: <Translate value="Sidebar.labor" />,
    icon: <Icon>how_to_reg</Icon>,
    component: () => <LaborIndex />,
  },
  {
    path: "/timekeepings",
    name: 'timekeeping',
    title: <Translate value="Sidebar.timekeeping" />,
    icon: <Icon>cloud_done</Icon>,
    component: () => <TimekeepingIndex />,
  },
  {
    path: "/monthly-report-management",
    name: 'monthly_report_management',
    title: <Translate value="Sidebar.monthlyReportManagement" />,
    icon: <Icon>calendar_view_day</Icon>,
    component: () => <TimekeepingIndex />,
  },
  {
    path: "/timekeepings",
    name: 'timekeeping',
    title: <Translate value="Sidebar.timekeeping" />,
    icon: <Icon>cloud_done</Icon>,
    component: () => <TimekeepingIndex />,
  },
  {
    path: "/dayoffs",
    name: 'dayoff',
    title: <Translate value="Sidebar.dayoff" />,
    icon: <Icon>date_range</Icon>,
    component: () => <DayOffIndex />,
  },
  {
    path: "/deletes",
    name: 'delete',
    title: <Translate value="Sidebar.delete" />,
    icon: <Icon>delete_forever</Icon>,
    component: () => <DeleteIndex />,
  },
  //users
  {
    path: "/users",
    name: 'user',
    title: <Translate value="Sidebar.user" />, //bắt buộc dùng thẻ Translate, không được dùng I18n
    icon: <Icon>account_circle</Icon>,
    component: () => <UserIndex />,
  },
  {
    path: "/roles",
    name: 'role',
    title: <Translate value="Sidebar.role" />,
    icon: <Icon>gavel</Icon>,
    component: () => <RoleIndex />,
  },
  {
    path: "/positions",
    name: 'position',
    title: <Translate value="Sidebar.position" />,
    icon: <Icon>work</Icon>,
    component: () => <PositionIndex />,
  },
  {
    path: "/areas",
    name: 'area',
    title: <Translate value="Sidebar.area" />,
    icon: <Icon>location_on</Icon>,
    component: () => <AreaIndex />,
  },
  //------------------------------------------------
  {
    path: "/setting",
    name: 'setting',
    title: <Translate value="Sidebar.settingTime" />,
    icon: <Icon>settings</Icon>,
    component: () => <SettingTime />,
  },
  {
    name: 'assetManagementSideBar',
    title: <Translate value="Sidebar.assetMagement.title" />,
    children: ['calendar_booking', 'list_product', 'revenue', 'printing', 'damageNotDefine']
  },
  {
    name: 'wlmWorkLoadSideBar',
    title: <Translate value="Sidebar.workingLoad.title"/>,
    children: ['wlmConstruction', 'wlmWorkerCharges', 'wlmTeamManagements', 'wlmWorkload', 'wlmEstimate', 'wlmLoad', 'wlmTeam']
  }
  /*__AUTOSIDEBAR__*/
  /*Do not remove __AUTOSIDEBAR__ variable.*/
]

export default sidebar

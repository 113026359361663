import BaseReducer from './BaseReducer';
/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
class ReportReducer extends BaseReducer {
  get actionsAllow() {
    return {
      ...super.actionsAllow,
      "Report.create": {
        path: "data"
      },
      "Report.edit": {
        path: "data"
      },
      "Report.delete": {
        path: "data"
      },
      "Report.destroy": {
        path: "data"
      },
      "Report.fetchAll": {
        path: "list"
      },
      "Report.fetch": {
        path: "item"
      },
      "Report.listConstruction": {
        path: "listConstruction"
      },
    }
  }

  get initialState() {
    return {
      ...super.initialState,
      error: {
        message: null
      }
    }
  }
}
/*
 * bắt buộc gọi hàm export()
 */
export default ReportReducer.export()
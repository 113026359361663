import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom'
import { IconButton, Icon, Tooltip, Button } from '@material-ui/core'
import BaseView from 'views/BaseView'
import PaperFade from 'components/Main/PaperFade';
import { I18n } from 'react-redux-i18n';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import _ from 'lodash'

const GridTable = React.lazy(() => import('components/Table/GridTable'))
const styles = theme => ({
    gridTable: {
        height: "calc(100vh - 100px)"
    },
    button: {
        marginRight: '10px'
    }
})

class Index extends BaseView {
    constructor(props) {
        super(props)
        this.state = {
        }
        this.table = {
            columns: [
                {
                    name: 'index',
                    title: I18n.t("Table.header.user.index"),
                    type: "text",
                    filterable: false,
                    sortable: false
                },
                {
                    name: 'code',
                    title: I18n.t('Table.header.position.code'),
                    sortable: false,
                    formatterComponent: (data) => {
                        return this.textLink(data.value, `/positions/${data.row._id}`)
                    }
                },
                {
                    name: 'name',
                    sortable: false,
                    title: I18n.t('Table.header.position.name'),
                },
                {
                    name: 'userNumber',
                    title: I18n.t('Table.header.position.number'),
                    filterable: false,
                    sortable: false,
                    formatterComponent: (data) => {
                        let userNumber = _.get(data.row, "userNumber", '')
                        return userNumber
                    },
                },
                {
                    name: 'remuneration',
                    filterable: false,
                    sortable: false,
                    title: I18n.t('Table.header.position.remuneration'),
                    formatterComponent: (data) => {
                        let remuneration = _.get(data.row, "remuneration", '');
                        return remuneration.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                    },
                },
                {
                    name: '_id',
                    title: I18n.t('Table.header.action'),
                    filterable: false,
                    sortable: false,
                    formatterComponent: (data) => {
                        return this.customActionColumn(data)
                    }
                },
            ],
            defaultSort: [],
            tableColumnExtensions: [
                { columnName: 'remuneration', align: 'right', wordWrapEnabled: true },
                { columnName: 'code', wordWrapEnabled: true },
                { columnName: 'name', wordWrapEnabled: true },
                { columnName: 'userNumber', align: 'left', wordWrapEnabled: true },
                { columnName: '_id', align: 'center' },
            ],
            columnWidths: [
                {
                    name: 'index',
                    width: 70
                },
                {
                    name: 'code',
                    width: 100
                },
                {
                    name: 'name',
                    width: 150
                },
                {
                    name: 'userNumber',
                    width: 70
                },
                {
                    name: 'remuneration',
                    width: 150
                },
                {
                    name: '_id',
                    width: 150
                },
            ]
        }
        this.ConfirmDialog = null
        this.renderToolbarActions = this.renderToolbarActions.bind(this)
        this.renderSelectedActions = this.renderSelectedActions.bind(this)
    }

    renderToolbarActions() {
        return [
            <Tooltip title={I18n.t("toolTip.new")} key="create">
                <Button variant='contained' color='primary' onClick={() => this.goto("/positions/create")}>
                    {I18n.t("Button.create")}
                </Button>
            </Tooltip>
        ]
    }

    renderSelectedActions(selectedIds) {
        return [
            <Tooltip title={I18n.t("toolTip.delete")} key="create">
                <IconButton key="delete" onClick={() => this.ConfirmDialog.show(selectedIds)}>
                   <Icon>delete</Icon>
                </IconButton>
            </Tooltip>,
            // <Tooltip title="Delete Selected Items" key="create">
            //     <IconButton key="delete" onClick={() => this.ConfirmDialog.show(selectedIds)}>
            //         <Icon>delete</Icon>
            //     </IconButton>
            // </Tooltip>
        ]
    }

    renderDialogConfirmDelete() {
        return <ConfirmDialog
            ref={(ref) => this.ConfirmDialog = ref}
            title={I18n.t('Message.deleteDialogTitle')}
            content={I18n.t('Message.deleteDialogContent')}
            onSubmit={this.props.onDeleteData}
        />
    }

    customActionColumn(data) {
        let _id = this.getData(data, "value", '')
        const { classes } = this.props;
        return (
            <div>
                <Tooltip title= '' key="edit" className={classes.button}>
                    <Button variant='contained' color="primary" onClick={() => this.goto(`/positions/${_id}`)}>
                        {I18n.t('Button.edit')}
                    </Button>
                </Tooltip>
                <Tooltip title='' key="Delete" className={classes.button}>
                    <Button variant='contained' color="primary" key="delete" onClick={() => this.ConfirmDialog.show([_id])}>
                        {I18n.t('Button.delete')}
                    </Button>
                </Tooltip>
            </div>
        )
    }

    render() {
        const { data, classes } = this.props
        return (
            <PaperFade showLoading={true}>
                <GridTable
                    id="PositionIndex"
                    className={classes.gridTable}
                    onFetchData={this.props.onFetchData}
                    onRefTable={this.props.onRefTable}
                    columns={this.table.columns}
                    rows={data.data}
                    totalCount={data.total}
                    pageSize={data.pageSize}
                    defaultSort={this.table.defaultSort}
                    showCheckboxColumn={true}
                    height="auto"
                    selectedActions={this.renderSelectedActions}
                    tableActions={this.renderToolbarActions}
                    tableColumnExtensions={this.table.tableColumnExtensions}
                    defaultColumnWidths={this.table.columnWidths}

                />
                {this.renderDialogConfirmDelete()}
            </PaperFade>
        )
    }
}

Index.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(withRouter(Index))
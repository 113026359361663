import BaseReducer from './BaseReducer';
/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
class DayOffReducer extends BaseReducer {
  get actionsAllow() {
    return {
      ...super.actionsAllow,
      "DayOff.create": {
        path: "data"
      },
      "DayOff.edit": {
        path: "data"
      },
      "DayOff.delete": {
        path: "data"
      },
      "DayOff.destroy": {
        path: "data"
      },
      "DayOff.fetchAll": {
        path: "list"
      },
      "DayOff.fetch": {
        path: "item"
      }
    }
  }

  get initialState() {
    return {
      ...super.initialState,
      error: {
        message: null
      }
    }
  }
}
/*
 * bắt buộc gọi hàm export()
 */
export default DayOffReducer.export()
import React from 'react';
import View from 'views/Area/Index'
import AreaAction from '../../actions/AreaAction';
import BaseContainer, { selector } from 'containers/BaseContainer';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n';
/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
class Index extends BaseContainer {
  constructor(props) {
    super(props)
    this.refTable = null
    this.onFetchData = this.onFetchData.bind(this)
    this.onRefTable = this.onRefTable.bind(this)
    this.onDeleteData = this.onDeleteData.bind(this)
  }

  componentWillReceiveProps(nextProps) {

    this.handleDataAction(AreaAction, "delete", nextProps,
      {
        success: (data) => {
          this.notify(I18n.t('Message.deleteDataSuccess'))
          if (this.refTable) {
            this.refTable.onSelectionChange([])
            this.refTable.onFetchData()
          }
        },
        error: (error) => {
          switch (error.status) {
            case 400: {
              if (error.message === "Area_Del_Warning") {
                this.notify(I18n.t('Backend.Area.Area_Del_Warning'))
              }
              break
            }
            case 404: {
              if (error.message === "No_Object") {
                this.notify(I18n.t('Backend.DbObject.No_Object'), 'error')
              }
              break
            }
            case 422: {
              if (error.message === "Id_Required") {
                this.notify(I18n.t('Backend.DbObject.Id_Required'), 'error')
              }
              break
            }
            default: this.notify(`Response: [${error.status}] ${error.message}`, 'error')

          }
        }
      })
  }

  onFetchData(state) {
    this.props.dispatch(AreaAction.fetchAll(state))
  }

  componentDidMount(){
    localStorage.setItem('userCalendar', JSON.parse(localStorage.user)._id)
    localStorage.setItem('dateCalendar', new Date())
  }

  onRefTable(ref) {
    this.refTable = ref
  }

  onDeleteData(selectedIds) {
    this.props.dispatch(AreaAction.delete({
      ids: selectedIds
    }))
  }

  render() {
    return (<View
      onFetchData={this.onFetchData}
      onRefTable={this.onRefTable}
      onDeleteData={this.onDeleteData}
      data={this.props.data}
    />);
  }
}


const mapStateToProps = state => {
  return {
    lastType: selector(state, "area.lastType", {}), //bắt buộc sử dụng để handle Data Action
    data: selector(state, "area.list", {}),
    error: selector(state, "area.error", ""),
  }
}

export default withRouter(connect(mapStateToProps)(Index))
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import { Form, DateTimeField } from "components/Forms";
import { Icon, Button, Grid } from "@material-ui/core";
import BaseView from "views/BaseView";
import PaperFade from "components/Main/PaperFade";
import { I18n } from "react-redux-i18n";
import Fab from "@material-ui/core/Fab";
import Hidden from "@material-ui/core/Hidden";
import moment from "moment";
import _ from "lodash";
import Table from "./components/Table";

const styles = (theme) => ({
	paper: {
		padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 4}px`,
		[theme.breakpoints.down("sm")]: {
			padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 1}px`,
			marginTop: `${theme.spacing.unit * 1}px`,
		},
	},
});

class Index extends BaseView {
	constructor(props) {
		super(props);
		//nếu là ngày đầu tháng thì tìm kiếm tháng trước
		let startOfMonth =
			moment().startOf("month").format("YYYY-MM-DD") ===
			moment().format("YYYY-MM-DD");
		this.startDate = startOfMonth
			? moment().subtract(1, "months")
			: moment().startOf("month");
		this.endDate = moment();
	}
	// onChange = (time) => {
	//     this.startTime = time
	// }
	render() {
		const { row, classes, onSubmit, showTable } = this.props;
		return (
			<div>
				<PaperFade showLoading={true} className={classes.paper}>
					<Form className={classes.form} onSubmit={onSubmit}>
						<Grid
							container
							direction="row"
							alignItems="flex-end"
							spacing={8}
						>
							<Grid item xs={5} xs={5} md={4} lg={3}>
								<DateTimeField
									label={I18n.t("Common.from")}
									name="startDate"
									format="YYYY.MM.DD"
									value={this.startDate}
									autoOk={true}
									showTime={false}
									showDate={true}
								/>
							</Grid>

							<Grid item xs={5} xs={5} md={4} lg={3}>
								<DateTimeField
									label={I18n.t("Common.to")}
									name="endDate"
									format="YYYY.MM.DD"
									value={this.endDate}
									autoOk={true}
									showTime={false}
									showDate={true}
								/>
							</Grid>
							<Grid item xs={2} lg={1}>
								<Grid
									container
									direction="row"
									justify="flex-end"
									alignItems="flex-end"
								>
									<Hidden smDown>
										<Button
											type="submit"
											variant="contained"
											style={{ marginBottom: "8px" }}
											color="primary"
										>
											{I18n.t("Button.search")}
										</Button>
									</Hidden>
									<Hidden mdUp>
										<Fab
											color="primary"
											type="submit"
											style={{ marginBottom: "8px" }}
											size="small"
											aria-label="add"
											className={classes.fab}
										>
											<Icon>search</Icon>
										</Fab>
									</Hidden>
								</Grid>
							</Grid>
						</Grid>
					</Form>
				</PaperFade>
				{showTable ? <Table row={row} /> : ""}
			</div>
		);
	}
}

Index.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Index));

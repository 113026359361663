import React from 'react';
import View from 'views/Timekeeping/Edit'
import TimekeepingAction from '../../actions/TimekeepingAction'
import BaseContainer, { selector } from 'containers/BaseContainer'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import moment from 'moment'
/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
class Edit extends BaseContainer {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <View
            />
        )
    }
}

const mapStateToProps = state => {
    return {
    }
}

export default withRouter(connect(mapStateToProps)(Edit))
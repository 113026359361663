import BaseAction from "./BaseAction";
/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
class RequestAction extends BaseAction {
  get actions() {
    return {
      create: {
        method: "post",
        url: "/api/v1/requests",
        type: "Request.create",
      },
      edit: {
        method: "put",
        url: "/api/v1/requests/:_id",
        type: "Request.edit",
      },
      destroy: {
        method: "delete",
        url: "/api/v1/requests/:_id",
        type: "Request.destroy",
      },
      delete: {
        method: "delete",
        url: "/api/v1/requests",
        type: "Request.delete",
      },
      fetchAll: {
        method: "get",
        url: "/api/v1/requests",
        type: "Request.fetchAll",
      },
      fetch: {
        method: "get",
        url: "/api/v1/requests/:_id",
        type: "Request.fetch",
      },
      deleteEmployee: {
        method: "put",
        url: "/api/v1/constructions/deleteEmployee",
        type: "Request.deleteEmployee",
      },
      // getWorkerRequests: {
      //     method: 'get',
      //     url: '/api/v1/requests/getWorkerRequests',
      //     type: 'Request.getWorkerRequests'
      // },
      sendMessage: {
        method: "put",
        url: "/api/v1/requests/sendMessage",
        type: "Request.sendMessage",
      },
      getListMessages: {
        method: "get",
        url: "/api/v1/requests/getListMessages",
        type: "Request.getListMessages",
      },
      getApprover: {
        method: "get",
        url: "/api/v1/requests/getApprover",
        type: "Request.getApprover",
      },
      reUpdate: {
        method: "put",
        url: "/api/v1/requests/reUpdate",
        type: "Request.reUpdate",
      },
      checkExistRequest: {
        method: "get",
        url: "/api/v1/requests/checkExistRequest",
        type: "Request.checkExistRequest",
      },
      listHolidayCalendar: {
        method: "get",
        url: "/api/v1/calendars/listHolidayCalendar",
        type: "Request.listHolidayCalendar",
      },
      countDayOffRequest: {
        method: "get",
        url: "/api/v1/requests/countDayOffRequest",
        type: "Request.countDayOffRequest",
      },
      checkSlideTime: {
        method: "get",
        url: "/api/v1/requests/checkSlideTime",
        type: "Request.checkSlideTime",
      },
      refreshDayOff: {
        method: "get",
        url: "/api/v1/requests/refreshDayOff",
        type: "Request.refreshDayOff",
      },
      deleteConfirm: {
        method: "put",
        url: "/api/v1/requests/deleteConfirm",
        type: "Request.deleteConfirm",
      },
      deleteRequestCalendar: {
        method: "delete",
        url: "/api/v1/requests/deleteRequestCalendar",
        type: "Request.deleteRequestCalendar",
      },
      getTimeSetting: {
        method: "get",
        url: "/api/v1/requests/getTimeSetting",
        type: "Request.getTimeSetting",
      },
    };
  }
}
/*
 * bắt buộc gọi hàm export()
 */
export default RequestAction.export();

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Grid,
    Button,
    withStyles,
    ExpansionPanel,
    ExpansionPanelDetails,
    TextField
} from '@material-ui/core'
import { I18n } from 'react-redux-i18n'
import common from "common";

const styles = (theme => ({
    root: {
        flexGrow: 1,
    },
    fixTextField: {
        marginBottom: 0
    },
    Button: {
        height: '50px'
    }
}))
class TotalHour extends Component {
    constructor(props) {
        super(props)

        this.totalTime = this.props.totalTime
        // // this.maxTotalTime = this.props.maxTotalTime
        let { hours, minutes } = common.msToTime(this.totalTime)

        this.state = {
            hours: hours,
            minutes: minutes,
            hours_error: undefined,
            minutes_error: undefined,
            disabled: true
        }
        this.error = false
        this.error_hours = false
        this.error_minutes = false
    }

    componentDidUpdate(){
        let { hours, minutes } = common.msToTime(this.props.totalTime)
        this.state.hours = hours
        this.state.minutes = minutes
    }

    onChange = (event) => {
        let { name, value } = event.target
        value = value.replace(/[^0-9.]/g, "");
        let _name = name === 'hours' ? 'minutes' : 'hours'
        let totalMs = 0
        
        //tính tổng thời gian theo milliseconds
        if (name === 'hours') {
            totalMs = this.props.convertTimeToMs({ hours: value, minutes: this.state[_name] });
            if(value > 24){
                this.error_hours = I18n.t('Form.hours')
            } else {
                this.error_hours = ""
            }
        }

        if (name === 'minutes') {
            totalMs = this.props.convertTimeToMs({ hours: this.state[_name], minutes: value })
            if(value > 60){
                this.error_minutes = I18n.t('Form.minutes')
            } else {
                this.error_minutes = ""
            }
        }

        if (totalMs > this.totalTime) {
            this.error = I18n.t('Form.CheckInOut.maxTotal')
        } else {
            this.error = false
        }
        this.setState({
            [name]: value
        })
    }

    onSubmit = () => {
        //nếu có lỗi thì không cho xác nhận
        if (this.error) {
            this.props.checkErrorTotalHour(true)
            return false
        }

        this.setState({
            disabled: true
        })
        
        let totalTime = this.props.convertTimeToMs({ hours: this.state.hours, minutes: this.state.minutes })
        this.props.submitTotalHour(totalTime)
        this.props.checkErrorTotalHour(false)
    }

    edit = () => {
        this.setState({
            disabled: false
        })
        this.props.checkErrorTotalHour(true)
    }
    render() {
        const { classes } = this.props

        return (
            <ExpansionPanel expanded={true}>
                <ExpansionPanelDetails className={classes.fixPanel}>
                    <Grid container spacing={16}
                        direction="row"
                        justify="center"
                        alignItems="flex-end"
                        className={classes.root}>

                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                className={classes.fixTextField}
                                label={I18n.t("Input.hours")}
                                name={`hours`}
                                margin="normal"
                                type="tel"
                                error={this.error_hours? true : false}
                                helperText={this.hours_error}
                                value={this.state.hours}
                                onChange={this.onChange}
                                // InputProps={{
                                //     readOnly: this.state.disabled,
                                //   }}
                                disabled = {this.state.disabled}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                className={classes.fixTextField}
                                fullWidth
                                label={I18n.t("Input.minutes")}
                                name={`minutes`}
                                type="tel"  
                                margin="normal"
                                error={this.error_minutes? true : false}
                                helperText={this.error_minutes}
                                value={this.state.minutes}
                                onChange={this.onChange}
                                // InputProps={{
                                //     readOnly: this.state.disabled,
                                //   }}
                                disabled = {this.state.disabled}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            {/* <Button variant="outlined" className={classes.Button} size="small" fullWidth onClick={this.onSubmit} color="primary" >
                                {I18n.t('Button.submit')}
                            </Button> */}
                        </Grid>
                        <Grid item xs={3}>
                            {/* <Button variant="outlined" className={classes.Button} size="small" fullWidth onClick={this.edit} color="primary" >
                                {I18n.t('Button.edit')}
                            </Button> */}
                        </Grid>
                        {
                            this.error ?
                                <p style={{ color: "red", fontFamily: "Roboto", fontSize: "17px" }}>{this.error}</p>
                                : ""
                        }
                    </Grid>

                </ExpansionPanelDetails>
            </ExpansionPanel>
        )

    }
}

TotalHour.propTypes = {
    classes: PropTypes.object.isRequired,
}
export default withStyles(styles)(TotalHour)
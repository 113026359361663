import React from 'react'
import View from 'views/Request/Index'
import RequestAction from 'actions/RequestAction'
import DayOffAction from 'actions/DayOffAction'
import BaseContainer, { selector } from 'containers/BaseContainer'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import _ from 'lodash'
import moment from 'moment'
import common from 'common'

class Index extends BaseContainer {
    constructor(props) {
        super(props)
        this.refTable = null
        this.onFetchData = this.onFetchData.bind(this)
        this.onRefTable = this.onRefTable.bind(this)
        this.onDeleteData = this.onDeleteData.bind(this)
        this.updateDayOff = this.updateDayOff.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onDeleteConfirmSubmit = this.onDeleteConfirmSubmit.bind(this)
    }

    componentDidMount() {
        localStorage.setItem('userCalendar', JSON.parse(localStorage.user)._id)
        localStorage.setItem('dateCalendar', new Date())  
    }

    convertLabelToType(state) {
        state.filters.map((item, i) => {
            let typeRequest = null
            if(item.columnName === 'type') {
                typeRequest = state.filters[i].value

                if((I18n.t("Input.request.typeEarlyLate").includes(typeRequest))){
                    typeRequest = 1
                } else if((I18n.t("Input.request.typeOT").includes(typeRequest))){
                    typeRequest = 2
                } else if((I18n.t("Input.request.typeSalary")).includes(typeRequest)){
                    typeRequest = 3
                } else if((I18n.t("Input.request.typeWorkOnDayOff")).includes(typeRequest)){
                    typeRequest = 4
                } else if((I18n.t("Input.request.typeCompensated")).includes(typeRequest)){
                    typeRequest = 5
                } else if((I18n.t("Input.request.typeReplace")).includes(typeRequest)){
                    typeRequest = 6
                } else if(("月報申請").includes(typeRequest)){
                    typeRequest = 7
                } else if((I18n.t("Input.request.absent")).includes(typeRequest)){
                    typeRequest = 8
                } else if((I18n.t("Input.request.dayoff")).includes(typeRequest)){
                    typeRequest = 9
                } else {
                    typeRequest = 0
                }

                state.filters[i].value = typeRequest
                state.filters[i].dataType = "number"
                state.filters[i].operation = "equal"
            }
        })
    }

    onFetchData(state) {
        this.convertLabelToType(state)
        state['flag'] = 'requests'
        this.props.dispatch(RequestAction.fetchAll(state))
    }

    onRefTable(ref) {
        this.refTable = ref
    }

    onSubmit(value) {
        let permissions = JSON.parse(localStorage.user).permissions || []
        let checkTimeZoneFE = localStorage.getItem('frontTimeZone')
        let userLogin = JSON.parse(localStorage.user)._id
        let subtractHour = _.get(value, 'data.subtractHour', '')
        let userId = _.get(value, "data.userId", null) // nhân viên cần xóa lịch
        let date = moment(_.get(value, 'data.date', null)).format('YYYY-MM-DD') // ngày cần xóa
        let replaceDate = moment(_.get(value, 'data.replaceDate', null)).format('YYYY-MM-DD') // ngày cần xóa
        let type = _.get(value, 'data.type', null) // kiểu xin nghỉ
        if(type == 6){
            date = replaceDate
        }
        let manage1 = _.get(value, 'data.manage1', null)
        let manage2 = _.get(value, 'data.manage2', null)
        let updateStatus1 = _.get(value, 'status1', '')
        let updateStatus2 = _.get(value, 'status2', '')

        if (userLogin == manage1) {
            this.props.dispatch(RequestAction.edit({ _id: value.data._id, status1: updateStatus1, permissions: permissions, checkTimeZoneFE: checkTimeZoneFE })) // status1 gửi lên
            .then(data => {
                if (!data.error) {
                    let status = _.get(data, 'data.status', '')
                    if (status && (parseInt(type) === 5 || parseInt(type) === 6 || parseInt(type) === 9)) {
                        this.props.dispatch(RequestAction.deleteEmployee({ userId: userId, start: date, checkTimeZoneFE: checkTimeZoneFE })) // tự động xóa lịch làm việc
                        this.updateDayOff(userId, date, type, subtractHour) // tự động thêm ngày nghỉ đặc biệt
                    } else if(status && parseInt(type) === 3) {
                        this.updateDayOff(userId, date, type, subtractHour) // tự động thêm ngày nghỉ đặc biệt
                    }
                    this.goto('/requests')
                } else {
                    this.notify(`Response: [${data.error.status}] ${common.formatMessageError(data.error)}`, 'error')
                }
            })
        } else if (userLogin == manage2) {
            this.props.dispatch(RequestAction.edit({ _id: value.data._id, status2: updateStatus2, permissions: permissions, checkTimeZoneFE: checkTimeZoneFE })) // status2 gửi lên
            .then(data => {
                if (!data.error) {
                   
                    let status = _.get(data, 'data.status', '')
                    if (status && (parseInt(type) === 5 || parseInt(type) === 6 || parseInt(type) === 9)) {
                        this.props.dispatch(RequestAction.deleteEmployee({ userId: userId, start: date, checkTimeZoneFE: checkTimeZoneFE })) // tự động xóa lịch làm việc
                        this.updateDayOff(userId, date, type, subtractHour) // tự động thêm ngày nghỉ đặc biệt
                    }  else if(status && parseInt(type) === 3) {
                        this.updateDayOff(userId, date, type, subtractHour) // tự động thêm ngày nghỉ đặc biệt
                    }
                    this.goto('/requests')
                } else {
                    this.notify(`Response: [${data.error.status}] ${common.formatMessageError(data.error)}`, 'error')
                }
            })
        }
    }

    onDeleteConfirmSubmit(value) {
        let permissions = JSON.parse(localStorage.user).permissions || []
        let checkTimeZoneFE = localStorage.getItem('frontTimeZone')
        this.props.dispatch(RequestAction.deleteConfirm({ data: value.data, permissions: permissions, checkTimeZoneFE: checkTimeZoneFE })) // status2 gửi lên
            .then(data => {
                if (!data.error) {
                    this.goto('/requests')
                } else {
                    this.notify(`Response: [${data.error.status}] ${common.formatMessageError(data.error)}`, 'error')
                }
            })
    }

    // tự động thêm ngày nghỉ đặc biệt
    updateDayOff(userId, date, type, subtractHour) {
        let permissions = JSON.parse(localStorage.user).permissions || []
        let checkTimeZoneFE = localStorage.getItem('frontTimeZone')
        
        let formatData = {
            permissions: permissions,
            checkTimeZoneFE: checkTimeZoneFE,
            date: date,
            userId: userId,
            subtractHour: subtractHour,
            type: `${type}` // chuyển thành string vì bên DayOff đang sử dụng type string
        }
        if (userId && date && type) {
            this.props.dispatch(DayOffAction.create(formatData))
            .then(result => {
                if (!result.error) {
                    // this.notify(I18n.t('Message.editDataSuccess'))
                } else {
                    this.notify(`Response: [${result.error.status}] ${common.formatMessageError(result.error)}`, 'error');
                }
            })
        }
    }

    onDeleteData(selectedIds) {
        let checkTimeZoneFE = localStorage.getItem('frontTimeZone')
        this.props.dispatch(RequestAction.delete({ ids: selectedIds, checkTimeZoneFE: checkTimeZoneFE }))
            .then(result => {
                if (!result.error) {
                    if (this.refTable) {
                        this.refTable.onSelectionChange([])
                        this.refTable.onFetchData()
                    }
                } else {
                    this.notify(`Response: [${result.error.status}] ${result.error.message}`, 'error')
                }
            })
    }

    render() {
        return (
            <View
                onFetchData={this.onFetchData}
                onRefTable={this.onRefTable}
                onDeleteData={this.onDeleteData}
                data={this.props.data}
                onSubmit={this.onSubmit}
                onDeleteConfirmSubmit={this.onDeleteConfirmSubmit}
            />
        )
    }
}


const mapStateToProps = state => {
    return {
        data: selector(state, "request.list", {}),
    }
}

export default withRouter(connect(mapStateToProps)(Index))
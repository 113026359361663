import React from 'react';
import View from 'views/ConstructionHour/Index'
import ConstructionHourAction from '../../actions/ConstructionHourAction'
import UserAction from '../../actions/UserAction'
import BaseContainer, { selector } from 'containers/BaseContainer';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n';
import moment from 'moment'
/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
class Index extends BaseContainer {
    constructor(props) {
        super(props)
        this.refTable = null
        this.onFetchData = this.onFetchData.bind(this)
        this.onRefTable = this.onRefTable.bind(this)
        this.onSubmitDate = this.onSubmitDate.bind(this)
    }

    componentDidMount() {
        let value = {
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            pageSize: -1
        }
        this.props.dispatch(ConstructionHourAction.fetchAll(value))
    }

    onFetchData(state) {
        // this.props.dispatch(ConstructionHourAction.fetchAll(state))
    }

    onSubmitDate(value) {
        this.props.dispatch(ConstructionHourAction.fetchAll(value))
    }

    onRefTable(ref) {
        this.refTable = ref
    }

    render() {
        return (
            <View
                onFetchData={this.onFetchData}
                onRefTable={this.onRefTable}
                data={this.props.data}
                onSubmitDate={this.onSubmitDate}
            />
        )
    }
}


const mapStateToProps = state => {
    return {
        data: selector(state, "constructionHour.list", {}),
    }
}

export default withRouter(connect(mapStateToProps)(Index))
import BaseAction from './BaseAction'
/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
class DayOffAction extends BaseAction {
    get actions() {
        return {
            create: {
                method: 'post',
                url: '/api/v1/dayoffs',
                type: 'DayOff.create'
            },
            edit: {
                method: 'put',
                url: '/api/v1/dayoffs/:_id',
                type: 'DayOff.edit'
            },
            destroy: {
                method: 'delete',
                url: '/api/v1/dayoffs/:_id',
                type: 'DayOff.destroy'
            },
            delete: {
                method: 'delete',
                url: '/api/v1/dayoffs',
                type: 'DayOff.delete'
            },
            fetchAll: {
                method: 'get',
                url: '/api/v1/dayoffs',
                type: 'DayOff.fetchAll'
            },
            fetch: {
                method: 'get',
                url: '/api/v1/dayoffs/:_id',
                type: 'DayOff.fetch'
            }
        }
    }
}
/*
 * bắt buộc gọi hàm export()
 */
export default DayOffAction.export()
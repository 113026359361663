import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom'
import { IconButton, Icon, Tooltip, Button } from '@material-ui/core'
import BaseView from 'views/BaseView'
import PaperFade from 'components/Main/PaperFade';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash'
import ExportExcel from 'components/ExportExcel/ExportExcel'
import FilterFrom from './components/filter.form'
import ReactToPrint from 'react-to-print';
import PrintTable from '../../components/Print/PrintTable'
import http from '../../helpers/http'
const GridTable = React.lazy(() => import('components/Table/GridTable'))

const styles = theme => ({
    gridTable: {
        height: "calc(100vh)"
    },
    button: {
        // marginRight: '10px'
    }
})

class Index extends BaseView {
    constructor(props) {
        super(props)
        this.state = {
        }
        this.table = {
            columns: [
                {
                    name: 'index',
                    filterable: false,
                    title: I18n.t("Table.header.user.index"),
                    sortable: false,
                    type: "text",
                    printStyle: {
                        textAlign: 'center',
                    },
                    style: {
                        textAlign: "center"
                    }
                },
                {
                    name: 'code',
                    title: I18n.t('Table.header.construction.code'),
                    sortable: false,
                    style: {
                        textAlign: "center"
                    },
                    formatterComponent: (data) => {
                        return _.get(data, 'row.code', '').replace(/-/g, '')
                    }
                },
                {
                    name: 'name',
                    title: I18n.t('Table.header.construction.name'),
                    sortable: false,
                    style: {
                        textAlign: "center"
                    }
                },
                {
                    name: 'totalTime',
                    title: I18n.t('Table.header.construction.timeConstruction'),
                    filterable: false,
                    type: "number",
                    sortable: false,
                    exportType: this.props._60Time ? "60Time" : "10Time",
                    formatterComponent: (data) => {
                        let totalTime = _.get(data.row, "totalTime", '0')
                        return totalTime
                    },
                    printStyle: {
                        textAlign: 'center',
                    },
                    style: {
                        textAlign: "center"
                    }
                },
                {
                    name: 'totalWorkers',
                    filterable: false,
                    type: "number",
                    sortable: false,
                    title: I18n.t('Table.header.construction.totalPeople'),
                    printStyle: {
                        textAlign: 'center',
                    },
                    style: {
                        textAlign: "center"
                    }
                },
                {
                    name: 'totalDays',
                    filterable: false,
                    sortable: false,
                    type: "number",
                    title: I18n.t('Table.header.construction.totalDate'),
                    printStyle: {
                        textAlign: 'center',
                    },
                    style: {
                        textAlign: "center"
                    }
                },
                {
                    name: 'totalSalary',
                    filterable: false,
                    sortable: false,
                    title: I18n.t('Table.header.construction.totalProfit'),
                    formatterComponent: (data) => {
                        data = _.get(data.row, "totalSalary", '0')
                        return data
                    },
                    printStyle: {
                        textAlign: 'right',
                    },
                    style: {
                        textAlign: "center"
                    }
                },
                {
                    name: '_id',
                    title: I18n.t('Table.header.action'),
                    filterable: false,
                    sortable: false,
                    formatterComponent: (data) => {
                        return this.customActionColumn(data)
                    },
                    style: {
                        textAlign: 'center',
                        whiteSpace: "normal",
                        wordWrap: "break-word"
                    }
                },
            ],
            defaultSort: [],
            tableColumnExtensions: [
                { columnName: 'name', wordWrapEnabled: true },
                { columnName: 'totalWorkers', align: 'right', wordWrapEnabled: true },
                { columnName: 'totalDays', align: 'right', wordWrapEnabled: true },
                { columnName: 'totalSalary', align: 'right', wordWrapEnabled: true },
                { columnName: 'totalTime', align: 'center', wordWrapEnabled: true },
                { columnName: '_id', align: 'center' }
            ],

            //tên các column name không muốn xuất file
            ignoreExcelColumns: ["index", "_id"],
            ignorePrintColumns: [ "_id"],
            
            //set độ rộng của excel và của bảng gridTable
            columnWidths: [
                {
                    name: 'index',
                    width: 90
                },
                {
                    name: 'code',
                    width: 130
                },
                {
                    name: 'name',
                    width: 280
                },
                {
                    name: 'totalTime',
                    width: 120
                },
                {
                    name: 'totalWorkers',
                    width: 80
                },
                {
                    name: 'totalDays',
                    width: 90
                },
                {
                    name: 'totalSalary',
                    width: 100
                },
                {
                    name: '_id',
                    width: 80
                }
            ]
        }
        this.ConfirmDialog = null  
        this.renderToolbarActions = this.renderToolbarActions.bind(this)
    }

    customActionColumn(data) {
        let _id = data.value || ''
        const { classes, month } = this.props;
        return (
            <Tooltip title={I18n.t('Button.detail')} key="Detail" className={classes.button}>
                <Button variant='contained' color="primary" onClick={() => this.goto({
                    pathname: `/labors/${_id}`,
                    search: `?month=${month}`
                })}>
                    {I18n.t("Button.detail")}
                </Button>
            </Tooltip>
        )

    }

    getHeaderText(){
        let print_labors = JSON.parse(localStorage.getItem("print_labors")) || {};
        return `${I18n.t('Breadcrumb.laborIndex')} ${I18n.t('Input.month')}: ${print_labors.month}`
    }

    onFetchExcelData = async (cb) => {
        let response = await http.get('/api/v1/labors/excel', {
            startDate: this.filterForm.startDate,
            endDate: this.filterForm.endDate
        })

        cb(response.data)
    }

    renderToolbarActions() {
        const { data = [] } = this.props.data;

        let printData = data.map(item => {
            item.code = item.code.replace(/-/g, '')
            return item
        })

        const { ignoreExcelColumns, columnWidths, columns, ignorePrintColumns } = this.table;
        const headerText = this.getHeaderText();
        const csvCols = [
            {
                name: 'constructionCode',
                title: I18n.t('Table.header.construction.code'),
            },
            {
                name: 'constructionName',
                title: I18n.t('Table.header.construction.name')
            },
            {
                name: 'userCode',
                title: I18n.t('Table.header.user.code'),
            },
            {
                name: 'userName',
                title: I18n.t('Table.header.user.name')
            },
            {
                name: 'totalTime',
                title: I18n.t('Table.header.construction.timeConstruction')
            },
            {
                name: 'totalDays',
                title: I18n.t('Table.header.construction.totalDate'),
            },
            {
                name: 'totalSalaryFormated',
                title: I18n.t('Table.header.construction.totalProfit')
            }
        ]
        return [
            <ReactToPrint key='print'
                trigger={() => <Tooltip title={I18n.t('toolTip.print')} key="print">
                    <IconButton>
                        <Icon>local_printshop</Icon>
                    </IconButton>
                </Tooltip>}
                content={() => this.componentRef}
            />,
            <div key='printTable' style={{ display: "none" }}>
                <PrintTable headerText = {headerText} data={printData} ignore={ignorePrintColumns} columns={columns} columnWidths={columnWidths} ref={el => (this.componentRef = el)} />
            </div>,
            // ignoreExcelColumns: tên các cột không muốn xuất, name: tên bảng, columnWidths: độ rộng của các cột, columns: tên column truyền vào
            <ExportExcel
                ignoreExcelColumns={[]}
                name="labors"
                columnWidths={[]}
                columns={csvCols}
                key="exportExcel"
                onClick={this.onFetchExcelData}
                type='exportLabos' />
        ]
    }


    render() {
        const { data, classes, onSubmit, _60Time } = this.props
        return (
            <PaperFade showLoading={true} >
                <FilterFrom
                    onSubmit={onSubmit}
                    _60Time={_60Time}
                    is60Time={this.props.is60Time}
                    onRef={ref => this.filterForm = ref } />
                <GridTable
                    id="LaborIndex"
                    className={classes.gridTable}
                    onFetchData={this.props.onFetchData}
                    onRefTable={this.props.onRefTable}
                    columns={this.table.columns}
                    rows={[...(data.data || [])]}
                    totalCount={data.total}
                    pageSize={data.pageSize}
                    defaultSort={this.table.defaultSort}
                    height="auto"
                    tableColumnExtensions={this.table.tableColumnExtensions}
                    tableActions={this.renderToolbarActions}
                    defaultColumnWidths={this.table.columnWidths}
                />
            </PaperFade>
        )
    }
}

Index.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Index))
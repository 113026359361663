import React from "react";
import View from "views/Calendar/Calendar";
import CalendarAction from "actions/CalendarAction";
import ConstructionAction from "actions/ConstructionAction";
import BaseContainer, { selector } from "containers/BaseContainer";
import HistoryAction from "actions/HistoryAction";
import HourAction from "actions/HourAction";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import _ from "lodash";
import moment from "moment";

class Index extends BaseContainer {
	constructor(props) {
		super(props);
		this.onSubmit = this.onSubmit.bind(this);
		this.onUpdateHistory = this.onUpdateHistory.bind(this);
		this.onUpdateWorkingDriver = this.onUpdateWorkingDriver.bind(this);
		this.state = {
			getAllConstructionDropDown: [],
		};
	}

	componentWillMount() {
		this.props
			.dispatch(ConstructionAction.fetchAll({ pageSize: -1 }))
			.then((result) =>
				this.setState({
					getAllConstructionDropDown: result.data.data,
				})
			);

		let userCalendar = localStorage.getItem("userCalendar");
		let dateCalendar = localStorage.getItem("dateCalendar");
		let yearMonth = moment(new Date()).format("YYYY-MM");
		if (dateCalendar) {
			yearMonth = moment(dateCalendar).format("YYYY-MM");
		}

		this.props.dispatch(
			CalendarAction.getSettingTimeUser({ userId: userCalendar })
		);
		this.props.dispatch(ConstructionAction.listHolidayContruction());
		this.props.dispatch(CalendarAction.drivingLog({ yearMonth }));
		this.props.dispatch(
			CalendarAction.listConstructionCalendar({
				userId: userCalendar,
				yearMonth: yearMonth,
			})
		); // get User, Construction in Calendar,
		this.props.dispatch(
			CalendarAction.listHistoryCalendar({
				userId: userCalendar,
				yearMonth: yearMonth,
			})
		);
		this.props.dispatch(
			CalendarAction.listDriverLengthCalendar({
				userId: userCalendar,
				yearMonth: yearMonth,
			})
		);
		this.props.dispatch(
			CalendarAction.listWorkerRequestsCalendar({
				userId: userCalendar,
				yearMonth: yearMonth,
			})
		);
		this.props.dispatch(
			CalendarAction.listUserDayOffCalendar({
				userId: userCalendar,
				yearMonth: yearMonth,
			})
		);
	}

	onSubmit(values) {
		this.props.dispatch(CalendarAction.drivingLog(values));
		this.props.dispatch(CalendarAction.listConstructionCalendar(values));
		this.props.dispatch(CalendarAction.listHistoryCalendar(values));
		this.props.dispatch(CalendarAction.listDriverLengthCalendar(values));
		this.props.dispatch(CalendarAction.listWorkerRequestsCalendar(values));
		this.props.dispatch(CalendarAction.listUserDayOffCalendar(values));
	}

	updateAfterHandle() {
		let userCalendar = localStorage.getItem("userCalendar");
		let dateCalendar = localStorage.getItem("dateCalendar");
		let yearMonth = moment(new Date()).format("YYYY-MM");
		if (dateCalendar) {
			yearMonth = moment(dateCalendar).format("YYYY-MM");
		}
		this.props.dispatch(
			CalendarAction.getSettingTimeUser({ userId: userCalendar })
		);
		this.props.dispatch(ConstructionAction.listHolidayContruction());
		this.props.dispatch(
			CalendarAction.listDriverLengthCalendar({
				userId: userCalendar,
				yearMonth: yearMonth,
			})
		);
		this.props.dispatch(CalendarAction.drivingLog({ yearMonth }));
		this.props.dispatch(
			CalendarAction.listConstructionCalendar({
				userId: userCalendar,
				yearMonth: yearMonth,
			})
		); // get User, Construction in Calendar,
		this.props.dispatch(
			CalendarAction.listHistoryCalendar({
				userId: userCalendar,
				yearMonth: yearMonth,
			})
		);
		this.props.dispatch(
			CalendarAction.listWorkerRequestsCalendar({
				userId: userCalendar,
				yearMonth: yearMonth,
			})
		);
		this.props.dispatch(
			CalendarAction.listUserDayOffCalendar({
				userId: userCalendar,
				yearMonth: yearMonth,
			})
		);
	}

	showMessage(data) {
		if (!data.error) {
			this.updateAfterHandle();
			this.notify(I18n.t("Message.editDataSuccess"));
		} else {
			let err = data.error;
			switch (err.status) {
				case 422: {
					if (err.message === "Id_Required") {
						this.notify(I18n.t("Backend.DbObject.Id_Required"), "error");
					}
					if (err.message === "Input_WorkTime_Warn") {
						this.notify(I18n.t("Backend.History.Input_WorkTime_Warn"), "error");
					}
					break;
				}
				case 404: {
					if (err.message === "No_Object") {
						this.notify(I18n.t("Backend.DbObject.No_Object"), "error");
					}
					break;
				}
				case 405: {
					if (err.message === "Expired_Update_Warn") {
						this.notify(I18n.t("Backend.History.Expired_Update_Warn"), "error");
					}
					break;
				}
				case 412: {
					if (err.message === "Input_Construct_Warn") {
						this.notify(
							I18n.t("Backend.History.Input_Construct_Warn"),
							"error"
						);
					}
					break;
				}
				case 417: {
					if (err.message === "Block_Update") {
						this.notify(I18n.t("Backend.History.Block_Update"), "error");
					}
					if (err.message === "Block_Holiday") {
						this.notify(I18n.t("Backend.History.Block_Holiday"), "error");
					}
					break;
				}
				default:
					this.notify(`Response: [${err.status}] ${err.message}`, "error");
			}
		}
	}

	onUpdateHistory(values) {
		let permissions = JSON.parse(localStorage.user).permissions || [];
		let userCalendar = localStorage.getItem("userCalendar");
		let checkTimeZoneFE = localStorage.getItem("frontTimeZone");

		if (values.idRequest !== undefined) {
			this.props
				.dispatch(
					HistoryAction.edit({
						_id: values.idRequest,
						...values,
						permissions: permissions,
						checkTimeZoneFE: checkTimeZoneFE,
					})
				)
				.then((data) => {
					this.showMessage(data);
				});
		} else {
			this.props
				.dispatch(
					HistoryAction.addHistoryInCalendar({
						...values,
						permissions: permissions,
						userId: userCalendar,
						checkTimeZoneFE: checkTimeZoneFE,
					})
				)
				.then((data) => {
					this.showMessage(data);
				});
		}		
	}

	onUpdateWorkingDriver(values) {
		let userCalendar = localStorage.getItem("userCalendar");
		let checkTimeZoneFE = localStorage.getItem("frontTimeZone");

		this.props
			.dispatch(
				HistoryAction.updateWorkingLength({ ...values, userId: userCalendar, checkTimeZoneFE: checkTimeZoneFE })
			)
			.then((data) => {
				this.showMessage(data);
			});
	}

	render() {
		let getAllConstructionDropDown = this.state
			? this.state.getAllConstructionDropDown
			: [];

		return (
			<div>
				<View
					listHistory={this.props.listHistory}
					onSubmit={this.onSubmit}
					onUpdateHistory={this.onUpdateHistory}
					onUpdateWorkingDriver={this.onUpdateWorkingDriver}
					users={this.props.users}
					listHolidayContruction={this.props.listHolidayContruction}
					getRequests={this.props.getRequests}
					constructions={this.props.constructions}
					listConstruction={this.props.listConstruction}
					listUserDayOff={this.props.listUserDayOff}
					listWorkerRequests={this.props.listWorkerRequests}
					listDrivingLog={this.props.listDrivingLog}
					listDriverLengthCalendar={this.props.listDriverLengthCalendar}
					getAllConstructionDropDown={getAllConstructionDropDown}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		listDrivingLog: selector(state, "calendar.drivingLog", []),
		listHistory: selector(state, "calendar.listHistory", []),
		constructions: selector(state, "calendar.dataFull.list", []),
		getRequests: selector(state, "calendar.listWorkerRequests", []),
		listConstruction: selector(state, "calendar.listConstruction.list", []),
		users: selector(state, "calendar.listConstruction.users", []), // get User in Calendar
		listUserDayOff: selector(state, "calendar.listUserDayOff", []),
		listWorkerRequests: selector(state, "calendar.listWorkerRequests", []),
		listHolidayContruction: selector(
			state,
			"construction.listHolidayContruction.data",
			[]
		),
		listDriverLengthCalendar: selector(
			state,
			"calendar.listDriverLengthCalendar",
			[]
		),
	};
};
export default withRouter(connect(mapStateToProps)(Index));

import { Document, Page, Text, View, Font, Image } from "@react-pdf/renderer";
import moment from "moment";
import _ from "lodash";
// import FontMincho from "./MSMINCHO.TTF";
import FontGothic from "./BIZUDPGothic-Regular.ttf";
import { styles } from "./style";

Font.register({
	family: "BIZ UDPGothic",
	src: FontGothic,
	// family: "Michi",
	// src: FontMincho,
	format: "truetype",
});

const chunkSubstr = (str, size) => {
	const numChunks = Math.ceil(str.length / size);
	const chunks = new Array(numChunks);

	for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
		chunks[i] = str.substr(o, size);
	}
	return chunks[0] + "...";
};

const Template = ({ data = {} }) => {
	let rows = data.data || [];
	let construction = _.get(data, "userConstructions", {});
	let constructionList = construction.list || [];
	let queryDate = _.get(data, "queryDate", null);
	let user = _.get(data, "user", {});
	let totalWorkTimeConstruction = 0;

	const columns = [
		{ title: "", style: styles.colMain },
		{ title: "", style: styles.colMain },
		{ title: "工事", style: styles.colMainX4 },
		{ title: "工事時間", style: styles.colMainX1 },
		{ title: "勤務時間", style: styles.colMainX6 },
		{ title: "労働時間", style: styles.colMainX1 },
		{ title: "種別", style: styles.colMainX2 },
		{ title: "通勤・業務", style: styles.colMainX3 },
		{ title: "私用", style: styles.colMain },
		{ title: "給油量", style: styles.colMainX3 },
		{ title: "残業", style: styles.colMain },
		{ title: "深夜", style: styles.colMain },
		{ title: "申請書", style: styles.colMainX2 },
		{ title: "メモ", style: styles.colMainX5 },
	];

	return (
		<Document>
			<Page size="A4" style={styles.page} orientation="landscape" wrap>
				<View style={[styles.flex]}>
					<View style={[styles.infoLeft, styles.infoSize]}>
						<Text style={[styles.title]}>作業月報・運行日誌一覧表</Text>
						<Text style={[styles.full, styles.borderText]}>
							期間:{" "}
							{queryDate
								? moment(new Date(queryDate)).format("YYYY年MM月")
								: ""}
						</Text>
						<View style={[styles.flex, styles.borderText]}>
							<Text>氏名: {user.name}</Text>
							<Text>社員コード: {user.code}</Text>
							<Text>ユーザーID: {user.username}</Text>
						</View>
						<Text style={[styles.full, styles.borderText]}>
							車番: {user.car_number}
						</Text>
					</View>
					<View style={[styles.infoRight]}>
						<View style={[styles.table, styles.bold]}>
							<View style={styles.rowHead}>
								<View style={[styles.col, styles.colInfoHead]}>
									<Text style={[styles.text]}>承認者2</Text>
								</View>
								<View style={[styles.col, styles.colInfoHead]}>
									<Text style={[styles.text]}>承認者1</Text>
								</View>
								<View style={[styles.col, styles.colInfoHead]}>
									<Text style={[styles.text]}>本人</Text>
								</View>
							</View>
							<View style={styles.rowHead}>
								<View style={[styles.col, styles.colInfoStamp]}>
									{data.request2Approved && (
										<Image
											style={[styles.image, styles.infoCenter]}
											src="/images/stamp.png"
										/>
									)}
								</View>
								<View style={[styles.col, styles.colInfoStamp]}>
									{data.request1Approved && (
										<Image
											style={[styles.image, styles.infoCenter]}
											src="/images/stamp.png"
										/>
									)}
								</View>
								<View style={[styles.col, styles.colInfoStamp]}></View>
							</View>
						</View>
					</View>
				</View>
				<View style={[styles.flex]}>
					<View style={[styles.left]}>
						<View style={[styles.table, styles.bold]}>
							<View style={styles.row}>
								{columns.map((row, i) => (
									<View style={[styles.col, row.style]} key={i} wrap>
										<Text style={[styles.text]}>{row.title}</Text>
									</View>
								))}
							</View>
							{rows.map((row, index) => {
								return (
									<View key={index} style={styles.row}>
										<View style={[styles.col, styles.colMain]}>
											<Text style={[styles.text]}>{row.dateText}</Text>
										</View>
										<View style={[styles.col, styles.colMain]}>
											<Text style={[styles.text]}>
												{row.totalRow
													? "合計"
													: row.date
													? moment(row.date).format("dd")
													: ""}
											</Text>
										</View>
										<View
											style={[styles.col, styles.colMainX4, styles.flexCol]}
											wrap
										>
											{Array.isArray(row.constructions) &&
												row.constructions.map((con, indexConstruction) => {
													let code = _.get(con, "code", "");
													code =
														code && code !== "" ? code.replace(/-/g, "") : code;
													let nameLength = con?.name?.length;
													let codeName = `${code} ${_.get(con, "name", "")}`;

													if (row.constructions.length > 2) {
														return (
															<Text
																style={[styles.smallSize]}
																key={index + "" + indexConstruction}
																wrap
																break
															>
																{codeName.length > 51 ? codeName.substring(0, 51) + "..." : codeName.substring(0, 51)}
															</Text>
														);
													} else if (row.constructions.length === 2) {
														return (
															<Text
																style={[styles.colFixed]}
																key={index + "" + indexConstruction}
																wrap
																break
															>
																{codeName.length > 30 ? codeName.substring(0, 30) + "..." : codeName.substring(0, 30)}
															</Text>
														);
													// }
													// else if (
													// 	nameLength > 50 &&
													// 	row.constructions.length === 1
													// ) {
													// 	return (
													// 		<Text
													// 			style={[styles.colFixed]}
													// 			key={index + "" + indexConstruction}
													// 			wrap
													// 			break
													// 		>
													// 			{chunkSubstr(code + _.get(con, "name", ""), 50)}
													// 		</Text>
													// 	);
													} else if (row.constructions.length === 1) {
														let tCodeName = `${code} ${_.get(con, "name", "")}`;
														let lineFirst = "",
															lineSecond = "";

														if (tCodeName.length > 68) {
															tCodeName = chunkSubstr(tCodeName, 65);
														}

														if (tCodeName.length > 20) {
															lineFirst = tCodeName.substring(0, 33);
															lineSecond = tCodeName.substring(
																34,
																tCodeName.length
															);
														}

														return (
															<div>
																{
																	lineFirst && lineSecond  ? 
																<>
																	<Text
																		style={[styles.colFixed]}
																		key={index + "" + indexConstruction + 1}
																		wrap
																		break
																	>
																		{lineFirst}
																	</Text>
																	<Text
																		style={[styles.colFixed]}
																		key={index + "" + indexConstruction + 2}
																		wrap
																		break
																	>
																		{lineSecond}
																	</Text>
																</> : <Text
																		style={[styles.colFixed]}
																		key={index + "" + indexConstruction + 1}
																		wrap
																		break
																	>
																		{codeName}
																	</Text>
																}
															</div>
														);
													}
												})}
										</View>
										<View style={[styles.col, styles.colMainX1]}>
											{!row.totalRow ? (
												Array.isArray(row.constructions) &&
												row.constructions.map((con, key) => {
													totalWorkTimeConstruction += con.workTime;
													return (
														<Text
															key={index + "" + key}
															style={[
																row.constructions.length > 2
																	? styles.smallSize
																	: styles.colFixed,
																styles.text,
															]}
														>{`${con.workTime}`}</Text>
													);
												})
											) : (
												<Text style={[styles.text]}>
													{totalWorkTimeConstruction}
												</Text>
											)}
										</View>
										<View style={[styles.col, styles.colMainX6]}>
											<Text style={[styles.text]}>{row.workHourRange}</Text>
										</View>
										<View style={[styles.col, styles.colMainX1]}>
											<Text style={[styles.text]}>
												{row.totalRow ? row.totalWorkHour : row.work_hour}
											</Text>
										</View>
										<View style={[styles.col, styles.colMainX2]}>
											{Array.isArray(row.typeOfWorkingDay) &&
												row.typeOfWorkingDay.map((item, indexType) => (
													<Text
														key={index + "" + indexType}
														style={[styles.text]}
													>{`${item}`}</Text>
												))}
										</View>
										<View style={[styles.col, styles.colMainX3]}>
											<Text style={[styles.text]}>
												{row.driving_length_on_working &&
												![undefined, null].includes(
													row.driving_length_on_working
												)
													? row.driving_length_on_working.toFixed(2)
													: row.driving_length_on_working}
											</Text>
										</View>
										<View style={[styles.col, styles.colMain]}>
											<Text style={[styles.text]}>
												{row.driving_length_on_private &&
												![undefined, null].includes(
													row.driving_length_on_private
												)
													? row.driving_length_on_private.toFixed(2)
													: row.driving_length_on_private}
											</Text>
										</View>
										<View style={[styles.col, styles.colMainX3]}>
											<Text style={[styles.text]}>
												{row.fuel_supply_amount &&
												![undefined, null].includes(row.fuel_supply_amount)
													? row.fuel_supply_amount.toFixed(2)
													: row.fuel_supply_amount}
											</Text>
										</View>
										<View style={[styles.col, styles.colMain]}>
											<Text style={[styles.text]}>
												{row.totalRow
													? row.total_ot
													: row.total_ot
													? row.total_ot
													: row[row.dateType] && row[row.dateType].normalOT}
											</Text>
										</View>
										<View style={[styles.col, styles.colMain]}>
											<Text style={[styles.text]}>
												{row.totalRow
													? row.total_work_night_hour
													: row.total_work_night_hour
													? row.total_work_night_hour
													: row[row.dateType] &&
													  row[row.dateType].workNightHour}
											</Text>
										</View>
										<View style={[styles.col, styles.colMainX2]}>
											{Array.isArray(row.typeRequestWorkingDay) &&
												row.typeRequestWorkingDay.map((item, indexWork) => (
													<Text
														key={index + "" + indexWork}
														style={[styles.text]}
													>{`${item}`}</Text>
												))}
										</View>
										<View style={[styles.col, styles.colMainX5]}>
											<Text style={[styles.textMemo]}>{row.memo}</Text>
										</View>
									</View>
								);
							})}
						</View>
					</View>
					<View style={[styles.right]}>
						<View style={[styles.table, styles.bold]}>
							<View style={styles.row}>
								<View style={[styles.col, styles.colSubX2]} wrap>
									<Text>出勤日数</Text>
								</View>
								<View style={[styles.col, styles.colSub]} wrap>
									<Text style={[styles.text]}>{data.total_working_day}</Text>
								</View>
							</View>
							<View style={styles.row}>
								<View style={[styles.col, styles.colSubX2]} wrap>
									<Text>休日出勤日数</Text>
								</View>
								<View style={[styles.col, styles.colSub]} wrap>
									<Text style={[styles.text]}>
										{data.requests &&
											Object.keys(data.requests[0].workOnDayOffReq).length}
									</Text>
								</View>
							</View>
							<View style={styles.row}>
								<View style={[styles.col, styles.colSubX2]} wrap>
									<Text>先月最終メーター</Text>
								</View>
								<View style={[styles.col, styles.colSub]} wrap>
									<Text style={[styles.text]}>
										{data.drivingLog && data.drivingLog.prevMonth.length > 0
											? Intl.NumberFormat("ja-JP", {
													notation: "standard",
											  }).format(data.drivingLog.prevMonth.length)
											: 0}
									</Text>
								</View>
							</View>
							<View style={styles.row}>
								<View style={[styles.col, styles.colSubX2]} wrap>
									<Text>今月最終メーター</Text>
								</View>
								<View style={[styles.col, styles.colSub]} wrap>
									<Text style={[styles.text]}>
										{data.drivingLog && data.drivingLog.currentMonth.length > 0
											? Intl.NumberFormat("ja-JP", {
													notation: "standard",
											  }).format(data.drivingLog.currentMonth.length)
											: 0}
									</Text>
								</View>
							</View>
							<View style={styles.row}>
								<View style={[styles.col, styles.colSubX2]} wrap>
									<Text>期間走行距離</Text>
								</View>
								<View style={[styles.col, styles.colSub]} wrap>
									<Text style={[styles.text]}>{data.period_mileage}</Text>
								</View>
							</View>
							<View style={styles.row}>
								<View style={[styles.col, styles.colSubX2]} wrap>
									<Text>通勤＋業務距離</Text>
								</View>
								<View style={[styles.col, styles.colSub]} wrap>
									<Text style={[styles.text]}>
										{data.driving_length_on_working}
									</Text>
								</View>
							</View>
						</View>
						<View style={[styles.table, styles.bold, styles.headRightTable]}>
							<View style={styles.row}>
								<View style={[styles.col, styles.colSubX2]} wrap>
									<Text>工事</Text>
								</View>
								<View style={[styles.col, styles.colSub]} wrap>
									<Text style={[styles.text]}>時間</Text>
								</View>
							</View>
							{[...Array(rows.length - 8)].map((x, i) => {
								let con = constructionList[i] || { code: "", name: "" };

								let codeName = con.code.replace(/-/g, "") + " " + con.name;
								let lineFirst = "",
									lineSecond = "";
								if (codeName.length > 60) {
									codeName = chunkSubstr(codeName, 57);
								}
								if (codeName.length <= 60) {
									lineFirst = codeName.substring(0, 31);
									lineSecond = codeName.substring(32, codeName.length);
								}

								return (
									<View style={styles.row} key={con.constructionId}>
										<View
											style={[styles.col, styles.colSubX3, styles.flexCol]}
											wrap
										>
											{codeName.length < 60 ? (
												<div>
													<Text key={`${i}1`} wrap break>
														{lineFirst}
													</Text>
													<Text key={`${i}2`} wrap break>
														{lineSecond}
													</Text>
												</div>
											) : (
												<Text
													numberOfLines={2}
													style={
														con.name.length > 60
															? styles.smallSize
															: styles.colFixed
													}
												>
													{codeName}
												</Text>
											)}
										</View>
										<View style={[styles.col, styles.colSub]} wrap>
											<Text style={[styles.text]}>{con.workTime}</Text>
										</View>
									</View>
								);
							})}
							<View style={styles.row}>
								<View style={[styles.col, styles.colSubX2]} wrap>
									<Text>合計</Text>
								</View>
								<View style={[styles.col, styles.colSub]} wrap>
									<Text style={[styles.text]}>{construction.total}</Text>
								</View>
							</View>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);
};

export default Template;

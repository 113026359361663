import BaseAction from './BaseAction'
/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
class CalendarAction extends BaseAction {
    get actions() {
        return {
            detailPast: {
                method: 'get',
                url: '/api/v1/calendars/detailPast',
                type: 'Calendar.detailPast'
            },
            listConstruction: {
                method: 'get',
                url: '/api/v1/calendars/listConstruction',
                type: 'Calendar.listConstruction'
            },
            listConstructionCalendar: {
                method: 'get',
                url: '/api/v1/calendars/listConstructionCalendar',
                type: 'Calendar.listConstructionCalendar'
            },
            listDriverLengthCalendar: {
                method: 'get',
                url: '/api/v1/calendars/listDriverLengthCalendar',
                type: 'Calendar.listDriverLengthCalendar'
            },
            listHistoryCalendar: {
                method: 'get',
                url: '/api/v1/calendars/listHistoryCalendar',
                type: 'Calendar.listHistoryCalendar'
            },
            listWorkerRequestsCalendar: {
                method: 'get',
                url: '/api/v1/calendars/listWorkerRequestsCalendar',
                type: 'Calendar.listWorkerRequestsCalendar'
            },
            listUserDayOffCalendar: {
                method: 'get',
                url: '/api/v1/calendars/listUserDayOffCalendar',
                type: 'Calendar.listUserDayOffCalendar'
            },
            drivingLog: {
                method: 'get',
                url: '/api/v1/calendars/drivingLog',
                type: 'Calendar.drivingLog'
            },
            updateDrivingLength: {
                method: 'post',
                url: '/api/v1/calendars/updateDrivingLength',
                type: 'Calendar.updateDrivingLength'
            },
            getSettingTimeUser: {
                method:'get',
                url: '/api/v1/calendars/getSettingTimeUser',
                type: 'Calendar.getSettingTimeUser'
            },
            listRequestUser: {
                method: 'get',
                url: '/api/v1/calendars/listRequestUser',
                type: 'Calendar.listRequestUser'
            },
            getUserDayOffThisDayCalendar: {
                method: 'get',
                url: '/api/v1/calendars/getUserDayOffThisDayCalendar',
                type: 'Calendar.getUserDayOffThisDayCalendar'
            },
            listRemainTotalCompensatedThisMonth: {
                method: 'get',
                url: '/api/v1/calendars/listRemainTotalCompensatedThisMonth',
                type: 'Calendar.listRemainTotalCompensatedThisMonth'
            },
            getMaxDateRequest: {
                method: 'get',
                url: '/api/v1/calendars/getMaxDateRequest',
                type: 'Calendar.getMaxDateRequest'
            }
        }
    }
}
/*
 * bắt buộc gọi hàm export()
 */
export default CalendarAction.export()
import BaseReducer from './BaseReducer';

class MenuToggleReducer extends BaseReducer {
    get actionsAllow() {
        return {
            "toggle_menu": this.toggleMenu,
        }
    }
    get initialState() {
        return {
            menuToggle: {
                status: true
            }
        }
    }

    toggleMenu(state, action) {
        return {
            ...state,
            menuToggle: action.data
        }
    }
}
/**
 * bắt buộc gọi hàm export()
 */
export default MenuToggleReducer.export()
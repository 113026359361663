import BaseAction from './BaseAction'
/**
 * Files are automatically generated from the template.
 * MQ Solutions 2019
 */
class ReportAction extends BaseAction {
  get actions() {
    return {
      create: {
        method: 'post',
        url: '/api/v1/reports',
        type: 'Report.create'
      },
      edit: {
        method: 'put',
        url: '/api/v1/reports/:_id',
        type: 'Report.edit'
      },
      destroy: {
        method: 'delete',
        url: '/api/v1/reports/:_id',
        type: 'Report.destroy'
      },
      delete: {
        method: 'delete',
        url: '/api/v1/reports',
        type: 'Report.delete'
      },
      fetchAll: {
        method: 'get',
        url: '/api/v1/reports',
        type: 'Report.fetchAll'
      },
      fetch: {
        method: 'get',
        url: '/api/v1/reports/:_id',
        type: 'Report.fetch'
      },
      listConstruction: {
        method: 'get',
        url: '/api/v1/reports/listConstruction',
        type: 'Report.listConstruction'
      },
    }
  }
}
/*
 * bắt buộc gọi hàm export()
 */
export default ReportAction.export()
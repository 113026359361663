import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    // fontFamily: "Michi",
    fontFamily: "BIZ UDPGothic",
    padding: "7px",
    fontSize: "4.9px",
    fontWeight: "bold"
  },
  table: {
    width: "100%",
    borderRight: "1px solid #000000",
    borderTop: "1px solid #000000",
  },
  rowHead: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: 0,
    borderTop: "none",
    justifyContent: "space-between",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    height: "15px",
    margin: 0,
    padding: 0,
    borderTop: "none",
    justifyContent: "space-between",
  },
  bold: {
    fontWeight: "bold",
  },
  flex: {
    marginTop: "2.5px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
  infoLeft: {
    width: "35%",
  },
  infoRight: {
    width: "25%",
  },
  infoSize: {
    fontSize: '8.5px'
  },
  left: {
    width: "75%",
    paddingRight: "8px",
  },
  right: {
    width: "25%",
  },
  headRightTable: {
    marginTop: "13.7px"
  },
  title: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  text: {
    maxWidth: "10px",
  },
  col: {
    padding: "0.4px",
    borderLeft: "1px solid #000000",
    borderBottom: "1px solid #000000",
    flexGrow: 1,
    fontSize: "4.9px",
    justifyContent: "center",
  },
  smallSize: {
    fontSize: "2.9px"
  },
  colFixed: {
    fontSize: "4.9px"
  },
  textMemo: {
    fontSize: "4.9px"
  },  
  colInfoHead: {
    width: "30px",
    minHeight: "15px",
  },
  colInfoStamp: {
    width: "100%",
    minHeight: "50px",
  },
  infoCenter: {
    alignSelf: 'center'
  },
  colMain: {
    width: "12px",
  },
  colMainX1: {
    width: "15px",
  },
  colMainX2: {
    width: "30px",
  },
  colMainX3: {
    width: "30px",
  },
  colMainX4: {
    width: "140px"
  },
  colMainX5: {
    width: "75px",
  },
  colMainX6: {
    width: "30px",
  },
  colSub: {
    width: "40px",
  },
  colSubX2: {
    width: "120px",
  },
  colSubX3: {
    width: "120px"
  },
  image: {
    width: "38px",
    height: "38px",
    textAlign: "center",
  },
  text: {
    textAlign: "center",
  },
  borderText: {
    borderBottom: "1px solid #00000026",
    marginTop: "4px",
  },
  full: {
    width: "100%"
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { IconButton, Icon, Tooltip, Button, Hidden } from '@material-ui/core'
import BaseView from 'views/BaseView'
import PaperFade from 'components/Main/PaperFade'
import { I18n } from 'react-redux-i18n'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import IndexMobile from './IndexMobile'
import Filter from './components/Filter'
import _ from 'lodash'
import './style.css'

const GridTable = React.lazy(() => import('components/Table/GridTable'))
const styles = theme => ({
    gridTable: {
        height: "calc(100vh - 100px)"
    }
})

class Index extends BaseView {
    constructor(props) {
        super(props)
        this.state = {
            rendered: false,
        }
        this.table = {
            columns: [
                {
                    name: 'index',
                    title: I18n.t("Table.header.user.index"),
                    type: "text",
                    filterable: false,
                    sortable: false
                },
                {
                    name: 'contractor',
                    title: I18n.t("Table.header.status"),
                    type: "text",
                    filterable: false,
                    sortable: false,
                    formatterComponent: (data) => {
                        let listUserSeen = _.get(data.row, 'listUserSeen', []) || []
                        let status = data.row.User._id === JSON.parse(localStorage.user)._id
                        for (let e of listUserSeen) {
                            if (e === JSON.parse(localStorage.user)._id) {
                                status = true
                            }
                        }
                        return (
                            <div>
                                {
                                    !status
                                        ? <Icon style={{ color: '#476b6b' }} size='small'>fiber_manual_record</Icon>
                                        : null
                                }
                            </div>
                        )
                    }
                },
                {
                    name: 'warning',
                    title: I18n.t("Input.warning"),
                    filterable: false,
                    sortable: false,
                    formatterComponent: (data) => {
                        let warning = _.get(data.row, "warning", null)
                        return (
                            <div>
                                {
                                    warning
                                        ? <Tooltip title={I18n.t('Input.warning')} key='warning'>
                                            <IconButton style={{ color: 'red' }}>
                                                <Icon>warning</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        : null
                                }
                            </div>
                        )
                    }
                },
                {
                    name: 'date',
                    title: I18n.t("Table.header.date"),
                    formatterComponent: (data) => {
                        let date = _.get(data.row, "date", "")
                        return moment(date).format("YYYY-MM-DD")
                    },
                    filterFormat: "YYYY/MM/DD",
                    type: "date"
                },
                {
                    name: 'Area.name',
                    title: I18n.t("Table.header.area.name"),
                    filterable: false,
                    formatterComponent: (data) => {
                        let areaName = _.get(data.row, "Area.name", '')
                        return `${areaName}`
                    }
                },
                {
                    name: 'Construction.code',
                    title: I18n.t("Table.header.construction.code"),
                    formatterComponent: (data) => {
                        let constrcutionCode = _.get(data.row, "Construction.code", null)
                        return constrcutionCode
                    }
                },
                {
                    name: 'Construction.name',
                    title: I18n.t("Table.header.construction.name"),
                    formatterComponent: (data) => {
                        let constrcutionName = _.get(data.row, "Construction.name", null)
                        return constrcutionName
                    }
                },
                {
                    name: 'User.name',
                    title: I18n.t("Table.header.construction.userName"),
                    formatterComponent: (data) => {
                        let name = _.get(data.row, "User.name", "")
                        return name
                    }
                },
                {
                    name: 'subject',
                    title: I18n.t("Table.header.report.title")
                },
                {
                    name: '_id',
                    title: I18n.t('Table.header.action'),
                    filterable: false,
                    sortable: false,
                    formatterComponent: (data) => {
                        return this.customActionColumn(data)
                    },
                    style: {
                        textAlign: 'center',
                        whiteSpace: "normal",
                        wordWrap: "break-word"
                    }
                }
            ],
            defaultSort: [{ columnName: "date", direction: "desc" }],
            tableColumnExtensions: [
                { columnName: 'Area.name', wordWrapEnabled: true },
                { columnName: 'Construction.name', wordWrapEnabled: true },
                { columnName: 'User.name', wordWrapEnabled: true },
                { columnName: 'subject', wordWrapEnabled: true },
                { columnName: 'contractor', wordWrapEnabled: true },
            ],
            columnWidths: [
                {
                    name: 'index',
                    width: 60
                },
                {
                    name: 'contractor',
                    width: 60
                },
                {
                    name: 'warning',
                    width: 60
                },
                {
                    name: 'date',
                    width: 100
                },
                {
                    name: 'Area.name',
                    width: 100
                },
                {
                    name: 'Construction.code',
                    width: 100
                },
                {
                    name: 'Construction.name',
                    width: 100
                },
                {
                    name: 'User.name',
                    width: 100
                },
                {
                    name: 'subject',
                    width: 220
                },
                {
                    name: '_id',
                    width: 200
                },
            ]
        }
        this.ConfirmDialog = null
        this.renderToolbarActions = this.renderToolbarActions.bind(this)
        this.renderSelectedActions = this.renderSelectedActions.bind(this)
    }

    renderAction(data) {
        let status = _.get(data, 'row.status', null)
        return (
            <Tooltip title={status ? I18n.t('Button.view') : I18n.t('Button.unview')} key='view'>
                <Button
                    variant='contained'
                    onClick={() => this.showDetailReport(data)}
                    color={status ? 'primary' : 'inherit'}
                    // style={{ marginRight: '10px' }}
                >
                    {I18n.t('Button.detail')}
                </Button>
            </Tooltip>
        )
    }

    gotoDetail(status, _id) {
        this.goto(`/reports/${_id}`)
        if (status === false) {
            this.props.onSubmit(_id)
        }
    }

    customActionColumn(data) {
        let listUserSeen = _.get(data.row, 'listUserSeen', []) || []
        let _id = this.getData(data, "value", '')
        let status = false
        for (let e of listUserSeen) {
            if (e === JSON.parse(localStorage.user)._id) {
                status = true
            }
        }
        return ([
            <Button key='detail' variant='contained' color={'primary'} onClick={() => this.gotoDetail(status, _id)}>
                {I18n.t('Button.detail')}
            </Button>,
            <Button key='delete' variant="outlined" color="secondary" style={{
                backgroundColor: '#fff',
                color: 'red',
                borderColor: 'red',
                marginLeft: '10px'
                }}
                onClick={() => this.ConfirmDialog.show([data.row._id])}>
                    {I18n.t("Button.delete")}
                </Button>
            ]
            
        )
    }

    deleteLocalStorageCreate() {
        localStorage.removeItem('constructionId')
        localStorage.removeItem('today')
        localStorage.removeItem('title')
        localStorage.removeItem('contractor')
        localStorage.removeItem('construction_unit')
        localStorage.removeItem('problem_report')
        localStorage.removeItem('areaId')
        localStorage.removeItem('warning')
        localStorage.removeItem('titleContent')
        localStorage.removeItem('content')
        localStorage.setItem('backReport', 'backReport')
        this.goto(`/reports/create`)
    }
    renderToolbarActions() {
        return [
            <Tooltip title={I18n.t("toolTip.new")} key="create">
                <Button variant="contained" color="primary" onClick={() => this.deleteLocalStorageCreate()}>
                    {I18n.t("Button.create")}
                </Button>
            </Tooltip>,
        ]
    }
    renderSelectedActions(selectedIds) {
        return [
            <Tooltip title={I18n.t("toolTip.delete")} key="delete">
                <Button key="delete" onClick={() => this.ConfirmDialog.show(selectedIds)}>
                    {I18n.t("Button.delete")}
                </Button>
            </Tooltip>,
        ]
    }
    renderDialogConfirmDelete() {
        return <ConfirmDialog
            ref={(ref) => this.ConfirmDialog = ref}
            title={I18n.t('Message.deleteDialogTitle')}
            content={I18n.t('Message.deleteDialogContent')}
            onSubmit={this.props.onDeleteData}
        />
    }

    render() {
        const { data, classes, onSubmit, area, filterData } = this.props
        let width = window.innerWidth
        return (
            <PaperFade showLoading={true}>
                <Hidden smUp>
                    <IndexMobile
                        filterData={filterData}
                        area={area}
                        data={data}
                        classes={classes}
                        onSubmit={onSubmit}
                    />
                </Hidden>
                <Hidden smDown>
                <React.Fragment>
                    <Filter areas={data.areas || []} onFilterArea={this.props.onFilterArea} />
                        <GridTable
                            id="ReportIndex221"
                            estimatedRowHeight={70}
                            className={classes.gridTable + ' report-page'}
                            onFetchData={this.props.onFetchData}
                            onRefTable={this.props.onRefTable}
                            columns={this.table.columns}
                            rows={data.data}
                            totalCount={data.total}
                            pageSize={data.pageSize}
                            defaultSort={this.table.defaultSort}
                            showCheckboxColumn={false}
                            height="auto"
                            selectedActions={this.renderSelectedActions}
                            tableActions={this.renderToolbarActions}
                            defaultColumnWidths={this.table.columnWidths}
                            tableColumnExtensions={this.table.tableColumnExtensions}
                        />
                    </React.Fragment>
                    {this.renderDialogConfirmDelete()}
                </Hidden>
                {/* {
                  
                    width <= 600
                    ? 
                        <IndexMobile
                            filterData={filterData}
                            area={area}
                            data={data}
                            classes={classes}
                            onSubmit={onSubmit}
                        />
                    :
                        <React.Fragment>
                            <Filter areas={data.areas || []} onFilterArea={this.props.onFilterArea} />
                            <GridTable
                                id="ReportIndex"
                                estimatedRowHeight={70}
                                className={classes.gridTable}
                                onFetchData={this.props.onFetchData}
                                onRefTable={this.props.onRefTable}
                                columns={this.table.columns}
                                rows={data.data}
                                totalCount={data.total}
                                pageSize={data.pageSize}
                                defaultSort={this.table.defaultSort}
                                showCheckboxColumn={false}
                                height="auto"
                                selectedActions={this.renderSelectedActions}
                                tableActions={this.renderToolbarActions}
                                defaultColumnWidths={this.table.columnWidths}
                                tableColumnExtensions={this.table.tableColumnExtensions}
                            />
                        </React.Fragment>
                }
                    {this.renderDialogConfirmDelete()} */}
            </PaperFade>
        )
    }
}

Index.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Index));
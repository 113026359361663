import React from 'react'
import View from 'views/Delete/Index'
import BaseContainer, { selector } from 'containers/BaseContainer'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import moment from 'moment'
import DeleteAction from '../../actions/DeleteAction'
import _ from 'lodash'


class Index extends BaseContainer {
    constructor(props) {
        super(props)
        this.onSubmit = this.onSubmit.bind(this)
    }

    onSubmit(value) {
        this.props.dispatch(DeleteAction.delete()).then(data =>{
          if(!data.error){
              this.notify(I18n.t('Message.deleteDataSuccess'))
              this.goto("/deletes")
          }
          else{
            let err = data.error
            switch (err.status) {
                case 405: {
                    if (err.message === "Dont_have_permission") {
                        this.notify(I18n.t('Backend.Delete.Dont_have_permission'), 'error')
                    }
                    break
                }
                default: this.notify(`Response: [${err.status}] ${err.message}`, 'error')
            }
          }
        })
    }

    render() {
        return (
            <div>
                <View
                    onSubmit={this.onSubmit}
                />
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
    }
}

export default withRouter(connect(mapStateToProps)(Index))